.youtube-modal {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  z-index: 1000;
  background: #000;
  display: none;

  .fitvid {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }

  &__close {
    position: absolute;
    top: 10px;
    right: 10px;
    background: transparent;
    border: none;
    padding: 20px;
    z-index: 2;
    transition: all .25s ease;

    svg {
      fill: #FFF;
    }

    &:hover,
    &:focus {
      background: $green;
    }
  }
}
