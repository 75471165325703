.search-results {
  &__filters {
    li {
      padding: 0 15px;

      a {
        font-family: $public-sans-medium;
        font-size: 16px;
        color: $black;
        border-bottom: 3px solid transparent;
        padding-bottom: 5px;
      }

      &:first-child {
        padding-left: 0;
      }

      &.active {
        a {
          border-bottom-color: $light-green;
        }
      }
    }
  }

  &__filter-list {
    display: none;

    @include media($post-tablet) {
      display: block;
    }
  }

  &__filter-dropdown {
    @include media($post-tablet) {
      display: none;
    }
  }

  &__search-container {
    position: relative;
    max-width: 920px;
    margin-bottom: 30px;

    input {
      width: 100%;
      border-radius: 6px;
      border: 1px solid #cbcbcb;
      background-color: #ffffff;
      padding: 23px 55px 23px 36px;
      font-family: $public-sans-semibold;
      font-size: 23px;
    }

    &::after {
      content: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMyIgaGVpZ2h0PSIyMyIgdmlld0JveD0iMCAwIDIzIDIzIj48Zz48Zz48cGF0aCBmaWxsPSIjMDA0YTdlIiBkPSJNMTAuMDU4IDIuODc1YzMuOTQzIDAgNy4xODIgMy4yMTMgNy4xODIgNy4xODMgMCAzLjk3LTMuMjM5IDcuMTgyLTcuMTgyIDcuMTgyLTMuOTQ0IDAtNy4xODMtMy4yMzktNy4xODMtNy4xODIgMC0zLjk0NCAzLjIxMy03LjE4MyA3LjE4My03LjE4M3ptLS4wMjkgMTcuMTZhMTAuMDIgMTAuMDIgMCAwIDAgNi4wNzEtMi4wNTdsNC42MjcgNC42MjFjLjI2Ny4yNjcuNjE1LjQwMS45MzYuNDAxLjMyIDAgLjY5NS0uMTM0LjkzNi0uNGExLjMyMSAxLjMyMSAwIDAgMCAwLTEuODk3bC00LjYtNC42MjJhOS45OTQgOS45OTQgMCAwIDAgMi4wNi02LjA2NEMyMC4wNTggNC40ODcgMTUuNTY0IDAgMTAuMDI4IDBTMCA0LjQ4OCAwIDEwLjAxN2MwIDUuNTMgNC40OTMgMTAuMDE4IDEwLjAzIDEwLjAxOHoiLz48L2c+PC9nPjwvc3ZnPg==);
      position: absolute;
      top: 54%;
      right: 36px;
      transform: translate(0, -50%);
      line-height: 1;
    }
  }

  &__pagination {
    margin-bottom: 50px;

    .grid__row {
      @include flex-row(1355px);
      padding: 0 20px;
    }
  }
}
