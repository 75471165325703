input {
  &:not([type="checkbox"]):not([type="radio"]):not([type="search"]):not([type="color"]):not([type="button"]):not([type="submit"]):not([type="reset"]) {
    font-family: $public-sans-semibold;
    background-color: $white;
    border: 1px solid #bababa;
    border-radius: 5px;
    font-size: 14px;
    margin-bottom: 16px;
    padding: 25px 20px;
    max-width: 360px;
    width: 100%;
    color: $black;

    &:focus {
      border: 1px solid $light-green;
      outline: 0;
    }

    &::placeholder {
      color: $black;
    }
  }

  &[type="checkbox"] {
    background-color: $white;
    border-radius: 4px;
    border: 2px solid #bababa;
    vertical-align: middle;
  }
}

textarea {
  background-color: $white;
  border: 1px solid #bababa;
  border-radius: 5px;
  font-size: 14px;
  margin-bottom: 16px;
  padding: 25px 20px;
  width: 100%;
  color: $black;

  &:focus {
    border: 1px solid $light-green;
    outline: 0;
  }

  &::placeholder {
    color: $black;
  }
}

select:not([multiple]) {
  border: 1px solid #bababa;
  border-radius: 5px;
  color: $black;
  font-size: 16px;
  font-weight: 600;
  padding: 15px 40px 15px 20px;
  background-color: $white;
  appearance: none;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA0AAAAHCAMAAADkrjcoAAAAFVBMVEUAAACfsyahtSafryChtCagtCaftCbTrD/KAAAAB3RSTlMAUH8Q0P+gugqphAAAACZJREFUeNpFx7cNAAAIA7BQ/z8Z0b0ZxI8gaksF2U/20p1sK1fvBDQJAOjtDFxVAAAAAElFTkSuQmCC);
  background-repeat: no-repeat;
  background-position: 90% 50%;
  background-size: 12px 6px;
}
