.map-controls__form {
  .select2-container .select2-selection--single {
    height: 60px;
    border-radius: 5px;
    background-color: #fff;
    border-color: #fff;

    color: #171717;
    font-family: $public-sans-semibold;
    font-size: 14px;
    padding: 15px;
  }

  .select2-container--default .select2-selection--single .select2-selection__arrow {
    top: 50%;
    transform: translateY(-50%);
    right: 14px;
  }

  .select2-container .select2-selection--single .select2-selection__rendered {
    padding-right: 0;
  }




}

.select2-dropdown {
  border-color: #FFF !important;
}

.select2-results__option {
  color: #171717 !important;
  font-family: $public-sans-medium !important;
  font-size: 13px !important;
  line-height: 1.307692308 !important;
  padding: 10px 22px !important;
}

.select2-container--default .select2-results__option--highlighted[aria-selected] {
  background: $dark-green !important;
  color: #FFF !important;
}

.select2-container--default .select2-search--dropdown .select2-search__field {
  height: 44px;
  border-radius: 3px;
  border: 1px solid #bababa !important;
  background-color: #ffffff;
  background: url('../img/map-search.svg') no-repeat 95% 50%;
}
