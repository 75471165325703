.custom-table {
  margin: 30px 0 50px;

  &__row {
    @include flex-row();
  }

  table {
    border-collapse: collapse;
    margin: 0;
    padding: 0;
    width: 100%;
    table-layout: fixed;
    text-align: left;

    thead {
      width: 100%;

      tr {
        background-color: transparent;
        width: 100%;
      }

      th {
        font-family: $public-sans-semibold;
        letter-spacing: 0.9px;
        text-transform: uppercase;
        font-size: 18px;
        font-weight: 600;
        padding: 30px 10px;
        line-height: 1.2;

        // @media only screen and (min-width: 769px) {
        //   width: 25%;

        //   &:last-child {
        //     width: 50%;
        //     text-align: right;
        //   }
        // }
      }
    }

    tbody {
      width: 100%;

      tr {
        background-color: #fff;
        width: 100%;

        &:nth-child(even) {
          background-color: transparent;
        }
      }

      td {
        font-size: 16px;
        font-weight: 400;
        padding: 30px 10px;
        line-height: 1.2;
        position: relative;


        // @media only screen and (min-width: 769px) {
        //   width: 25%;
        // }


        &:first-child {
          border-right: 3px solid #f3f3f3;
          font-family: $public-sans-semibold;

          @media (min-width:769px) {
            width: 25%;
          }

        }

        &:nth-child(2) {
          font-family: $merriweather-bold;
        }

        // @media only screen and (min-width: 769px) {
        //   &:last-child {
        //     width: 50%;
        //     text-align: right;
        //   }
        // }

        .button {
          font-size: 13px;
          border-radius: 50px;
          padding: 12px 20px;
          max-width: 310px;
          line-height: 1.3;
          word-break: break-word;
        }
      }
    }
  }

  @media screen and (max-width: 768px) {
    table {
      border: 0;

      thead {
        border: none;
        clip: rect(0 0 0 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px;
      }

      tr {
        border-bottom: 3px solid #e4e5f3;
        display: block;
      }

      tbody {
        td {
          border-bottom: 1px solid #e4e5f3;
          display: block;
          font-size: 16px;
          text-align: right;
          padding: 14px 16px 12px;
          padding-left: 70px;

          &::before {
            content: attr(data-label);
            font-family: $public-sans-semibold;
            float: left;
            font-weight: bold;
            text-transform: uppercase;
            position: absolute;
            left: 10px;
          }

          &:not(:last-child) {
            border-right: 0;

          }

          &:last-child {
            border-bottom: 0;
          }
        }
      }

      .file-size {
        display: block;
        margin-top: 10px;
      }
    }
  }
}
