.site-header {
  $parent: &;
  display: flex;
  flex: 0 1 auto;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: 0 auto;
  padding: 0 27px;
  background-color: $white;

  @include media($post-tablet) {
    // align-items: flex-end;
    padding: 0 15px 31px;
  }

  @media #{$xlarge-up} {
    padding: 0 30px 31px;
  }

  &__mobile-toggle {
    position: relative;
    top: 0px;
    width: 30px;
    height: 25px;
    cursor: pointer;
    z-index: 1;

    @include media($post-tablet) {
      display: none;
    }
  }

  &__mobile-hamburger {
    position: absolute;

    left: -10px;
    top: -10px;

    width: 48px;
    height: 48px;

    &__line {
      top: 10px;
      left: 10px;
      position: absolute;
      width: 30px;
      height: 4px;
      transition-timing-function: ease;
      transition-duration: 150ms;
      transition-property: transform;
      border-radius: 5px;
      background-color: $blue;

      &::before,
      &::after {
        content: '';
        display: block;
        position: absolute;
        width: 30px;
        height: 4px;
        transition-timing-function: ease;
        transition-duration: 150ms;
        transition-property: transform;
        border-radius: 5px;
        background-color: $blue;
      }

      &::before {
        top: 10px;
        transition-timing-function: ease;
        transition-duration: 150ms;
        transition-property: transform, opacity;
      }

      &::after {
        top: 20px;
      }
    }

    &--active {
      .site-header__mobile-hamburger__line {
        transform: translate3d(0, 10px, 0) rotate(45deg);

        &::before {
          transform: rotate(-45deg) translate3d(-5.71429px, -6px, 0);
          opacity: 0;
        }

        &::after {
          transform: translate3d(0, -20px, 0) rotate(-90deg);
        }
      }
    }

  }

  &__logo {
    margin-top: 10px;
    max-width: 180px;

    @media #{$large-up} {
      max-width: 125px;
    }

    @media (min-width:1300px) {
      max-width: 150px;
    }

    width: 100%;

    a {
      display: block;

    }

    svg {
      height: auto;
      max-width: 222px;
      width: 100%;
    }
  }

  &__menu {
    position: fixed;
    top: 0;
    left: -102%;
    background-color: $white;
    padding: 30px 0 0;
    height: 100%;
    display: block;
    width: 100%;
    max-width: 85%;
    transition: left 400ms ease-in-out;
    box-shadow: 0 2px 64px rgba(0, 0, 0, 0.5);
    overflow-y: scroll;
    z-index: 10;

    @include media($post-tablet) {
      position: relative;
      align-items: flex-start;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;
      height: auto;
      padding: 0;
      left: 0;
      box-shadow: 0 0 0 0 transparent;
      max-width: calc(100% - 125px);
      overflow: visible;
      padding-left: 20px;
    }

    @media (min-width:1300px) {
      max-width: calc(100% - 150px);
    }

    .site-header__mobile-toggle {
      left: 29px;
      margin-bottom: 20px;
    }

    .landing-link {
      a::after {
        content: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMyIgaGVpZ2h0PSIxNSIgdmlld0JveD0iMCAwIDEzIDE1Ij48Zz48Zz48cGF0aCBmaWxsPSIjOGI5YzIxIiBkPSJNOCA3LjVsLS41NS42MzZMMS41MSAxNSAwIDEzLjcyOCA1LjM5MyA3LjUgMCAxLjI3MiAxLjUwOSAwIDcuNDUgNi44NjQgOCA3LjV6Ii8+PC9nPjxnPjxwYXRoIGZpbGw9IiM4YjljMjEiIGQ9Ik0xMyA3LjVsLS41NS42MzZMNi41MSAxNSA1IDEzLjcyOCAxMC4zOTMgNy41IDUgMS4yNzIgNi41MDkgMGw1Ljk0MiA2Ljg2NEwxMyA3LjV6Ii8+PC9nPjwvZz48L3N2Zz4=);
        margin-left: 12px;
        vertical-align: middle;
      }

      @include media($post-tablet) {
        display: none;
      }

    }

    &--active {
      left: 0;
    }

    &--main-menu,
    &--utility-menu {
      width: 100%;

      @include media($post-tablet) {
        width: auto;

        li {
          display: inline-block;
          vertical-align: middle;
        }
      }
    }

    &--main-menu {
      border-bottom: 1px solid #b9b9b9;

      @include media($post-tablet) {
        border-bottom: 0;
      }

      >ul {
        >li {
          @include media($post-tablet) {
            position: relative;
            padding: 0px .85vw;

            &::before {
              content: '';
              position: absolute;
              top: 0;
              left: 50%;
              transform: translate(-50%, 0);
              width: calc(100% - 2.50vw);
              height: 5px;
              background-color: transparent;
            }

            &:hover,
            &:focus {
              &::before {
                background-color: $light-green;
              }

              >a,
              >span {
                opacity: 0.6;
              }


              >ul {
                width: 265px;
                height: auto;
                box-shadow:
                  0px -20px 5px -5px rgb(255, 255, 255),
                  0px 14px 21px 0px rgba(0, 0, 0, 0.15);
              }
            }
          }

          a,
          span {
            display: block;
            color: $black;
            font-family: $public-sans-semibold;
            font-size: 19px;
            padding: 12px 0 12px 45px;

            @include media($post-tablet) {
              font-size: 1.1vw;
              text-transform: uppercase;
              border-top: 5px solid transparent;
              padding: 28px 0 30px;
            }

            @media only screen and (min-width: 1441px) {
              font-size: 16px;
            }
          }

          ul {
            display: none;

            @include media($post-tablet) {
              display: block;
              position: absolute;
              left: 50%;
              transform: translate(-50%, 0);
              top: 88%;
              height: 0;
              width: 0;
              z-index: 10;
              padding: 8px 0 0;
              border-radius: 6px;
              overflow: hidden;

              &::before {
                content: '';
                position: absolute;
                top: 8px;
                left: 0;
                width: 100%;
                height: 5px;
                background-color: $light-green;
                border-radius: 6px 6px 0 0;
                z-index: 1;
              }

              &::after {
                content: '';
                position: absolute;
                top: 0;
                left: 50%;
                transform: translate(-50%, 0);
                border-left: 8px solid transparent;
                border-right: 8px solid transparent;
                border-bottom: 8px solid $light-green;
              }
            }

            li {
              display: block;
              background-color: transparent;

              @include media($post-tablet) {
                padding: 0;
                background-color: #fff;

                &:not(:last-child) {
                  border-bottom: 1px solid #d1d3d7;
                }

                &:first-child {
                  border-radius: 6px 6px 0 0;
                  overflow: hidden;

                  >a {
                    padding-top: 24px;
                  }
                }

                // &:last-child {
                //    padding-bottom: 75px;

                //   >a {
                //     border-bottom: 1px solid #d1d3d7;
                //   }
                // }
              }

              a {
                font-size: 17px;

                @include media($post-tablet) {
                  font-size: 16px;
                  padding: 20px 48px;
                  line-height: 1.2;
                  transition: none;

                  &:hover,
                  &:focus {
                    background-color: $light-green;
                    color: #fff;
                  }
                }
              }
            }
          }
        }

        li {
          @media only screen and (max-width: 1024px) {
            &.has-children {
              position: relative;

              >a {
                position: relative;

                &::before {
                  content: '';
                  position: absolute;
                  top: 23px;
                  left: 20px;
                  @include triangle('right', 6px, 12px, #8c8c8c);
                }
              }

              &.active {
                background-color: #e8e8e8;

                >a::before {
                  top: 26px;
                  @include triangle('down', 12px, 6px, $green);
                }

                >ul {
                  display: block;
                }
              }

              .has-children {
                >a::before {
                  top: 16px;
                  left: 20px;

                }

                &.active {
                  background-color: #fff;

                  >a::before {
                    top: 19px;
                  }
                }
              }
            }

            li {
              margin-left: 20px;
              padding: 8px 0;

              a {
                padding: 10px 20px 10px 45px;
                line-height: 23px;
              }

              li {
                margin-left: 0;
                padding-right: 40px;

                a {
                  line-height: 23px;
                }
              }
            }
          }
        }
      }
    }

    &--utility-menu {
      padding: 0 0 0 45px;

      @include media($post-tablet) {
        padding: 0;
      }

      li {
        padding: 12px 0;

        @include media($post-tablet) {
          padding: 0;

          &:not(:first-child):not(:last-child) {
            padding: 0 0.833vw;
          }

          &:first-child {
            padding-right: 0.833vw;
          }

          &:last-child {
            padding-left: 0.833vw;
          }
        }

        a {
          color: $black;
          font-family: $public-sans-medium;
          font-size: 16px;

          @include media($post-tablet) {
            font-size: 1vw;
          }

          @media only screen and (min-width: 1441px) {
            font-size: 15px;
          }

          &:hover,
          &:focus {
            opacity: .6;
          }
        }

        .search-toggle {
          display: none;

          @include media($post-tablet) {
            display: block;
          }
        }
      }
    }
  }

  &__mobile-search {
    @include media($post-tablet) {
      display: none;
    }

    .search-toggle {
      padding: 10px 0;
    }
  }

  &__search-container {
    position: absolute;
    display: flex;
    top: 0;
    right: 0;
    width: 0;
    height: 88px;
    padding: 15px 0;
    overflow: hidden;
    transition: width 300ms ease-in-out, padding-left 300ms ease-in-out, padding-right 300ms ease-in-out;
    justify-content: space-between;
    background-color: $white;
    z-index: 11;

    @include media($post-tablet) {
      height: 116px;
      padding: 30px 0;
    }

    &::before {
      content: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI0OSIgaGVpZ2h0PSI0OSIgdmlld0JveD0iMCAwIDQ5IDQ5Ij48Zz48Zz48cGF0aCBmaWxsPSIjY2NjIiBkPSJNMjEuNDMzIDYuMTI1YzguNDA1IDAgMTUuMzA3IDYuODQ3IDE1LjMwNyAxNS4zMDcgMCA4LjQ2MS02LjkwMiAxNS4zMDgtMTUuMzA3IDE1LjMwOC04LjQwNiAwLTE1LjMwOC02LjkwMi0xNS4zMDgtMTUuMzA4IDAtOC40MDUgNi44NDctMTUuMzA3IDE1LjMwOC0xNS4zMDd6bS0uMDY3IDM2LjU1OGM0Ljg0MyAwIDkuMzQ0LTEuNjUgMTIuOTM0LTQuMzgybDkuODU3IDkuODQ1Yy41Ny41NyAxLjMxLjg1NCAxLjk5NC44NTQuNjg0IDAgMS40ODItLjI4NSAxLjk5NC0uODU0IDEuMTQtMS4xMzggMS4xNC0yLjkwMiAwLTQuMDRsLTkuOC05Ljg0NmMyLjczNS0zLjU4NSA0LjM4OC04LjA4MSA0LjM4OC0xMi45MTlDNDIuNzMzIDkuNTYxIDMzLjE2IDAgMjEuMzY2IDBTMCA5LjU2MSAwIDIxLjM0MWMwIDExLjc4IDkuNTcyIDIxLjM0MiAyMS4zNjYgMjEuMzQyeiIvPjwvZz48L2c+PC9zdmc+);
      margin-left: auto;
      margin-right: 3%;
      vertical-align: middle;
    }

    &.active {
      width: 100%;
      border-bottom: 5px solid #e8e8e8;
      padding: 15px 26px;

      @include media($post-tablet) {
        padding: 30px;
      }
    }

    input {
      width: 100%;
      max-width: 1140px;
      margin-right: auto;
      height: 100%;
      border: 0;
      font-size: 23px;
      font-family: $public-sans-semibold;

      &:focus {
        outline: 0;
      }
    }
  }

  &--homepage {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 10;
    background-color: transparent;

    #{$parent}__mobile-hamburger {
      background-color: $white;

      &::before,
      &::after {
        background-color: $white;
      }

      // &--active {
      //   background-color: $blue;

      //   &::after {
      //     background-color: $blue;
      //   }
      // }
    }

    #{$parent}__mobile-search {
      path {
        fill: $white;
      }
    }

    @include media($large-up) {
      #{$parent}__menu {
        background-color: transparent;

        &--main-menu {
          >ul {
            >li {

              >a,
              >span {
                color: $white;
              }

              &:hover,
              &:focus {
                >ul {
                  box-shadow: 0 0 0 0 transparent;
                }
              }
            }
          }
        }

        &--utility-menu {
          li {
            a {
              color: $white;
            }

            .search-toggle {
              path {
                fill: $white;
              }
            }
          }
        }
      }
    }
  }
}
