// remove margins from direct first & last child elements from `.u-no-children-margin`
.u-no-children-margin {
	> :first-child {
		margin-top: 0 !important;
	}

	> :last-child {
		margin-bottom: 0 !important;
	}
}
