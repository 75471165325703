.breadcrumbs {
  $parent: &;
  padding: 35px 0 10px;

  &__row {
    @include flex-row(1100px);
    align-items: center;
  }

  &__columns {
    @include flex-col(24);

    &:first-child {
      @include flex-col('lg', 17);
      @include med-pad();
    }

    &:last-child {
      @include flex-col('lg', 7);
      @include med-pad();
    }
  }

  &__list {
    display: none;

    @include media($post-tablet) {
      display: block;
    }

    .separator {
      padding: 0 14px;
      font-size: 18px;
      color: #c9c9c9;
    }

    li {
      font-family: $public-sans-medium;
      font-size: 15px;
    }

    a {
      font-family: $public-sans-medium;
      font-size: 15px;
      color: $black;

      &:hover,
      &:focus {
        color: $light-green;
      }
    }
  }

  .social-media-icons {
    ul {
      display: flex;
      flex-wrap: wrap;
    }

    li {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      margin: 0 14px;


      &:first-child {
        margin-left: 0;
      }

      &:last-child {
        margin-right: 0;
      }

      a {
        display: inline-flex;
        line-height: 1;
        border: 1px solid rgba(0, 0, 0, 0.1);
        border-radius: 50%;
        width: 56px;
        height: 56px;
        text-align: center;


        svg {
          position: relative;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }

        &:hover,
        &:focus {
          svg {
            path {
              fill: $light-green;
            }
          }
        }
      }
    }
  }

  &--alt-top-padding {
    padding-top: 12px;
  }

  &--alt-bottom-padding {
    padding-bottom: 16px;
  }
}
