.wdg_grid,
.wdg_overlay {
	position: absolute;
	z-index: 999;
	top: 0;
	width: 100%;
	height: 2000%;
	bottom: 0;
	background-position: 50% 0%;
	background-repeat: repeat-y;
	pointer-events: none;
}

.wdg_grid {
	display: none;
	opacity: 0.8;

	&--active {
		background-image:url(#{$img}overlay/grid--desktop.png);
		display: block !important;
	}
}

.wdg_overlay {
	display: none;
	z-index: 998;
	opacity: .5;
	background-position: 50% 0;
	background-repeat: no-repeat;
	width: 100%;
	background-size: 100% auto;

	.admin-bar & {
		background-position: 50% 46px;

		@media #{$large-up} {
			background-position: 50% 32px;
		}
	}

	&--active {

		display: block !important;
		// background-image:url(#{$img}overlay/School-blog-listing.png);
	}

}
