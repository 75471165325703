.form-module {
  background-image: linear-gradient(180deg, #ffffff 0%, #e2e2e2 100%);
  padding: 80px 0 60px;

  &__row {
    @include flex-row(1355px);
    justify-content: space-between;
    align-items: center;
  }

  &__columns {
    @include flex-col(24);
    @include flex-col('lg', 12);
    @include med-pad();

    &:first-child {
      @include flex-col('xl', 10);
    }

    &:last-child {
      @include flex-col('xl', 12);
    }
  }

  input:not([type="checkbox"]):not([type="radio"]):not([type="search"]):not([type="color"]):not([type="button"]):not([type="submit"]):not([type="reset"]) {
    max-width: 100%;

    @include media($medium-up) {
      width: 49.5%;

    }
  }

  input[type="checkbox"],
  input[type="radio"] {
    @include a11y;

    +label {
      position: relative;
      font-size: 16px;

      &::before {
        content: '';
        display: inline-block;
        width: 20px;
        height: 20px;
        border: 1px solid #a4a4a4;
        background-color: #ffffff;
        margin-right: 5px;
        position: relative;
        top: 3px;
        transition: border-color .25s ease;
      }
    }

    &:checked {
      +label {
        &::before {
          background: #FFF url(../img/checkbox.svg) no-repeat 50% 50%;
        }
      }
    }

    &:focus {
      +label {
        &::before {
          outline-style: auto;
          outline-color: blue;
          outline-width: 5px;
          outline-color: -webkit-focus-ring-color;
          border-color: #A0B426;
        }
      }
    }

    &:disabled {
      +label {
        &::before {
          cursor: default;
          opacity: .75;
        }
      }
    }
  }

  input[type="radio"] {
    +label {
      &::before {
        border-radius: 180%;
      }
    }

    &:checked {
      +label {
        &::before {
          background: #FFF url(../img/radio.svg) no-repeat 50% 50%;
        }
      }
    }
  }

  .fieldgroup {
    padding: 0;
    border-width: 0;
  }



  label,
  .fieldset-legend {
    font-family: $public-sans-medium;
    font-size: 18px;
  }

  select {
    margin-bottom: 16px;
  }
}


.webform-flex {
  legend {
    margin: 0;
    padding: 0;
  }

  label {
    display: block;
  }

  .form-checkbox+label,
  .form-radio+label {
    display: inline-block;
    vertical-align: middle;
  }

  input:not([type="checkbox"]):not([type="radio"]):not([type="search"]):not([type="color"]):not([type="button"]):not([type="submit"]):not([type="reset"]),
  select {
    width: 100%;
  }

  &--container {
    @media #{$tablet-up} {
      .webform-flex--container {
        margin: 0;
      }
    }
  }
}

@media #{$tablet-up} {
  // .webform-flex--3 {
  //   margin: 0 .5em;
  // }
}

.keyboard--focus {


  .form-module {

    input[type="checkbox"],
    [type="radio"] {
      &:focus {
        +label {
          &::before {
            outline: 1px dotted #171717;
          }
        }
      }
    }
  }
}
