.strategy-grid {
  $parent: &;
  color: $black;
  padding: 65px 0 45px;

  &__row {
    position: relative;
    @include flex-row(1355px);
  }

  &__column {
    @include flex-col(24);
    @include med-pad();
  }

  &__columns {
    @include flex-col(24);
    @include flex-col('lg', 8);
    margin-bottom: 20px;
    @include med-pad();

    &:nth-child(3n+1) {
      #{$parent}__title::before {
        background-color: $orange;
      }
    }

    &:nth-child(3n+2) {
      #{$parent}__title::before {
        background-color: $blue-alt;
      }
    }
  }

  h2 {
    color: inherit;
    font-size: em(26);
  }

  hr {
    max-width: 30px;
    margin: 16px 0 22px;
    background-color: $light-green;
  }

  &__image-container {
    position: relative;

    img {
      width: 100%;
    }
  }

  a {
    color: inherit;

    &:hover,
    &:focus {
      .strategy-grid__title {
        //border-bottom: 2px solid $light-green;
        color: $light-green;
      }
    }
  }

  &__intro-title {
    color: $dark-gray;
    font-family: $public-sans-medium;
    font-size: 15px;
    text-transform: uppercase;
    line-height: 1.2;
  }

  &__title {
    position: relative;
    vertical-align: middle;
    color: inherit;
    font-family: $merriweather-bold;
    font-size: 21px;
    line-height: 35px;
    margin-top: 13px;
    margin-bottom: 20px;
    padding-left: 15px;
    //cursor: pointer;
    border-bottom: 2px solid transparent;

    &::before {
      content: '';
      position: absolute;
      top: 7px;
      left: 0;
      width: 4px;
      height: 100%;
      max-height: 50px;
      background-color: $light-green;
    }
  }
}
