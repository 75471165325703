@import "node_modules/@glidejs/glide/src/assets/sass/glide.core";

.section-nav {
  $parent: &;
  background-color: $blue;
  color: $white;

  &__container {
    @include flex-row(1580px);
    margin: 0 0 0 auto;
    justify-content: center;
    padding-left: 30px;
  }

  &__nav {
    @include flex-col(24);
    padding: 16px 0;
    overflow: hidden;
    display: flex;

    li {
      position: relative;
      line-height: 21px;
      font-size: 16px;
      // width: auto !important;

      &:not(:last-child) {
        &::after {
          content: '';
          position: absolute;
          top: 0;
          right: -30px;
          height: 100%;
          width: 1px;
          background-color: #fff;
          opacity: 0.24;
        }
      }
    }

    a {
      color: #ffffff;
      font-family: $public-sans-semibold;
      font-size: 16px;
      font-weight: 600;
      line-height: 21px;
    }
  }

  .glide {
    position: relative;
    padding-left: 20px;
    display: inline-block;
    width: calc(100% - 105px);

    &__track {
      overflow: visible;
    }

    &__slides {
      margin: 0;
      overflow: visible;
    }

    &__slide {
      &:hover {
        >a {
          color: rgba(0, 0, 0, 0.6);
        }

        .glide__slide-dropdown {
          display: block;
        }
      }

      &:focus {
        >a {
          color: rgba(0, 0, 0, 0.6);
        }
      }
    }

    &__arrow {
      position: absolute;
      top: 0;
      width: 50px;
      height: 90%;
      background-color: $blue;

      &--left {
        display: none;
        left: -8px;
        transform: rotate(180deg);
      }

      &--right {
        right: -9px;
      }
    }

    &__slide-dropdown {
      position: absolute;
      display: none;
      top: 100%;
      left: 50%;
      transform: translate(-50%, 0);
      width: 300px;
      background-color: $dark-blue;
      z-index: 3;
      color: $white;
      border-radius: 6px;
      box-shadow: 0 11px 21px rgba(0, 0, 0, 0.15);

      &::before {
        content: '';
        position: absolute;
        top: -8px;
        left: 50%;
        transform: translate(-50%, 0);
        @include triangle('up', 14px, 8px, $dark-blue);
      }

      ul {
        list-style: none;
        margin: 0;
        padding: 25px 0 75px;

        li {
          margin: 0;
          padding: 0;
          border-bottom: 1px solid #424a58;

          &::after {
            display: none;
          }
        }
      }

      a {
        position: relative;
        display: block;
        padding: 18px 50px;
        color: $white;

        &:hover,
        &:focus {
          color: #a7adb6;

          &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 5px;
            height: 100%;
            background-color: $light-green;
          }
        }
      }
    }
  }

  &__nav-item {
    position: relative;
    display: inline-block;
    font-family: $public-sans-light;
    font-size: 16px;
    line-height: 21px;
    max-width: 96px;
    padding-right: 20px;
    z-index: 2;
    background-color: $blue;

    &::after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      height: 100%;
      width: 1px;
      background-color: $white;
      opacity: 0.24;
    }
  }

  &--white-bg {
    background-color: $white;

    a {
      color: #000;
    }

    #{$parent}__nav {
      border-top: 1px solid rgba(0, 0, 0, 0.24);
      border-bottom: 1px solid rgba(0, 0, 0, 0.24);

      li:not(:last-child)::after {
        background-color: #000;
      }
    }
  }

  &--media-center {
    margin-top: 35px;
    padding: 0 20px;

    #{$parent}__container {
      position: relative;
      max-width: 1070px;
      margin: 0 auto;
      padding: 0;

      &::before {
        content: '';
        position: absolute;
        top: 0;
        width: 100%;
        height: 90%;
        right: 100%;
        background-color: $white;
        z-index: 2;
      }

      &::after {
        content: '';
        position: absolute;
        top: 0;
        width: 100%;
        height: 90%;
        left: 100%;
        background-color: $white;
        z-index: 2;
      }

    }

    #{$parent}__nav {
      padding: 0;
      overflow: visible;

      li {
        padding: 10px 0;
        margin: 10px 0 5px;
      }

      li:not(:last-child)::after {
        right: -20px;
      }
    }

    .glide {
      padding-left: 0;
      width: 100%;

      &__arrow {
        background-color: $white;

        svg path {
          stroke: $black;
        }
      }
    }
  }
}
