@font-face {
    font-family: 'merriweather';
    src: url('../fonts/merriweather-regular-webfont.woff2') format('woff2'),
         url('../fonts/merriweather-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'merriweather-bold';
    src: url('../fonts/merriweather-bold-webfont.woff2') format('woff2'),
         url('../fonts/merriweather-bold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'public_sans-medium';
    src: url('../fonts/publicsans-medium-webfont.woff2') format('woff2'),
         url('../fonts/publicsans-medium-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'public_sans-light';
    src: url('../fonts/publicsans-light-webfont.woff2') format('woff2'),
         url('../fonts/publicsans-light-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'public_sans-regular';
    src: url('../fonts/publicsans-regular-webfont.woff2') format('woff2'),
         url('../fonts/publicsans-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'public_sans-semibold';
    src: url('../fonts/publicsans-semibold-webfont.woff2') format('woff2'),
         url('../fonts/publicsans-semibold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'bebas-neue';
    src: url('../fonts/BebasNeue-Regular.woff2') format('woff2'),
         url('../fonts/BebasNeue-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}