#mapbox-container {
  @media #{$large-down} {
    display: none;
  }
}

.map {
  position: relative;
  max-width: 1660px;
  margin: auto auto 50px;
  width: 100%;

  margin-top: 104px;
  padding-bottom: 45px;

  &::before {
    content: '';
    display: block;
    width: 200vw;
    background: #ECEFF4;
    position: absolute;
    height: 100%;
    left: -50%;
    top: 0;
    bottom: 0;
    right: 0;
  }

  &__wrap {
    display: flex;
    position: relative;

    // &--active {
    //   .map__listing {
    //     display: block;
    //   }
    // }
  }

  &__listing {
    width: 307px;
    height: 758px;
    overflow: hidden;
    overflow-y: auto;

    &__empty {
      padding-top: 100px;
      text-align: center;

      &__text {

        font-family: $merriweather;
        font-size: 16px;
        color: #171717;
        letter-spacing: 0;
        text-align: center;
        line-height: 30px;

        padding: 0px 70px;
      }
    }
  }
}

.map-projects {
  flex: 0 1 307px;
  padding: 55px 40px 30px;
  position: relative;

  &::before {
    content: '';
    display: block;
    background: #bec7cf;
    height: 1px;
    width: calc(100% - 70px);
    left: 20px;
    position: absolute;
    bottom: 0;
  }

  &::after {
    content: '';
    display: block;
    width: calc(100% - 70px);
    left: 20px;
    height: 20px;
    position: absolute;
    bottom: -20px;
    background: linear-gradient(to bottom, rgba(236, 239, 244, 1) 0%, rgba(236, 239, 244, 0.53) 47%, rgba(125, 185, 232, 0) 100%);
    z-index: 2;
  }

  &__title {
    margin-bottom: 0;
  }

  &__count {
    color: #2c84ab;
    font-family: $public-sans-semibold;
    font-size: 42px;
    text-transform: uppercase;
    display: block;
    margin-bottom: 9px;
  }

  &__text {
    color: #4c4c4c;
    font-family: $public-sans-medium;
    font-size: 15px;
    text-transform: uppercase;
    display: block;
  }
}

.map-controls {
  display: flex;
  position: relative;
  align-items: flex-end;

  &__wrap {

    &:last-child {
      margin-bottom: 20px;

      @media (min-width:1600px) {
        margin-bottom: 0;
      }
    }

    &--flex {
      display: flex;

      .map-controls__fieldset {
        margin-right: 15px;

        &:last-child {
          margin-right: 0;
          ;
        }
      }
    }
  }

  &__yearflex {
    display: flex;
  }

  &__form {
    position: relative;
    display: flex;
    flex: 0 1 calc(100% - 307px);

    padding: 56px 10px 0 0;
    flex-direction: column;

    @media (min-width:1600px) {
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
    }
  }

  &__fieldset {
    border: none;
    padding: 0;
    // margin: 0 30px 0 0;
  }

  &__legend,
  &__label {
    display: block;
    border: none;
    padding: 0;
    margin: 0 0 5px;
  }

  &__select {
    min-width: 150px;
    max-width: 300px;
    width: 300px;

    &__state {
      width: 200px;
    }

    &--year {
      width: 75px;
    }
  }

  &__years {
    &-range {
      display: flex;
      align-items: center;


    }
  }

  &__radio {
    position: absolute;
    top: 0;

    input {
      &:checked {
        +label {
          border-color: $green;

          &:hover,
          &:focus {
            border-color: $green;
          }
        }
      }
    }

    label {
      color: #171717;
      font-family: $public-sans-semibold;
      font-size: 12px;
      font-weight: 600;
      margin-right: 25px;
      border-top: 4px solid transparent;
      padding-top: 10px;
      transition: all .25s ease-in;

      &:hover,
      &:focus {
        border-color: $black;
      }
    }
  }
}


.map-range-hyphen {
  color: #4c4c4c;
  font-family: $public-sans-semibold;
  font-size: 13px;
  margin: 0 5px;
}

.mapbox {
  transition: all .25s ease;
  height: 758px;
  // width: calc(100% - 10px);
  width: calc(100% - 306px);
  background: #EEE;
  border: 1px solid #979797;

}



.project {
  padding: 0px 20px;
  position: relative;
  overflow: hidden;

  &::after {
    content: '';
    display: block;
    border-bottom: 2px dashed #bec7cf;

    width: calc(100% - 20px);
    padding-top: 25px;
  }

  &__year {
    display: block;
    opacity: 0.8;
    color: #000000;
    font-family: $public-sans-semibold;
    font-size: 11px;
    letter-spacing: 1.44px;
    padding-top: 13px;
  }

  &__title {
    margin: 0;
    line-height: 1;
    font-size: 1rem;
  }

  &__toggle {
    color: #000000;
    font-family: $merriweather-bold;
    font-size: 15px;
    line-height: 1.733333333;

    background: none;
    text-align: left;
    position: relative;

    padding: 5px 15px 5px 0;
    width: 100%;
    box-sizing: border-box;

    &::after {
      content: '';
      display: block;
      position: absolute;
      top: 10px;
      right: 0px;
      border-style: solid;
      height: 0;
      width: 0;
      border-color: transparent transparent transparent #7c8d10;

      border-width: 6px 0 6px 6px;
      transition: all .25s ease;
    }

    &[aria-expanded="true"]::after {
      transform: rotate(90deg);
    }
  }

  &__description {
    display: none;
    position: relative;

    &::after {
      content: '';
      display: block;
      position: absolute;
      background: transparent;
      width: 6px;
      height: 300%;
      top: -100%;
      left: -20px;
      transition: all .25s ease;

    }

    &[style*="block;"],
    &[style*="height"] {
      &::after {
        background: #a0b426;
      }
    }

    p {
      color: #000000;
      font-family: $merriweather;
      font-size: 13px;
      line-height: 1.538461538;

      &.project__amount {
        font-family: $merriweather-bold;
        line-height: 2;


        .project__amount__number {
          font-size: 15px;
        }

      }
    }
  }

  &__program {
    p {
      color: #000000;
      font-family: $merriweather;
      font-size: 13px;
      line-height: 2;


    }
  }



  &__link {
    font-size: 16px;
    font-weight: bold;
    text-decoration: none;
  }

  &__pop {
    display: none;
  }
}

.mapboxgl-popup-close-button {
  font-size: 24px;
  margin-right: 5px;
}

.filters,
#goHome {
  background: #000;
  color: #FFF;
  padding: 5px 10px;
  cursor: pointer;
}

.filters:hover,
#goHome:hover {
  background: #FFF;
  color: #000;
}


.mapboxgl-popup-content {
  h2 {
    font-family: $merriweather-bold;
    font-size: 15px;
    color: #000000;
    letter-spacing: 0;
    line-height: 1.733333333;

  }

  p {
    font-family: $merriweather;
    font-size: 13px;
    color: #000000;
    letter-spacing: 0;
    line-height: 1.538461538;
  }
}


.map__loading {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  background: rgba(#1E242F, .8);
  pointer-events: none;
  transition: all .25s ease;

  &--hide {
    opacity: 0;
  }
}

.select2-results__option {
  font-size: 16px;
  line-height: 1.2;

}


.button--map {
  font-size: 15px;
  padding: 8px 10px;
  margin-top: 10px;
}


.map-animate {
  width: 60px;
  height: 60px;
  border: 3px solid rgba(#bfc4cb, 0.33);
  display: block;
  position: relative;
  border-radius: 180%;
  transition: all .25s ease;
  margin-left: 15px;

  &__svg {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    svg {
      transition: inherit;
      height: 19px;
      fill: $dark-green;
    }

    &__pause {
      display: none;

      svg {
        height: 37px;
        position: relative;
        top: 5px;
      }
    }


  }

  &:hover,
  &:focus {
    background: $green;
    border-color: $green;

    svg {
      fill: #FFF;
    }
  }
}


.map-range-hide--hiden {
  display: none;
}

//https://loading.io/css/

.lds-roller {
  display: inline-block;
  position: relative;
  width: 64px;
  height: 64px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  div {
    animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    transform-origin: 32px 32px;

    &:after {
      content: " ";
      display: block;
      position: absolute;
      width: 6px;
      height: 6px;
      border-radius: 50%;
      background: #fff;
      margin: -3px 0 0 -3px;
    }

    &:nth-child(1) {
      animation-delay: -0.036s;
    }

    &:nth-child(1):after {
      top: 50px;
      left: 50px;
    }

    &:nth-child(2) {
      animation-delay: -0.072s;
    }

    &:nth-child(2):after {
      top: 54px;
      left: 45px;
    }

    &:nth-child(3) {
      animation-delay: -0.108s;
    }

    &:nth-child(3):after {
      top: 57px;
      left: 39px;
    }

    &:nth-child(4) {
      animation-delay: -0.144s;
    }

    &:nth-child(4):after {
      top: 58px;
      left: 32px;
    }

    &:nth-child(5) {
      animation-delay: -0.18s;
    }

    &:nth-child(5):after {
      top: 57px;
      left: 25px;
    }

    &:nth-child(6) {
      animation-delay: -0.216s;
    }

    &:nth-child(6):after {
      top: 54px;
      left: 19px;
    }

    &:nth-child(7) {
      animation-delay: -0.252s;
    }

    &:nth-child(7):after {
      top: 50px;
      left: 14px;
    }

    &:nth-child(8) {
      animation-delay: -0.288s;
    }

    &:nth-child(8):after {
      top: 45px;
      left: 10px;
    }
  }
}






@keyframes lds-roller {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}


#map {
  .mapboxgl-popup-content {
    background: #FFFFFF;
    border-radius: 8px;
    // padding: 35px 35px 20px;
    padding: 20px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.7);
  }

  .mapboxgl-popup {
    max-width: 286px !important;
    width: 100%;
  }

  .mapboxgl-image {
    margin: -20px -20px 10px;
    border-radius: 8px 8px 0 0;
    overflow: hidden;

    img {
      display: block;
    }
  }

  .mapboxgl-popup-close-button {
    margin-right: 0;
    border-radius: 0 8px 0 0;
    right: 0px;
    width: 20px;
    transition: all .25s ease;
    background: #FFF;

    &:hover,
    &:focus {
      background: $green;
      color: #FFF;
    }
  }

  .mapboxgl-popup {
    transition: opacity .15s ease;

    &--hide {
      opacity: 0;
    }
  }
}
