.related-species {
  $parent: &;
  color: $black;
  padding: 65px 0 90px;

  &__row {
    position: relative;
    @include flex-row(1355px);
  }

  &__column {
    @include flex-col(24);
    @include med-pad();
  }

  &__columns {
    @include flex-col(24);
    @include flex-col('md', 12);
    @include flex-col('lg', 6);
    @include med-pad();
  }

  a {
    color: inherit;

    &:hover,
    &:focus {
      .related-species__title {
        color: $light-green;
      }
    }
  }

  h2 {
    color: inherit;
    font-size: em(26);
  }

  hr {
    max-width: 30px;
    margin: 16px 0 22px;
    background-color: $light-green;
  }

  &__image-container {
    position: relative;
    cursor: pointer;

    img {
      width: 100%;
    }
  }

  &__title {
    position: relative;
    vertical-align: middle;
    color: inherit;
    font-family: $public-sans-semibold;
    font-size: 18px;
    line-height: 29px;
    text-transform: uppercase;
    margin-top: 13px;
    margin-bottom: 30px;
    padding-left: 30px;
    cursor: pointer;

    &::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 0;
      transform: translate(0, -50%);
      width: 20px;
      height: 3px;
      background-color: $light-green;
    }
  }

  &__content {
    position: absolute;
    left: 15px;
    width: calc(100% - 25px);
    padding-top: 100px;
    padding-bottom: 90px;
    display: none;

    h3 {
      font-family: $merriweather-bold;
      font-size: 48px;
      line-height: 57px;
    }

    p {
      margin-bottom: 50px;
    }
  }

  &--no-bg {
    background-color: #fff;
    color: $black;
    border-bottom: 1px solid $gray;
  }
}
