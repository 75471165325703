.offices-list {
  $parent: &;
  padding: 75px 0 0;

  &:not(:last-child) {
    .offices__row:last-child {
      border-bottom: 1px dashed $gray;
    }
  }

  &:last-child {
    .offices__row:last-child {
      margin-bottom: 50px;
    }
  }

  .offices {
    &__row {
      @include flex-row(1100px);

      &:last-child {
        padding-bottom: 20px;
      }
    }

    &__column,
    &__columns {
      @include flex-col(24);
      @include med-pad();
    }

    &__columns {
      &--two-columns {
        font-family: $public-sans-regular;
        font-size: 18px;
        line-height: 33px;
        color: #4c4c4c;

        p {
          margin-bottom: 0;

          &~p {
            margin-top: 5px;
          }
        }

        &:first-child:not(:last-child) {
          @include flex-col('md', 12);
          @include flex-col('lg', 8);
          @include med-pad();
        }

        &:nth-child(2) {
          @include flex-col('md', 12);
          @include flex-col('lg', 16);
          @include med-pad();
        }
      }

      &--three-columns {
        @include flex-col('md', 12);
        @include flex-col('lg', 8);
        @include med-pad();
        margin-top: 15px;
        margin-bottom: 15px;

        p {
          margin-top: 0;
          font-family: $public-sans-medium;
          font-size: 16px;
          line-height: 1.3;

          a {
            display: inline-block;
            margin-top: 10px;
          }
        }
      }
    }

    &__staff-title {
      font-family: $merriweather-bold;
      font-size: 28px;
      line-height: 38px;
      margin-top: 70px;
      margin-bottom: 20px;
    }

    &__staff-name {
      font-family: $public-sans-semibold;
      text-transform: uppercase;
      font-size: 18px;
      letter-spacing: 0.9px;
      color: #000;
    }
  }

  h2 {
    font-size: 28px;
    line-height: 37px;

    @include media($tablet-up) {
      font-size: 48px;
      line-height: 57px;
    }
  }

  hr {
    margin-bottom: 10px;
  }

  &--alt-padding {
    padding-top: 0;

    &:last-child .offices__row:last-child {
      margin-bottom: 20px;
    }
  }
}
