.standard-content {
  max-width: 1100px;
  margin: 40px auto 20px;

  &__row {
    @include flex-row(1040px);
    margin-left: 0;

    &--alt-width {
      @include flex-row(1340px);
    }
  }

  &__column {
    @include flex-col();
    @include med-pad();
  }

  &--no-max-width {
    max-width: 100%;
  }
}
