.trending-content {
  $parent: &;
  padding: 70px 0 0;


  &__row {
    @include flex-row(1355px);
    align-items: center;
    padding: 0 0 70px;
    border-bottom: 1px solid #bfbfbf;
    justify-content: space-between;

  }

  &__columns {
    @include flex-col(24);
    @include med-pad();

    &:first-child {
      @media only screen and #{$medium-up} {
        @include flex-col(14);
      }

      @media only screen and #{$xlarge-up} {
        @include flex-col(12);
      }
    }

    &:last-child {
      @media only screen and #{$medium-up} {
        @include flex-col(10);

      }



      @media only screen and #{$xlarge-up} {
        @include flex-col(12);
      }

      @include med-pad();
    }
  }

  &__content {
    max-width: 550px;

    h2 {
      font-size: 36px;

      line-height: 1.1875;

      @media #{$tablet-up} {
        font-size: 48px;
      }

      @media (min-width:960px) and (max-width:1200px) {
        font-size: 36px;
      }


    }

    hr {
      max-width: 50px;
      margin-left: 0;
      background-color: $light-green;
      height: 3px;
    }

    p {
      max-width: 535px;
    }
  }

  &__topic {
    color: $dark-gray;
    font-family: $public-sans-medium;
    font-size: 15px;
    text-transform: uppercase;
  }

  img {
    margin-bottom: 30px;
  }

  &--reverse {
    #{$parent}__columns {
      @media only screen and #{$medium-up} {
        &:first-child {
          order: 2;
        }

        &:last-child {
          order: 1;

        }
      }

      @media only screen and #{$xlarge-up} {
        &:last-child {


          #{$parent}__content {
            margin-left: auto;
            margin-right: 120px;
          }
        }
      }
    }
  }

  &--background {
    background-color: $dark-blue;
    color: $white;

    #{$parent}__row {
      border-bottom: 0;
    }

    #{$parent}__topic {
      color: $white;
    }
  }

  .video-play {
    display: flex;
    align-items: center;
    color: $green;
    font-family: $public-sans-semibold;
    font-size: 16px;
    letter-spacing: 1.33px;
    text-transform: uppercase;

    svg {
      margin-right: 20px;
    }

    &:hover,
    &:focus {
      color: $light-green;
    }
  }
}
