.partners-grid {
  margin-top: 90px;

  &__row {
    @include flex-row(1520px);
    overflow: hidden;
    justify-content: center;
  }

  &__column {
    @include flex-col(24);
    @include med-pad();
    text-align: center;
  }

  &__columns {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50%;
    border-left: 1px solid #d8d8d8;
    padding: 0 30px;
    margin: 45px 0;

    @include media($medium-down) {
      &:nth-child(odd) {
        border-left: 0;
      }
    }

    @include media($medium-up) {
      width: 33.3333333%;
    }

    @include media($medium-only) {
      &:nth-child(3n + 1) {
        border-left: 0;
      }
    }

    @include media($large-up) {
      width: 25%;
    }

    @include media($large-only) {
      &:nth-child(4n + 1) {
        border-left: 0;
      }
    }

    @include media($xlarge-up) {
      width: 20%;

      &:nth-child(5n + 1) {
        border-left: 0;
      }
    }

    &:first-child {
      border-left: 0;
    }
  }

  h2 {
    position: relative;
    text-align: center;
    font-size: 26px;
    display: inline-block;
    padding: 0 42px;

    &::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 100%;
      width: 100vw;
      height: 1px;
      transform: translate(0, -50%);
      background-color: #bfbfbf;
    }

    &::after {
      content: '';
      position: absolute;
      top: 50%;
      right: 100%;
      width: 100vw;
      height: 1px;
      transform: translate(0, -50%);
      background-color: #bfbfbf;
    }
  }
}
