@mixin a11y() {
	position: absolute;
	width: 1px;
	height: 1px;
	padding: 0;
	overflow: hidden;
	clip: rect(0, 0, 0, 0);
	white-space: nowrap;
	-webkit-clip-path: inset(50%);
	clip-path: inset(50%);
	border: 0;

	&-focusable {

		&:focus,
		&:active {
			position: static;
			width: auto;
			height: auto;
			overflow: visible;
			clip: auto;
			white-space: normal;
			-webkit-clip-path: none;
			clip-path: none;
		}
	}
}

@mixin a11y_show() {
	position: static;
	width: auto;
	height: auto;
	overflow: visible;
	clip: auto;
	white-space: normal;
	-webkit-clip-path: none;
	clip-path: none;
}
