.accordions {
  margin: 20px 0;

  &__row {
    @include flex-row(1100px);
  }

  &__column {
    @include flex-col(24);
    @include med-pad();
  }

  .accordion {
    position: relative;
    color: #171717;
    font-family: $merriweather-bold;
    font-size: 18px;
    line-height: 28px;
    width: 100%;
    text-align: left;
    transition: 0.4s;
    padding: 16px 0 16px 60px;
    border-bottom: 1px dashed #9f9f9f;

    @media #{$tablet-up} {
      font-size: 28px;
      line-height: 38px;
    }

    @media screen and (min-width:1100px) {
      padding-left: 0;
      margin-left: 30px;
      width: calc(100% - 30px);

    }

    .icon {
      position: absolute;
      display: block;
      top: 50%;
      left: 0px;
      transform: translate(0, -50%);
      width: 40px;
      height: 40px;
      border-radius: 50%;
      overflow: hidden;
      text-align: center;
      z-index: 1;

      @media screen and (min-width:1100px) {
        left: -50px;
      }

      &::after {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        @include triangle('right', 7px, 13px, $light-green);
      }
    }

    &:hover,
    &:focus {
      .icon {
        border: 1px solid #CDCDCD;
      }
    }

    &.active {
      .icon {
        &::after {
          @include triangle('down', 13px, 7px, $light-green);
        }
      }
    }
  }

  .panel {

    background-color: white;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.2s ease-out;

    @media screen and (min-width:1100px) {
      padding: 0 30px;
    }

    .inner-container {
      padding: 20px 0;

      @media #{$tablet-up} {
        padding: 40px 0;
      }
    }

    p {
      margin-top: 0;

      @media #{$tablet-down} {
        font-size: 16px;
      }
    }

    .offices__column,
    .offices__columns {
      padding: 0;
    }
  }
}
