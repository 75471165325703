.related-content {
  $parent: &;
  padding: 35px 0 55px;

  @include media($tablet-up) {
    padding: 94px 0 85px;
  }

  &__row {
    @include flex-row(1355px);

    &:nth-child(2) {
      #{$parent}__column {
        padding-bottom: 15px;
        margin-bottom: 45px;
        width: 100%;

        @include media($tablet-up) {
          padding-bottom: 45px;
        }

        &:not(:last-child) {
          border-bottom: 2px dashed #9f9f9f;
        }
      }
    }
  }

  &__column {
    padding: 0 25px;

    @media #{$tablet-up} {
      display: flex;
      @include flex-col(24);


      &.contextual-region {
        align-items: center;
      }
    }

    @include med-pad();
  }

  &__content {
    display: inline-block;
    vertical-align: middle;

    @include media($medium-up) {
      margin-right: 70px;
    }

    @include media($tablet-up) {
      max-width: 70%;
      padding-right: 5%;
    }

    &~img {
      display: inline-block;
      vertical-align: middle;
    }
  }

  &__cat {
    color: $black;
    font-family: $public-sans-semibold;
    font-size: 11px;
    line-height: 1;
    font-weight: 600;
    letter-spacing: 1.44px;
    text-transform: uppercase;
    margin-bottom: 2px;
  }

  h2 {
    font-size: 26px;
    display: inline-block;
    margin-right: 40px;
    vertical-align: top;
    line-height: 38px;

    @include media($small-down) {
      hr {
        margin-bottom: 0;
      }
    }

    ~a {
      font-family: $public-sans-semibold;
      font-size: 14px;
      letter-spacing: 1.17px;
      text-transform: uppercase;
      vertical-align: top;

      @include media($small-down) {
        display: block;
        margin-bottom: 20px;
      }

      &:hover,
      &:focus {
        svg {
          path {
            stroke: $light-green;
          }
        }
      }

      svg {
        position: relative;
        top: 2px;
        width: 6px;
        margin-left: 10px;

        path {
          stroke: $dark-green;
        }
      }
    }
  }

  hr {
    max-width: 30px;
    margin: 8px 0 50px;
    background-color: $light-green;
  }

  h3 {
    font-size: 18px;
    line-height: 31px;

    @include media($tablet-up) {
      font-size: 28px;
      line-height: 38px;
    }

    a {
      color: inherit;
      border-bottom: 2px solid transparent;

      &:hover,
      &:focus {
        border-color: $light-green;
      }
    }

    svg {
      width: 32px;
      height: 32px;
      margin-left: 24px;
      margin-right: 5px;
      vertical-align: middle;

      @include media($small-down) {
        margin-left: 10px;
      }

      ~span {
        vertical-align: middle;
        font-family: $public-sans-medium;
        font-size: 15px;
        text-transform: uppercase;
      }
    }
  }

  p {
    font-size: 16px;
    line-height: 31px;
    margin-bottom: 0;

    @include media($tablet-up) {
      font-size: 18px;
    }
  }

  &--alt-top-padding {
    padding-top: 30px;
  }

  &--no-bottom-padding {
    padding-bottom: 0;
  }
}
