.grid {
  $parent: &;
  padding: 70px 0 65px;

  &__row {

    &:first-child,
    &:nth-child(3) {
      @include flex-row(1100px);
    }

    &:nth-child(2) {
      @include flex-row(1355px);
    }

    &:nth-child(3) {
      justify-content: center;
    }
  }

  &__column {
    @include flex-col(24);
    @include med-pad();
  }

  &__columns {
    @include flex-col(12);
    @include flex-col('lg', 8);
    @include med-pad();
  }

  &__filters {
    @media #{$tablet-down} {
      margin-bottom: 30px;
    }

    input,
    select {
      width: 100%;
      padding: 25px;
      border-radius: 5px;
      border: 1px solid #bababa;
      background-color: transparent;
      color: $black;
      font-family: $public-sans-semibold;
      font-size: 14px;


      &:focus {
        outline: 0;
      }
    }

    // select:not([multiple]) {
    //   margin-bottom: 15px;
    // }

    input {
      max-width: 415px;
      margin-right: 20px;
      margin-bottom: 50px;

      &:not([type="checkbox"]):not([type="radio"]):not([type="search"]):not([type="color"]):not([type="button"]):not([type="submit"]):not([type="reset"]) {
        border: 1px solid #bababa;
        border-radius: 5px;
        color: #171717;
        font-size: 16px;
        font-weight: 600;
        padding: 15px 40px 15px 20px;
        background-color: #fff;
        background: url(../img/search-icon.svg) no-repeat 95% 50%;

        @media (max-width:768px) {
          max-width: 100%;
        }

      }

      &::placeholder {
        color: $black;
      }
    }

    .select-wrapper {
      position: relative;

      width: 100%;
      display: inline-block;
      margin-bottom: 15px;

      @media #{$small-up} {
        max-width: calc(50% - 20px);
        margin-right: 35px;

        &:last-child {
          margin-right: 0;
        }
      }

      @media #{$tablet-up} {
        margin-bottom: 50px;
      }

      @media (min-width:769px) {
        max-width: 290px;
        margin-right: 20px;
      }

      // &::after {
      //   content: '';
      //   position: absolute;
      //   top: 50%;
      //   right: 20px;
      //   transform: translate(0, -50%);
      //   width: 0;
      //   height: 0;
      //   @include triangle('down', 12px, 6px, #7c8d10);
      // }
    }

    select {
      appearance: none;
    }
  }

  h2 {
    font-size: 36px;
  }

  hr {
    max-width: 50px;
    margin-left: 0;
    background-color: $light-green;
  }

  img {
    width: 100%;
    margin-bottom: 16px;
  }

  a {
    color: inherit;

    &:hover,
    &:focus {
      color: $light-green;
    }
  }

  h3 {
    font-size: 16px;
    line-height: 1.5;
    margin-bottom: 40px;

    @media #{$small-up} {
      font-size: 22px;
    }
  }


  &--title-dash {
    h3 {
      @media #{$tablet-up} {
        &::before {
          content: '';
          display: inline-block;
          vertical-align: middle;
          height: 3px;
          width: 20px;
          margin-right: 10px;
          margin-bottom: 4px;
          background-color: $light-green;
        }
      }
    }
  }

  &--public-sans {
    h3 {
      font-family: $public-sans-semibold;
      font-size: 13px;
      text-transform: uppercase;

      @media #{$tablet-up} {
        font-size: 22px;
      }
    }
  }

  &--alt-row {
    #{$parent}__row {
      &:first-child {
        @include flex-row(1338px);
      }
    }
  }
}
