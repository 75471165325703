.hp-hero {
  position: relative;
  z-index: 1;

  @media #{$large-up} {
    margin-top: -29px;
  }

  &__video {
    position: relative;
    overflow: hidden;
    height: calc(100vh - 120px);
    z-index: 1;

    @media #{$tablet-up} {
      height: 100vh;
    }

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: #000;
      opacity: 0.4;
      z-index: 1;
    }

    video {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 100%;
      height: 100%;
      transform: translate(-50%, -50%);
      object-fit: cover;
    }
  }

  &__play-button {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 95px;
    height: 95px;
    border: 1px solid rgba(255, 255, 255, 0.33);
    border-radius: 50%;
    display: block;
    z-index: 2;
    transition: all .2s ease;
    opacity: 0;

    .yt-ready & {
      opacity: 1;
    }

    &__triangle {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      display: block;
    }

    svg {
      overflow: visible;

    }

    &:hover,
    &:focus {
      background: $light-green;
      border-color: $light-green;
    }
  }

  &__text {
    text-align: center;
    position: absolute;
    //top: 89%;
    bottom: 125px;
    padding: 5px 35px;
    left: 50%;
    transform: translate(-50%, 0);
    z-index: 10;
    width: 100%;
    max-width: 600px;
    color: $white;
    font-family: $merriweather-bold;
    transition: all .25s ease;
    font-size: 36px;
    line-height: 1.3;

    @media #{$tablet-down} {
      font-size: 24px;
      line-height: 1.3;
      bottom: 90px;
    }
  }
}

.partnerships-investments-container {
  position: relative;
  background-size: 100%;
  background-position: center bottom;
  background-repeat: no-repeat;
  background-color: $white;
  z-index: 3;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 40%;
    background-image: linear-gradient(180deg, #eeeeee 0%, #ffffff 50%, rgba(255, 255, 255, 0) 100%);
    z-index: -1;
  }
}

.hp-partnerships,
.hp-results {
  &__row {
    position: relative;
    @include flex-row();
    background-color: $white;
    justify-content: center;
    text-align: center;
    padding: 70px 0 90px;
    margin-bottom: -100px;

    z-index: 5;

    @media #{$tablet-up} {
      top: -100px;
      margin-bottom: -100px;
    }
  }

  &__column {
    @include flex-col(24);
    @include flex-col('lg', 22);
    @include flex-col('xl', 18);

    a:not(.button) {
      text-decoration: underline;
    }
  }

  &__intro-title {
    font-family: $public-sans-medium;
    font-size: 15px;
    text-transform: uppercase;
    color: $dark-gray;
  }

  hr {
    background-color: $light-green;
    max-width: 30px;
    margin: 5px auto 0;
    height: 2px;
  }

  p {
    font-size: 20px;
    line-height: 45px;
    margin-bottom: 35px;

    @media #{$tablet-up} {
      font-size: 26px;
    }
  }
}

.hp-partnerships {
  &__row {
    top: 0;
  }
}

.hp-investments {
  &__row {
    @include flex-row(1305px);
    //max-width: 1305px;
    padding: 0 0 23vw;
  }

  &__columns {
    @include flex-col(24);
    @include flex-col('md', 12);

    &:first-child {
      @include flex-col('lg', 12);
    }

    &:last-child {
      @include flex-col('lg', 8);
      margin-left: auto;
    }
  }

  &__intro-title {
    font-family: $public-sans-medium;
    font-size: 15px;
    text-transform: uppercase;
    color: $dark-gray;
  }

  &__featured-landscape {
    display: flex;
    flex-wrap: wrap;
    margin-top: 30px;
    margin-bottom: 30px;
    align-items: center;

    img {
      max-width: 192px;
      margin: 0 40px 0 0;
    }

    a:not(.button) {
      text-decoration: none;
    }
  }

  &__landscape-type {
    font-family: $public-sans-semibold;
    font-size: 11px;
    letter-spacing: 1.44px;
    text-transform: uppercase;
    color: #323232;
  }

  &__landscape-title {
    font-family: $merriweather-bold;
    font-size: 22px;
    line-height: 31px;
    color: $black;

    @media #{$tablet-up} {
      font-size: 28px;
    }
  }

  &__stat {
    padding: 30px 10px 20px;
    background-image: linear-gradient(145deg, #338fb8 0%, #115c7b 100%);
    text-align: center;
    color: $white;
    max-width: 396px;
    margin: 0 auto;
    position: relative;
    z-index: 5;

    .button {
      background-color: #00435e;
      padding: 15px 10px;

      svg:first-child {
        margin-right: 10px;
      }

      @media #{$tablet-up} {
        svg:first-child {
          margin-right: 24px;
        }

        padding: 15px 20px;
      }

      @media #{$tablet-only} {
        padding: 15px 10px;
      }
    }
  }

  &__stat-number {
    font-size: 80px;
    line-height: 1.15;
    margin-bottom: 5px;

    @media #{$tablet-up} {
      font-size: 90px;
    }
  }

  &__stat-desc {
    font-size: 20px;
    line-height: 28px;
    margin-bottom: 25px;
  }

  hr {
    max-width: 30px;
    background-color: $light-green;
    height: 2px;
    margin: 0;
  }

  p {
    font-size: 20px;
    line-height: 45px;

    @media #{$tablet-up} {
      font-size: 26px;
    }
  }

  a:not(.button) {
    text-decoration: underline;

    &:hover,
    &:focus {
      color: $light-green;

      .hp-investments__landscape-title {
        color: $light-green;
      }
    }
  }

  .hp-investments__featured-landscape a {
    text-decoration: none;
  }

}

.hp-results {
  position: relative;
  background-color: $white;
  z-index: 6;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(180deg, #eeeeee 0%, #ffffff 50%, rgba(255, 255, 255, 0) 100%);
    z-index: -1;
  }
}

.hp-featured-story,
.hp-press,
.hp-cta {
  position: relative;
  z-index: 7;

  &__row {
    @include flex-row(1305px);
    align-items: center;
  }

  &__column {
    @include flex-col(24);
  }

  &__columns {
    @include flex-col(24);
    @include flex-col('lg', 12);

    &:first-child {
      @include flex-col('lg', 13);
    }

    &:last-child {
      @include flex-col('lg', 10);
      margin-left: auto;
    }
  }
}

.hp-featured-story {
  &__intro-title {
    font-family: $public-sans-medium;
    font-size: 15px;
    text-transform: uppercase;
    color: $dark-gray;
  }

  &__topic {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-top: 33px;

    img {
      max-width: 192px;
      margin: 0 40px 0 0;
    }
  }

  &__topic-subtitle {
    color: $black;
    font-family: $public-sans-semibold;
    font-size: 11px;
    letter-spacing: 1.44px;
    text-transform: uppercase;
  }

  h2,
  h2 a {
    color: #171717;
    font-family: $merriweather-bold;
    font-size: 28px;
    line-height: 1.3;

    @media #{$tablet-up} {
      font-size: 36px;
    }

    &:hover,
    &:focus {
      color: $light-green;
    }
  }

  h3,
  h3 a {
    color: $black;
    font-size: 28px;
    line-height: 31px;

    &:hover,
    &:focus {
      color: $light-green;
    }
  }

  hr:not(.hr--dashed) {
    height: 2px;
    width: 100%;
    background-color: $gray;
    margin: 30px 0;

    @media #{$tablet-up} {
      margin: 70px 0;
    }
  }
}

.hp-press {

  &__row {
    &:last-child:not(:first-child) {
      hr {
        height: 2px;
        width: 100%;
        background-color: $gray;
        margin: 50px 0 70px;
      }
    }
  }

  &__top-bar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 30px;

    @media #{$large-down} {
      margin-top: 50px;
    }

    a {
      font-family: $public-sans-semibold;
      font-size: 14px;
      letter-spacing: 1.17px;
      text-transform: uppercase;

      &:hover,
      &:focus {
        color: $light-green;
      }
    }
  }

  &__title {
    color: $dark-gray;
    font-family: $public-sans-medium;
    font-size: 15px;
    text-transform: uppercase;

    hr {
      max-width: 30px;
      height: 2px;
      background-color: $light-green;
      margin: 0;
    }
  }

  &__article {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 45px;

    @media #{$tablet-down} {
      flex-direction: column-reverse;
    }

    &:last-of-type {
      margin-bottom: 0;
    }


    img {
      margin-bottom: 10px;

      @media #{$tablet-up} {
        max-width: 192px;
        margin-left: 40px;
        margin-bottom: 0;
      }
    }

    a {
      display: block;
      color: $black;
      font-family: $merriweather-bold;
      font-size: 18px;
      line-height: 31px;
      margin-bottom: 5px;

      &:hover,
      &:focus {
        color: $light-green;
      }
    }

    &-date {
      color: $dark-gray;
      font-family: $public-sans-medium;
      font-size: 15px;
      text-transform: uppercase;
    }
  }

  .hp-press__slide {
    background-color: #191c22;
    color: #fff;

    img {
      width: 100%;
    }
  }

  &__img-container {
    position: relative;

    &::before {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-image: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #191c22 100%);
    }
  }

  &__slide-content {
    padding: 0 30px 60px;

    @include media($medium-up) {
      padding: 0 60px 60px;
    }

  }

  &__slide-date {
    opacity: 0.8;
    font-family: $public-sans-semibold;
    font-size: 15px;
    text-transform: uppercase;
    margin-bottom: 5px;
  }

  &__slide-title {
    font-size: 27px;
    font-weight: 700;
    line-height: 40px;
  }

  &__bottom-row {
    display: flex;
    align-items: center;
    margin-top: 25px;
    line-height: 1;


    .button {
      padding: 0;
      font-size: 13px;
      padding: 13px 25px;
      border-radius: 3px;
      margin-right: 28px;
    }

    a:not(.button) {
      font-size: 14px;
      letter-spacing: 1.17px;
      text-transform: uppercase;
      font-family: $public-sans-semibold;
      color: $light-green;

      &:hover,
      &:focus {
        color: $dark-green;
      }
    }
  }

  .glide {
    &__arrows {
      position: absolute;
      display: flex;
      justify-content: space-between;
      padding: 0 10px;
      top: 45%;
      transform: translate(0, -50%);
      width: 100%;

      @media #{$tablet-up} {
        padding: 0 20px;
      }
    }

    &__slides {
      margin: 0;
    }

    &__arrow {
      svg {
        width: 13px;
        height: 25px;
      }

      &--left {
        transform: rotate(-180deg);
      }
    }

    &__bullets {
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      bottom: 40px;
      width: 100%;
      height: auto;
      text-align: center;
    }

    &__bullet {
      width: 6px;
      height: 6px;
      border-radius: 50%;
      background-color: #fff;
      opacity: 0.7;
      margin: 0 10px;

      &--active {
        width: 10px;
        height: 10px;
        opacity: 1;
      }
    }
  }
}

.hp-cta {
  $parent: &;
  padding: 0 0 70px;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  color: $black;

  &__row {
    align-items: center;
  }

  &__columns {
    @include flex-col(24);


    &:first-child:not(:last-child) {
      @include flex-col('lg', 11);
      order: 2;

      @media only screen and #{$post-tablet} {
        order: 1;
      }

      h2 {
        margin-top: 20px;

        @media #{$tablet-down} {
          line-height: 1.4;
          ;
        }
      }
    }

    &:last-child:not(:first-child) {
      @include flex-col('lg', 13);
      order: 1;
      text-align: right;

      @media only screen and #{$post-tablet} {
        order: 2;

        img {
          width: auto;
        }
      }
    }
  }

  hr {
    margin: 0;
  }

  h2 {
    font-size: 36px;
    line-height: 66px;
    margin-bottom: 0;
  }

  img {
    width: 100%;
  }

  p {
    color: #4c4c4c;
    margin-bottom: 30px;
  }

  .button {
    padding: 20px 25px;
  }
}
