.cta {
  $parent: &;
  padding: 0 0 50px;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  color: $white;

  &__row {
    @include flex-row(1338px);
    align-items: center;
  }

  &__columns {
    @include flex-col(24);
    @include med-pad();

    &:first-child:not(:last-child) {
      @include flex-col('lg', 11);
      order: 2;

      @media only screen and #{$post-tablet} {
        order: 1;
      }

      h2 {
        margin-top: 20px;
      }
    }

    &:last-child:not(:first-child) {
      @include flex-col('lg', 13);
      order: 1;
      text-align: right;

      @media only screen and #{$post-tablet} {
        order: 2;

        img {
          width: auto;
        }
      }
    }
  }

  h2 {
    font-size: 26px;
    line-height: 46px;
    margin-bottom: 40px;

    @media #{tablet-up} {
      font-size: 36px;
      line-height: 66px;
    }
  }

  img {
    margin-top: -50px;
    width: 100%;
    box-shadow: 0 24px 34px rgba(0, 0, 0, 0.25);
  }

  &--simple {
    padding: 115px 0 135px;
    text-align: center;

    #{$parent}__columns {
      @include flex-col(24);
      @include med-pad();
    }

    h2 {
      margin-bottom: 32px;
    }
  }

  &--solid-bg {
    background-color: $blue;
  }
}
