.corporate-partner {
  background-color: $dark-blue;
  padding: 70px 0 72px;
  color: $white;

  &__row {
    @include flex-row(1140px);
    align-items: center;
  }

  &__columns {
    @include flex-col(24);
    @include flex-col('lg', 12);
    @include med-pad();
  }

  &__stat {
    font-family: $bebas-neue;
    font-size: 36px;
    text-transform: uppercase;
    margin-top: 45px;
    margin-bottom: 20px;
  }

  p:first-child::first-letter {
    font-family: $merriweather-bold;
    font-size: 64px;
    text-transform: uppercase;
    vertical-align: top;
    float: left;
    margin: 16px 16px 10px 0;
  }

  img {
    border: 1px solid #bfbfbf;
  }
}
