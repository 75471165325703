.tabbed-content {
  padding: 100px 0;
  background-color: #f3f3f3;
  overflow: hidden;

  &__container {
    @include flex-row(1075px);
    padding: 0px 25px;

    @media screen and (min-width: 768px) and (max-width: 1440px) {
      padding: 0 35px;
    }
  }
}

ul.tabs {
  width: 100%;
  margin: 0 0 35px;
  padding: 0;
  list-style: none;
  border-bottom: 1px solid $light-gray;



  li {
    position: relative;
    cursor: pointer;
    display: block;

    @media only screen and (min-width: 500px) {
      display: inline-block;
    }

    margin: 0;
    margin-right: -7px;

  }
}

.tab {
  &__button {
    font-family: $public-sans-semibold;
    font-size: 22px;
    letter-spacing: 0.9px;
    text-transform: uppercase;
    padding: 10px 28px;
    text-align: center;
    vertical-align: top;
    color: $black;

    &::after {
      content: '';
      position: absolute;
      bottom: -4px;
      left: 0;
      width: 100%;
      height: 6px;

    }


    &.current {
      background: $dark-blue;
      color: $white;
      z-index: 2;

      &::after {
        background-color: $light-green;
      }
    }

    &:hover,
    &:focus {
      &::after {
        background-color: $light-green;
      }
    }
  }
}

.tab-content {
  display: none;


  &.current {
    display: block;
  }

  .views-element-container {
    margin-bottom: 75px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  div.views-field-body {
    margin: 20px 0;
  }
}
