.site-footer {
  background-color: $dark-blue;
  border-top: 5px solid $light-green;
  padding: 30px 0;
  color: $white;
  font-family: $public-sans-medium;
  font-size: 15px;



  &__row {
    display: flex;
    flex-wrap: wrap;
    padding: 0 20px;
    justify-content: center;
    text-align: center;

    @include media($small-up) {
      text-align: left;
      justify-content: space-between;
      padding: 0 30px;
    }
  }

  &__columns {
    @include media($small-up) {
      flex: 0 1 43%;
      width: 100%;
    }

    @include media($large-up) {
      flex-basis: auto;
      width: auto;
    }

    &:nth-child(2) {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;

      @include media($small-up) {
        flex: 1;
        align-content: baseline;
        margin-left: 40px;
      }

      @include media($tablet-up) {
        flex-wrap: wrap;
      }


    }

    &--util {
      @include media($small-up) {
        text-align: center;
        flex: 0 1 100%;
      }

      @include media($tablet-up) {
        display: flex;
        flex-flow: column wrap;
        height: 150px;
      }

      @include media($large-up) {
        margin-top: 40px;
      }

      @media only screen and (min-width: 1440px) {
        display: block;
        height: auto;
        margin-top: 8px;
        max-width: 350px;
      }
    }
  }

  &__address {
    margin-bottom: 46px;
  }

  &__menu {
    margin: 0 2vw;
    width: 100%;

    @include media($small-up) {
      flex: 0 1 auto;
    }


    @include media($tablet-up) {
      flex-basis: calc(50% - 4vw);
      width: 100%;
    }

    @include media($large-up) {
      flex-basis: calc(25% - 4vw);
      margin: 15px 1vw;
    }



    h3 {
      margin: 0 auto 46px;
      width: 100%;
      position: relative;

      @include media($large-up) {
        margin-bottom: 26px;
      }

      //   @include media($post-tablet) {
      //     max-width: 133px;
      //     margin: 0 0 0.5rem;
      //   }

      &:after {
        content: "";
        position: absolute;
        left: 0;
        bottom: -10px;
        width: 20px;
        height: 3px;
        background-color: #a0b426;

        @include media($small-down) {
          left: 50%;
          transform: translateX(-50%);
        }
      }
    }

    nav {
      display: none;

      @include media($large-up) {
        display: block;
      }
    }

    li {
      margin: 6px 0;
    }
  }

  &__utility-menu {
    margin-bottom: 40px;

    @include media($tablet-up) {
      text-align: left;
      margin-bottom: 20px;
    }

    @media only screen and (min-width: 1440px) {
      margin-bottom: 45px;
    }

    li {
      margin: 0 5px;

      //   @include media($post-tablet) {
      //     margin: 0 2.8%;
      //   }

      &:first-child {
        margin-left: 0;
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }

  &__social {
    margin-bottom: 45px;

    @include media($tablet-up) {
      text-align: left;
      margin-bottom: 0;
    }

    @media only screen and (min-width: 1440px) {
      margin-bottom: 45px;
    }

    li {
      margin: 0 4%;

      &:first-child {
        margin-left: 0;
      }

      &:last-child {
        margin-right: 0;
      }

      a {

        &:hover,
        &:focus {
          svg path {
            fill: $white;
          }
        }
      }
    }
  }

  &__ad {
    @include media($tablet-up) {
      flex: 1 0 calc(50% - 40px);
      text-align: right;
      margin-left: 25px;
    }

    @media only screen and (min-width: 1440px) {
      text-align: left;
      margin-left: 0;
    }

    img {
      @include media($tablet-up) {
        max-width: 350px;
      }

      //   @media only screen and (min-width: 1440px) {
      //     max-width: none;
      //   }
    }
  }

  .button {
    padding: 12px 20px;
    border-radius: 25px;
    font-size: 13px;
    line-height: 18px;
    margin-bottom: 52px;
  }

  h3 {
    font-size: 16px;

    a {
      font-family: $public-sans-semibold;
      font-size: 16px;
      text-transform: uppercase;
    }
  }


  a {
    color: $white;
    font-family: $public-sans-medium;
    font-size: 15px;

    &:not(.button) {

      &:hover,
      &:focus {
        color: $light-green;
      }
    }
  }

  p {
    line-height: 29px;
  }
}
