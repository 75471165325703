.user-login-form {
  margin: 100px;

  label {
    display: block;
    font-weight: bold;
  }

  .description {
    margin-bottom: 20px;
  }
}
