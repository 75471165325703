@import "node_modules/slick-carousel/slick/slick.scss";
@import "node_modules/slick-carousel/slick/slick-theme.scss";

.sectionnav {
  $parent: &;
  background-color: $blue;
  color: $white;
  position: relative;
  z-index: 2;

  &__container {
    @include flex-row(1580px);
    margin: auto;
    justify-content: center;
    padding-left: 0;

    @media #{$tablet-up} {
      padding-left: 30px;
    }
  }

  &__nav {
    @include flex-col(24);
    padding: 16px 0 0;
    // overflow: hidden;
    display: flex;
    align-items: center;

    a {
      color: #ffffff;
      font-family: $public-sans-semibold;
      font-size: 16px;
      font-weight: 600;
      line-height: 21px;
      transition: all .25s ease;

      &:hover {
        color: rgba(0, 0, 0, 0.6);
      }
    }

    @media #{$tablet-down} {
      flex-direction: column;
    }
  }

  &__nav-item {
    position: relative;
    display: inline-block;
    font-family: $public-sans-semibold;
    font-size: 16px;
    line-height: 21px;

    padding-right: 20px;
    z-index: 3;
    // background-color: $blue;
    margin-bottom: 10px;
    padding-left: 52px;
    padding-bottom: 16px;

    @media #{$tablet-up} {
      font-family: $public-sans-light;
      max-width: 96px;
      margin-bottom: 0;
      padding-left: 0;
    }

    &::after {
      content: '';
      position: absolute;
      top: 50%;
      right: 0;
      height: 24px;
      width: 1px;
      background-color: $white;
      opacity: 0.24;
      transform: translateY(-50%);
      display: none;
    }
  }

  &--white-bg {
    background-color: $white;

    a {
      color: #000;
    }

    #{$parent}__nav {
      border-top: 1px solid rgba(0, 0, 0, 0.24);
      border-bottom: 1px solid rgba(0, 0, 0, 0.24);

      li:not(:last-child)::after {
        background-color: #000;
      }
    }

    .sectionnav__overflow::after {
      background: #FFF;
    }
  }

  &--media-center {
    margin-top: 35px;
    padding: 0 20px;

    #{$parent}__container {
      position: relative;
      max-width: 1070px;
      margin: 0 auto;
      padding: 0;

      &::before {
        content: '';
        position: absolute;
        top: 0;
        width: 100%;
        height: 90%;
        right: 100%;
        background-color: $white;
        z-index: 2;
      }

      &::after {
        content: '';
        position: absolute;
        top: 0;
        width: 100%;
        height: 90%;
        left: 100%;
        background-color: $white;
        z-index: 2;
      }

    }

    #{$parent}__nav {
      padding: 0;
      overflow: visible;

      li {
        padding: 10px 0;
        margin: 10px 0 5px;
      }

      li:not(:last-child)::after {
        right: -20px;
      }
    }

    .glide {
      padding-left: 0;
      width: 100%;

      &__arrow {
        background-color: $white;

        svg path {
          stroke: $black;
        }
      }
    }
  }

  &__ul {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    align-items: center;
    width: calc(100% - 72px);

    position: relative;

    &::after {
      content: '';
      display: block;
      background: $blue;
      height: 100%;
      width: 300%;
      right: -300%;
      position: absolute;
      z-index: 2;
      top: 0;
      bottom: 0;


    }
  }

  .slick-track {
    display: flex;
    align-items: stretch;
  }

  .slick-list {
    overflow: visible;
  }

  .slick-slide {
    max-width: 260px;
    position: relative;
    height: auto !important;
    display: flex;
    align-items: center;

    &::after {
      content: '';
      position: absolute;
      top: 50%;
      right: 0px;
      height: 24px;
      width: 1px;
      background-color: #fff;
      opacity: 0.24;
      transform: translateY(-50%);
    }
  }

  &__li {
    max-width: 260px;
    position: relative;
    line-height: 1.3125;
    font-size: 16px;
    border: 1px solid transparent;

    box-sizing: content-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 10px 0;


    // width: auto !important;
    a {
      display: block;
      padding: 0 15px;
      position: relative;


    }

    &:hover {
      .glide__slide-dropdown {
        display: block;
      }
    }

  }

  &__overflow {

    // overflow: hidden;
    // overflow-x: auto;
    position: relative;
    z-index: 2;
    margin-bottom: 16px;
    display: flex;
    align-items: center;

    @media #{$tablet-up} {
      width: calc(100% - 96px);
    }

    &::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 0;
      height: 24px;
      width: 1px;
      background-color: $white;
      opacity: 0.24;
      transform: translateY(-50%);
      display: block;
      z-index: 4;
    }

    &::after {
      content: '';
      display: block;
      background: $blue;
      height: 100%;
      width: 300%;
      left: -300%;
      position: absolute;
      z-index: 2;
      top: 0;
      bottom: 0;

    }
  }

  .glide {
    position: relative;
    padding-left: 20px;
    display: inline-block;
    width: calc(100% - 105px);



    &__slide {
      &:hover {
        >a {
          color: rgba(0, 0, 0, 0.6);
        }

        .glide__slide-dropdown {
          display: block;
        }
      }

      &:focus {
        >a {
          color: rgba(0, 0, 0, 0.6);
        }
      }
    }

    &__arrow {
      position: absolute;
      top: 0;
      width: 50px;
      height: 90%;
      background-color: $blue;

      &--left {
        display: none;
        left: -8px;
        transform: rotate(180deg);
      }

      &--right {
        right: -9px;
      }
    }

    &__slide-dropdown {
      position: absolute;
      display: none;
      top: calc(100% + 3px);
      //top: 100%;
      left: 50%;
      transform: translate(-50%, 0);
      width: 300px;
      background-color: $dark-blue;
      z-index: 3;
      color: $white;
      border-radius: 6px;
      box-shadow: 0 11px 21px rgba(0, 0, 0, 0.15);

      &::before {
        content: '';
        position: absolute;
        top: -8px;
        left: 50%;
        transform: translate(-50%, 0);
        @include triangle('up', 14px, 8px, $dark-blue);
      }

      ul {
        list-style: none;
        margin: 0;
        // padding: 25px 0 75px;
        padding: 25px 0 5px;

        li {
          margin: 0;
          padding: 0;
          border-bottom: 1px solid #424a58;

          &:last-child {
            border-bottom: none;
          }

          &::after {
            display: none;
          }
        }
      }

      a {
        position: relative;
        display: block;
        padding: 18px 50px;
        color: $white;

        &:hover,
        &:focus {
          color: #a7adb6;

          &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 5px;
            height: 100%;
            background-color: $light-green;
          }
        }
      }
    }
  }

  .slick-arrow {
    display: block;

    width: 36px;
    height: 46px;
    transition: all .25s ease;

    background: $blue;
    position: relative;
    transition: all .25s ease;
    z-index: 3;

    &:hover {
      background: $green;
    }

    svg {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      fill: #FFF;
      width: 14px;
      height: 9px;
    }

    &--prev {
      svg {
        transform: translate(-50%, -50%) rotate(180deg);
      }
    }

    &--next {
      &::after {
        content: '';
        display: block;
        position: relative;
        top: 0;
        left: -30px;
        width: 30px;
        height: 100%;
        background: linear-gradient(to left, rgba(0, 74, 126, 1) 0%, rgba(0, 74, 126, 0.98) 2%, rgba(0, 74, 126, 0.29) 71%, rgba(125, 185, 232, 0) 100%);

      }
    }

    &.slick-disabled {
      opacity: 0.1;
      cursor: default;

      &:hover {
        background: #004A7E;
      }
    }

    &.slick-hidden {
      display: none;
    }
  }
}

.sectionnav--media-center {

  .sectionnav__overflow {
    width: 100%;
    margin-bottom: 0;
  }


}

.sectionnav--white-bg {
  .slick-arrow {

    background: white;
    position: relative;
    transition: all .25s ease;
    z-index: 3;

    &:hover {
      background: $green;

      svg {
        [stroke] {
          stroke: #FFF;
        }
      }
    }

    svg {
      position: absolute;

      [stroke] {
        stroke: $black;
      }
    }

    &--next {
      &::after {
        background: linear-gradient(to left, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0.98) 2%, rgba(255, 255, 255, 0.29) 71%, rgba(125, 185, 232, 0) 100%);

      }
    }

    &.slick-disabled {
      &:hover {
        background: #FFF;
      }
    }
  }

  .sectionnav__ul::after {
    background: #FFF;
  }
}
