.hero {
  $parent: &;
  font-size: 16px;
  background-image: linear-gradient(to bottom, #004a7e 0%, #1e242f 65%, #1e242f 100%);
  color: $white;

  .highlight {
    color: $light-green;
  }

  &__container {
    @include flex-row(1600px);
    justify-content: center;
  }

  &__image-container {
    position: relative;
    margin-top: 0px;
    overflow: hidden;
    width: 100%;
    max-width: 1446px;

    @media #{$tablet-down} {
      height: 433px;
    }

    @include media($post-tablet) {
      margin-top: -30px
    }


    &::after {
      content: '';
      display: block;
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 329px;
      background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, #1E242F 100%);
    }

    &[style*="background-image"] {
      background-size: contain;
      background-attachment: fixed;
      // background-position: 0 50%;
      background-position: 100% 0;

      background-repeat: no-repeat;

      img {
        opacity: 0;
      }
    }

    img {
      max-width: none;
      position: relative;
      left: 50%;
      transform: translate(-50%, 0);
      transform-origin: top;

      height: 433px;
      width: auto;

      @media #{$tablet-up} {
        width: 100%;
        height: auto;

      }
    }
  }

  &__content-container {
    @include flex-col(24);
    max-width: 1326px;
    margin-top: -2.3rem;
    margin-bottom: 50px;
    z-index: 1;
    position: relative;
    @include med-pad();

    h1 {
      color: $white;
      font-size: em(34);

      @include media($post-tablet) {
        font-size: em(55);
      }
    }
  }

  &__all {
    margin-bottom: 10px;

    a {
      color: inherit;
      font-family: $public-sans-semibold;
      font-size: em(15);
      letter-spacing: 1.5px;
      text-transform: uppercase;

      &:hover,
      &:focus {
        color: $light-green;
      }

      svg {
        position: relative;
        top: -2px;
        vertical-align: middle;
        margin-right: 15px;
      }
    }
  }

  &__image-caption {
    color: #6a6a6a;
    font-family: $public-sans-medium;
    font-size: 13px;
    font-weight: 400;
    line-height: 27px;

    @include media($tablet-up) {
      text-align: right;
    }
  }

  &__content {
    color: $white;
    font-size: 27px;
    line-height: 43px;
  }

  hr {
    max-width: 50px;
    margin: 5px 0 20px;
    background-color: $light-green;
  }

  .social-media-icons {
    ul {
      display: flex;
      flex-wrap: wrap;
    }

    li {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      border: 1px solid rgba(0, 0, 0, 0.1);
      border-radius: 50%;
      width: 56px;
      height: 56px;
      text-align: center;
      margin: 0 14px;

      &:first-child {
        margin-left: 0;
      }

      &:last-child {
        margin-right: 0;
      }

      a {
        display: inline-flex;
        line-height: 1;

        &:hover,
        &:focus {
          svg {
            path {
              fill: $light-green;
            }
          }
        }
      }
    }
  }

  &--simple {
    background-image: none;
    color: $black;

    @media screen and (max-width: 1025px) {
      margin-top: 30px;
    }

    #{$parent}__container {
      @include flex-row(1100px);
    }

    #{$parent}__content-container {
      margin-top: -5px;
      margin-bottom: 0;
      max-width: none;
      padding: 0 25px;
      @include med-pad();

      h1 {
        color: $black;

        &::after {
          content: '';
          display: block;
          width: 50px;
          height: 3px;
          background-color: $light-green;
          margin-top: 2px;
        }
      }
    }

    +.views-element-container {
      .sectionnav--white-bg {
        margin-top: 40px;
      }
    }
  }

  &--margin-bottom {
    margin-bottom: 50px;
  }

  &--alt-width {
    #{$parent}__container {
      @include flex-row(1340px);
    }
  }

  &--social {
    #{$parent}__content-container {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-between;
    }
  }
}
