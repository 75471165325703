.announcements {
  padding: 35px 0;

  @include media($tablet-up) {
    padding: 55px 0;
  }

  &__row {
    @include flex-row(1355px);
  }

  &__column {
    display: flex;
    @include flex-col(24);
    @include med-pad();

    @include media($small-down) {
      flex-wrap: wrap;
    }

    .views-exposed-form {
      @include flex-row(1100px);
      padding: 0 25px;
      @include med-pad();
      margin-bottom: 50px;
    }
  }

  &__columns {
    @include flex-col(24);
    @include flex-col('md', 12);
    @include flex-col('lg', 8);
    @include med-pad();
    margin-bottom: 20px;
  }

  &__date {
    color: $dark-gray;
    font-family: $public-sans-medium;
    font-size: 15px;
    text-transform: uppercase;
  }

  img {
    width: 100%;
    margin-bottom: 10px;
  }

  h4 {
    margin-right: 40px;
    font-size: 26px;

    @include media($small-down) {
      hr {
        margin-bottom: 0;
      }
    }

    ~a {
      margin-top: 4px;
      font-family: $public-sans-semibold;
      font-size: 14px;
      letter-spacing: 1.17px;
      text-transform: uppercase;

      @include media($small-down) {
        display: block;
        margin-bottom: 20px;
      }

      &:hover,
      &:focus {
        color: $light-green;

        svg {
          path {
            stroke: $light-green;
          }
        }
      }

      svg {
        position: relative;
        top: 2px;
        width: 6px;
        margin-left: 10px;

        path {
          stroke: $dark-green;
        }
      }
    }
  }

  h5,
  h5 a {
    font-size: 18px;
    line-height: 31px;
    color: $black;
    margin-bottom: 0;

    &:hover,
    &:focus {
      color: $light-green;
    }
  }
}
