.overview {
  position: relative;

  @include media($xlarge-up) {
    &::before {
      content: 'Overview';
      text-transform: uppercase;
      position: absolute;
      top: 40%;
      // left: -1.5%;
      transform: rotate(-90deg) translate(0, -50%);
      opacity: 0.2;
      text-transform: uppercase;
      font-family: $bebas-neue;
      font-size: 48px;
      margin-left: -20px;
    }
  }

  &__row {
    @include flex-row(1100px);
  }

  &__column {
    @include flex-col(24);
    @include med-pad();
  }

  // p:first-child::first-letter
  *:first-child::first-letter {
    font-family: $merriweather-bold;
    font-size: 64px;
    text-transform: uppercase;
    vertical-align: top;
    float: left;
    margin: 16px 25px 10px 0;
  }

  .down-arrow-link {
    display: inline-block;
    margin-top: 25px;
    font-family: $public-sans-semibold;
    font-size: 15px;
    text-transform: uppercase;
  }
}

//singular partner
.overview-partner {
  position: relative;

  @include media($medium-up) {
    margin-bottom: 60px;
  }

  @include media($xlarge-up) {
    &::before {
      content: 'Overview';
      text-transform: uppercase;
      position: absolute;
      top: 40%;
      // left: -1.5%;
      transform: rotate(-90deg) translate(0, -50%);
      opacity: 0.2;
      text-transform: uppercase;
      font-family: $bebas-neue;
      font-size: 48px;
      margin-left: -20px;
    }
  }



  &__container {
    @include media($medium-up) {
      display: flex;
      max-width: 1100px;
      margin: 0 auto;
    }
  }

  .overview {
    @include media($medium-up) {
      max-width: 72%;
      padding-right: 40px;
    }

    @include media($xlarge-up) {
      &:before {
        display: none;
      }
    }
  }

  .partners-grid {
    @include media($medium-up) {
      margin-top: 20px;
      border-left: 1px solid $light-gray;
    }

    @include media($xlarge-up) {
      margin-left: 5%;
      padding-left: 60px;
    }

    &__columns {
      width: 100%;

      @include media($xlarge-up) {
        padding: 0;
      }
    }

    h2 {
      @include media($medium-up) {
        &:before {
          display: none;

        }

        &:after {
          left: 50%;
          bottom: -10px;
          top: auto;
          right: auto;
          width: 50px;
          height: 2px;
          background-color: $green;
          transform: translateX(-50%);
        }
      }
    }


  }
}
