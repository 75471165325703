.blockquote-module {
  margin: 80px 0 40px;

  &__row {
    @include flex-row(1100px);
  }

  &__column {
    @include flex-col(24);
    padding-left: 40px;
    border-left: 3px solid $orange;
  }

  &__author {
    @include media($small-up) {
      display: flex;
      align-items: center;
    }

  }

  &__author-name {
    font-family: $public-sans-semibold;
    font-size: 19px;
    line-height: 26px;
    color: $black;
    text-transform: uppercase;

    @include media($small-down) {
      margin-top: 10px;
    }
  }

  &__author-company {
    font-family: $public-sans-semibold;
    font-size: 16px;
    line-height: 26px;
    color: $dark-gray;
    margin-top: 5px;


  }

  p {
    margin-top: 0;
    font-family: $public-sans-semibold;
    font-size: 22px;
  }

  img {
    margin: 20px 0 0;
    max-width: 220px;
    border: 1px solid $gray;
    background-color: $white;
    margin-right: 40px;
  }
}
