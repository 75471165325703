.video-module {
  margin: 60px 0 70px;

  &__row {
    @include flex-row(1355px);
  }

  &__column {
    position: relative;
    @include flex-col(24);
    @include med-pad();
  }

  &__img-container {
    position: relative;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: $black;
      opacity: 0.4;
    }
  }

  &__content {
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    transform: translate(0, -50%);
    text-align: center;

    .button {
      padding: 14px 25px;

      svg {
        margin-right: 20px;
        width: 40px;
        height: 40px;
      }
    }
  }
}
