@mixin flex-row($width: 1446px) {
  display: flex;
  box-sizing: border-box;
  flex: 0 1 auto;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  margin: 0 auto;
  max-width: $width;
}

@mixin col-condition($col-number) {
  @if type-of($col-number)==number {
    @if $col-number>$grid-columns or $col-number==0 {
      @warn "Column number must be greater than 0 and less than or equal to total number of columns in the grid (#{$grid-columns})";
    }

    @else {
      box-sizing: border-box;
      flex-basis: 100% / $grid-columns * $col-number;
      max-width: 100% / $grid-columns * $col-number;
      padding: 0 25px;
    }
  }

  @else if $col-number=="auto" {
    box-sizing: border-box;
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%;
    padding: 0 25px;
  }

  @else {
    @warn "Column number argument must either be the string 'auto' or a number greater than 0 and less than or equal to total number of columns in the grid (#{$grid-columns})";
  }
}

@mixin media($media) {
  @media only screen and #{$media} {
    @content;
  }
}

@mixin flex-col($breakpoint: "xs", $col-number: "auto") {
  @if type-of($breakpoint)==number {
    $col-number: $breakpoint;
    @include col-condition($col-number);
  }

  @else if $breakpoint=="xs" {
    @include col-condition($col-number);
  }

  @else if $breakpoint=="sm" {
    @include media($small-up) {
      @include col-condition($col-number);
    }
  }

  @else if $breakpoint=="md" {
    @include media($tablet-up) {
      @include col-condition($col-number);
    }
  }

  @else if $breakpoint=="lg" {
    @include media($post-tablet) {
      @include col-condition($col-number);
    }
  }

  @else if $breakpoint=="xl" {
    @include media($xlarge-up) {
      @include col-condition($col-number);
    }
  }
}

@mixin med-pad() {
  @media screen and (min-width: 768px) and (max-width: 1440px) {
    padding: 0 35px;
  }
}
