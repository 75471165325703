//declare color variables
$black: #171717;
$white: #fff;
$light-green: #A0B426;
$green: #8B9C21;
$dark-green: #5D6B03;
$orange: #D17A22;
$light-gray: #C9C9C9;
$gray: #BFBFBF;
$dark-gray: #6A6A6A;
$light-blue: #8D9DBA;
$blue: #004A7E;
$blue-alt: #21447e;
$blue-alt-alt: #3369c3;
$blue-gray: #A7ADB6;
$dark-blue: #1E242F;

$link-text-color: $dark-green;
$link-text-color-hover: $light-green;
$line-color: #9f9f9f;

//declare file paths. placed here since it's only one extra var
$img: "../img/";