.annual-report {
  margin: 35px 0 0;

  &__row {
    @include flex-row(1355px);
  }

  &__column {
    display: flex;
    @include flex-col(24);
    @include med-pad();
    margin-bottom: 25px;
  }

  &__columns {
    @include flex-col(12);
    @include flex-col('md', 6);
    //@include flex-col('lg', 6);
    @include med-pad();
    margin-bottom: 20px;

    a {

      &:hover,
      &:focus {
        color: $light-green;

        .annual-report__title {
          color: $light-green;
        }
      }
    }
  }

  &__title {
    color: $dark-gray;
    font-family: $public-sans-medium;
    font-size: 15px;
    line-height: 1.3;
    text-transform: uppercase;
    margin-bottom: 5px;
  }

  &--padding-bottom {
    padding-bottom: 80px;
  }

  h4 {
    display: inline-block;
    margin-right: 40px;

    ~a {
      margin-top: 4px;
      font-family: $public-sans-semibold;
      font-size: 14px;
      letter-spacing: 1.17px;
      text-transform: uppercase;

      &:hover,
      &:focus {
        svg {
          path {
            stroke: $light-green;
          }
        }
      }

      svg {
        position: relative;
        top: 2px;
        width: 6px;
        margin-left: 10px;

        path {
          stroke: $dark-green;
        }
      }
    }
  }
}
