html {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

html {
  background-color: $white;
  color: $black;
  font-family: $base-font-family;
  font-size: $base-font-size;
  line-height: $base-line-height;
  height: 100%;

  @media print {
    background: none;
  }
}

body {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  background: $dark-blue;
  color: $black;
  image-rendering: -webkit-optimize-contrast;
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
  font-size: $base-font-size;
  overflow-x: hidden;
  height: 100%;

  &.fixed {
    overflow: hidden;
  }

  @media print {
    padding-top: 0;
    margin-left: 0.5in;
    margin-right: 0.5in;
  }

  &.admin-bar {
    margin-top: -16px;
  }
}

@media print {
  @page {
    margin: 2cm;
  }
}

// Horizontal rules
hr {
  background-color: $line-color;
  border: none;
  height: 2px;
  margin: $base-margin auto;
}

.hr {
  &--dashed {
    background-color: transparent;
    border-top: 1px dashed $line-color;
  }

  &--divide {
    margin: 70px 0 50px;
  }



  &--green {
    background-color: $light-green;
    margin-top: 8px;
    margin-bottom: 8px;
    max-width: 50px;
  }

  &--short {
    max-width: 30px;
    margin-left: 0;
  }

  &--larger-margin {
    margin-top: 64px;
    margin-bottom: 64px;
  }

  &--max-width {
    margin-left: 20px;
    margin-right: 20px;

    @media #{$tablet-up} {
      margin-left: 40px;
      margin-right: 40px;
    }
  }

  &--thin {
    height: 1px;
  }

  &--no-bottom-margin {
    margin-bottom: 0;
  }

  &--no-top-margin {
    margin-top: 0;
  }
}

.highlight-word {
  color: $light-green;
}

// Links
a {
  font-family: $merriweather;
  color: $link-text-color;
  cursor: pointer;
  text-decoration: none;
  transition: color 200ms ease-out;

  &:hover,
  &:focus {
    color: $link-text-color-hover;
  }

  &.down-arrow-link {
    color: $black;

    svg {
      margin-right: 16px;

      path {
        fill: $light-green;
      }
    }

    &:hover,
    &:focus {
      span {
        border-bottom: 2px solid $light-green;
      }

    }
  }
}

// margins for block elements
dd,
dl,
dt,
fieldset,
ol,
p,
ul,
table {
  margin: $base-margin 0;
}

cite,
address {
  font-style: normal;
}

figure {
  margin: 0;

  figcaption {
    color: $dark-gray;
    font-family: $public-sans-regular;
    font-size: 16px;
    line-height: 27px;
    margin-top: 10px;
  }
}

.highlight {
  color: $light-green;
}

.callout {
  display: block;
  font-family: $bebas-neue;
  font-size: 36px;
  color: #000;
  text-transform: uppercase;
  font-weight: 400;
  margin: 50px 0;
}

ul {
  &.inline {
    list-style: none;
    padding: 0;

    li {
      display: inline-block;
      vertical-align: middle;
    }
  }

  &.editor-rich-text__tinymce {
    font-size: 1.8em;

    @media #{$tablet-up} {
      font-size: 2.2em;
    }

    li>* {
      font-size: 1em;
    }
  }

  &.drupal-edit-link {
    padding: 0 15px;
    margin: 20px 0;
    max-width: 1440px;
    list-style: none;

    @include media($post-tablet) {
      margin: 25px auto 60px;
    }

    li {
      display: inline-block;
      margin: 0 15px 0 0;

      .button {
        padding: 10px 20px;
        font-size: 13px;

        @include media($tablet-down) {
          padding: 3px 10px;
        }
      }


    }
  }
}

@include media($medium-up) {
  .embedded-entity {
    &.align-left {
      float: left;
      margin: 1rem 1rem 1rem 0;

      @include media($xlarge-up) {
        margin: 1.5rem 1.5rem 1.5rem -18%;
      }
    }

    &.align-center {
      display: block;
      margin: 16px auto;
      text-align: center;
    }

    &.align-right {
      float: right;
      margin: 1rem 0 1rem 1rem;

      @include media($xlarge-up) {
        margin: 1.5rem -18% 1.5rem 1.5rem;
      }
    }
  }
}

h1,
.h1 {
  font-size: 34px;
  line-height: normal;
  font-family: $merriweather-bold;
  margin: 0 0 0.5rem;

  @include media($large-up) {
    font-size: 55px;
  }
}

h2,
.h2 {
  font-size: 36px;
  line-height: 0.98;
  font-family: $merriweather-bold;
  margin: 0 0 0.5rem;

  @include media($large-up) {
    font-size: 48px;
  }
}

h3,
.h3 {
  font-size: 36px;
  line-height: normal;
  font-family: $merriweather-bold;
  margin: 0 0 0.5rem;
}

h4,
.h4 {
  font-size: 28px;
  line-height: 38px;
  font-family: $merriweather-bold;
  margin: 0 0 0.5rem;
}

h5,
.h5 {
  font-size: 20px;
  line-height: normal;
  font-family: $merriweather-bold;
  margin: 0 0 0.5rem;
}

h6,
.h6 {
  font-size: 15px;
  line-height: normal;
  font-family: $public-sans-semibold;
  margin: 0 0 0.5rem;
}

.figcaption {
  //max-width: $width-content;
  margin: 10px auto 0;
  font-size: 1.6em;
  text-align: left;
  color: $black;

  p {
    font-size: 1em;
    margin: 0;
  }

}

// Images
img {
  height: auto;
  margin: 0 auto;
  max-width: 100%;
  vertical-align: bottom;
}



// Lists
nav {

  ol,
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  li {
    margin: 0;
  }

  &.inline {
    li {
      display: inline-block;
      vertical-align: middle;
    }
  }
}

dt {
  font-weight: bold;
}

dd {
  margin-left: 0;
}

svg {
  max-width: 100%;
}

// Forms
fieldset {
  border: 0;
  border-top: 1px solid $line-color;
  padding: 0;
}

legend {
  margin-left: 0.5em;
  padding: 0 0.5em;
}

label {
  cursor: pointer;
  display: inline-block;
}

// #{$all-text-inputs} {
// 	border: 1px solid $line-color;
// 	padding: 0.625em 1em;
// }

a,
input[type="button"],
input[type="submit"],
input[type="reset"],
button {
  cursor: pointer;
}

[hidden] {
  display: none !important;
}

.svgs,
.svg-hide {
  display: none;
}

.content-wrapper {
  width: 100%;
  position: relative;
  z-index: 4; //increased for breadcrumb visibility
  padding-top: 63px;
  padding-bottom: 50px;

  &:focus {
    outline: none;
  }
}

a,
button,
[tabindex="0"],
[tabindex="-1"] {

  &:focus {
    outline: none;

    .keyboard--focus & {
      outline: 1px dotted $black;
    }
  }
}

button {
  appearance: none;
  border: 0;
  padding: 0;
  background: none;
  border: 0;
}

.site-container {
  background-color: $white;
  overflow: hidden;
}

div[data-drupal-messages] {
  max-width: 1440px;
  margin: 0 auto;
  padding: 0 15px;
}
