.button {
  display: inline-block;
  vertical-align: middle;
  padding: 25px 30px;
  border: 2px solid transparent;
  border-radius: 5px;
  transition: background-color 250ms ease-out, border-color 250ms ease-out;
  font-family: $public-sans-semibold;
  font-size: 16px;
  appearance: none;
  line-height: 1;
  letter-spacing: 1.33px;
  text-transform: uppercase;
  text-decoration: none;
  text-align: center;
  cursor: pointer;
  background-color: $green;
  color: $white;

  svg {
    &:first-child {
      margin-right: 24px;
      vertical-align: middle;
    }

    &:last-child {
      margin-left: 24px;
      vertical-align: middle;
    }

    path {
      &[fill] {
        fill: $white;
      }

      &[fill="none"][stroke] {
        fill: none;
        stroke: $white;
      }
    }
  }

  &:hover,
  &:focus {
    background-color: $light-green;
    border-color: $light-green;
    color: $white;

    svg {
      path {
        &[fill] {
          fill: $white
        }

        &[fill="none"][stroke] {
          fill: none;
          stroke: $white
        }
      }
    }
  }

  &--secondary {
    background-color: $blue-alt;

    &:hover,
    &:focus {
      background-color: $blue-alt-alt;
      border-color: $blue-alt-alt;
    }
  }

  &--outline {
    background-color: transparent;

    &--bg-white {
      background-color: $white;
    }

    &--green {
      border-color: $green;
      color: $green;

      svg path {
        &[fill] {
          fill: $green;
        }

        &[fill="none"][stroke] {
          fill: none;
          stroke: $green;
        }
      }

      &:hover,
      &:focus {
        border-color: $light-green;
        color: $white;
        background-color: $light-green;

        svg path {
          &[fill] {
            fill: $white;
          }

          &[fill="none"][stroke] {
            fill: none;
            stroke: $white;
          }
        }
      }
    }

    &--blue {
      border-color: $blue-alt;
      color: $blue-alt;

      svg path {
        &[fill] {
          fill: $blue-alt;
        }

        &[fill="none"][stroke] {
          fill: none;
          stroke: $blue-alt;
        }
      }

      &:hover,
      &:focus {
        border-color: $blue-alt-alt;
        color: $white;
        background-color: $blue-alt-alt;

        svg path {
          &[fill] {
            fill: $white;
          }

          &[fill="none"][stroke] {
            fill: none;
            stroke: $white;
          }
        }
      }
    }
  }
}
