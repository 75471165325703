// Declare any fonts variables
$merriweather: 'merriweather', serif;
$merriweather-bold: 'merriweather-bold', serif;
$public-sans-light: 'public_sans-light', sans-serif;
$public-sans-medium: 'public_sans-medium', sans-serif;
$public-sans-regular: 'public_sans-regular', sans-serif;
$public-sans-semibold: 'public_sans-semibold', sans-serif;
$bebas-neue: 'bebas-neue', sans-serif;

$font-stack-system: $merriweather;
$base-font-family: $font-stack-system;
$base-font-size: 20px;
$base-line-height: 36px;
$base-margin: 20px;