.load-more__button {
    position: absolute;
    left: 50%;
    bottom: -25px;
    transform: translate(-50%,0);
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 1.33px;
    text-transform: uppercase;
    color: #5d6b03;
    border-radius: 35px;
    background-color: #fff;
    padding: 15px 30px;
    border: 1px solid #b9b9b9;

    &::before {
        content: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNSIgaGVpZ2h0PSIxNSIgdmlld0JveD0iMCAwIDE1IDE1Ij48Zz48ZyB0cmFuc2Zvcm09InJvdGF0ZSgtNDUgNy41IDcuNSkiPjxwYXRoIGZpbGw9IiM1ZDZiMDMiIGQ9Ik04LjY3MyA3LjVsNC4wODQtNC4wODRhLjgzLjgzIDAgMCAwLTEuMTczLTEuMTczTDcuNSA2LjMyNyAzLjQxNiAyLjI0M2EuODMuODMgMCAwIDAtMS4xNzMgMS4xNzNMNi4zMjcgNy41bC00LjA4NCA0LjA4NGEuODMuODMgMCAwIDAgMS4xNzMgMS4xNzNMNy41IDguNjczbDQuMDg0IDQuMDg0YS44MjguODI4IDAgMSAwIDEuMTczLTEuMTczTDguNjczIDcuNSIvPjwvZz48L2c+PC9zdmc+);
        display: inline-block;
        margin-right: 12px;
    }

    &--loaded {
        &::before {
            content: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNSIgaGVpZ2h0PSIxNSIgdmlld0JveD0iMCAwIDE1IDE1Ij48Zz48ZyB0cmFuc2Zvcm09InJvdGF0ZSgtNDUgNy41IDcuNSkiPjxwYXRoIGZpbGw9IiM1ZDZiMDMiIGQ9Ik04LjcsNy41TDMuNCwyLjJDMy4xLDEuOSwyLjYsMiwyLjIsMi4zYy0wLjMsMC4zLTAuMywwLjgsMCwxLjFsOS4zLDkuM2MwLjMsMC4zLDAuOCwwLjMsMS4yLDBzMC4zLTAuOCwwLTEuMkw4LjcsNy41Ii8+PC9nPjwvZz48L3N2Zz4=);
        }
    }

    &:hover,
    &:focus {
        border-color: $light-green;
        color: $light-green;
    }
}

[data-load-more] {
    position: relative;
    z-index: 2;

    & ~ [data-load-more] {
        z-index: 1;
    }
}