.pagination {
  margin-top: 20px;

  @media #{$tablet-down} {
    margin: 20px 25px 0;
    text-align: center;
  }

  li {
    padding: 10px 15px;
    border-bottom: 6px solid transparent;

    &.active {
      border-bottom: 6px solid $light-green;
    }
  }

  a {
    font-family: $public-sans-semibold;
    font-size: 18px;
    line-height: 21px;
    color: inherit;

    &:hover,
    &:focus {
      color: $light-green;
    }
  }
}


.pager {
  .announcements & {
    width: 100%;
    text-align: center;
  }
}
