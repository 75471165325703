.species-drawer {
  $parent: &;
  background-color: $dark-blue;
  color: $white;
  padding: 65px 0 90px;

  &--alt {
    background: none;
    color: $black;

    .species-drawer__click-container {
      color: $black;
    }
  }

  &__row {
    position: relative;
    @include flex-row(1355px);
  }

  &__column {
    @include flex-col(24);
    @include med-pad();
  }

  &__columns {
    @include flex-col(24);
    @include flex-col('md', 8);
    @include med-pad();

    &.active {
      #{$parent}__image-container {
        &::before {
          content: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNSIgaGVpZ2h0PSIyNSIgdmlld0JveD0iMCAwIDI1IDI1Ij48Zz48Zz48cGF0aCBmaWxsPSIjYTBiNDI2IiBkPSJNMTUuMTY2IDEyLjVsOS4yODItOS4yODJBMS44ODUgMS44ODUgMCAwIDAgMjEuNzgzLjU1MkwxMi41IDkuODM1IDMuMjE3LjU1MkExLjg4NSAxLjg4NSAwIDAgMCAuNTUyIDMuMjE4TDkuODM0IDEyLjUuNTUyIDIxLjc4M2ExLjg4NSAxLjg4NSAwIDAgMCAyLjY2NSAyLjY2NWw5LjI4My05LjI4MiA5LjI4MyA5LjI4MmExLjg4MiAxLjg4MiAwIDAgMCAyLjY2NiAwIDEuODg1IDEuODg1IDAgMCAwIDAtMi42NjVMMTUuMTY1IDEyLjUiLz48L2c+PC9nPjwvc3ZnPg==);
          position: absolute;
          top: 50%;
          left: 0;
          transform: translate(0, -50%);
          width: 100%;
          text-align: center;
          z-index: 1;
        }

        &::after {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: rgba(0, 0, 0, 0.75);
        }
      }

      #{$parent}__title {
        color: $light-green;
      }
    }
  }

  h2 {
    color: inherit;
    font-size: em(26);
  }

  hr {
    max-width: 30px;
    margin: 16px 0 22px;
    background-color: $light-green;
  }

  &__image-container {
    position: relative;
    cursor: pointer;

    img {
      width: 100%;
    }
  }

  &__title {
    position: relative;
    vertical-align: middle;
    color: inherit;
    font-family: $public-sans-semibold;
    font-size: 22px;
    line-height: 29px;
    text-transform: uppercase;
    margin-top: 13px;
    margin-bottom: 30px;
    padding-left: 30px;
    cursor: pointer;

    &::before {
      content: '';
      position: absolute;
      top: 15px;
      left: 0;
      height: 3px;
      width: 20px;
      background-color: $light-green;
    }
  }

  &__content {
    position: absolute;
    left: 25px;
    width: calc(100% - 50px);
    padding-top: 30px;
    padding-bottom: 50px;
    display: none;

    @media screen and (min-width: 768px) and (max-width: 1440px) {
      left: 35px;
      width: calc(100% - 70px);
    }

    @media #{$large-up} {
      padding-top: 100px;
      padding-bottom: 90px;
    }

    h3 {
      font-family: $merriweather-bold;
      font-size: 32px;
      line-height: 1.1875;

      @media #{$large-up} {
        font-size: 48px;
      }
    }

    p {
      margin-bottom: 50px;
    }
  }

  &--no-bg {
    background-color: #fff;
    color: $black;
    border-bottom: 1px solid $gray;

    .species-drawer__click-container {
      color: $black;
    }
  }

  &__click-container {
    text-align: left;
    color: #FFF;

    &:hover,
    &:focus {
      color: $light-green;
    }
  }
}

.species-content {
  &__row {
    display: flex;
    flex-wrap: wrap;
  }

  &__columns {
    @include media($post-tablet) {
      width: 50%;

      &:first-child {
        width: 42%;
        margin-right: 100px;
      }
    }
  }

  &__related-plans {
    h4 {
      font-family: $public-sans-semibold;
      font-size: 15px;
      font-weight: 600;
      letter-spacing: 1.5px;
      text-transform: uppercase;
      color: inherit;
    }

    ul {
      list-style: none;
      margin: 0 0 40px;
      padding: 0;

      a {
        color: inherit;
        font-family: $merriweather;
        font-size: 26px;
        font-weight: 700;
        line-height: 44px;
        transition: all .25s ease;

        svg {
          margin-left: 10px;
        }

        &:hover {
          color: $light-green;
        }
      }
    }
  }
}

.species-landscapes {
  &__row {
    display: flex;
    flex-wrap: wrap;

    img {
      width: 100%;
      margin-bottom: 15px;
    }
  }

  &__columns {
    width: 50%;
    padding: 0 8px;
    margin-bottom: 45px;

    a {
      color: inherit;
      display: block;
      transition: all .2s ease;

      &:hover {
        color: $light-green;
      }
    }
  }

  &__title {
    font-family: $public-sans-semibold;
    font-size: 18px;
    line-height: 1.2;
    text-transform: uppercase;
    vertical-align: middle;

    &::before {
      content: '';
      display: inline-block;
      vertical-align: middle;
      height: 3px;
      width: 20px;
      margin-right: 10px;
      margin-bottom: 4px;
      background-color: $light-green;
    }
  }
}
