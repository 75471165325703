@charset "UTF-8";
@font-face {
  font-family: 'merriweather';
  src: url("../fonts/merriweather-regular-webfont.woff2") format("woff2"), url("../fonts/merriweather-regular-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'merriweather-bold';
  src: url("../fonts/merriweather-bold-webfont.woff2") format("woff2"), url("../fonts/merriweather-bold-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'public_sans-medium';
  src: url("../fonts/publicsans-medium-webfont.woff2") format("woff2"), url("../fonts/publicsans-medium-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'public_sans-light';
  src: url("../fonts/publicsans-light-webfont.woff2") format("woff2"), url("../fonts/publicsans-light-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'public_sans-regular';
  src: url("../fonts/publicsans-regular-webfont.woff2") format("woff2"), url("../fonts/publicsans-regular-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'public_sans-semibold';
  src: url("../fonts/publicsans-semibold-webfont.woff2") format("woff2"), url("../fonts/publicsans-semibold-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'bebas-neue';
  src: url("../fonts/BebasNeue-Regular.woff2") format("woff2"), url("../fonts/BebasNeue-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

html {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

html {
  background-color: #fff;
  color: #171717;
  font-family: "merriweather", serif;
  font-size: 20px;
  line-height: 36px;
  height: 100%;
}

@media print {
  html {
    background: none;
  }
}

body {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  background: #1E242F;
  color: #171717;
  image-rendering: -webkit-optimize-contrast;
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
  font-size: 20px;
  overflow-x: hidden;
  height: 100%;
}

body.fixed {
  overflow: hidden;
}

@media print {
  body {
    padding-top: 0;
    margin-left: 0.5in;
    margin-right: 0.5in;
  }
}

body.admin-bar {
  margin-top: -16px;
}

@media print {
  @page {
    margin: 2cm;
  }
}

hr {
  background-color: #9f9f9f;
  border: none;
  height: 2px;
  margin: 20px auto;
}

.hr--dashed {
  background-color: transparent;
  border-top: 1px dashed #9f9f9f;
}

.hr--divide {
  margin: 70px 0 50px;
}

.hr--green {
  background-color: #A0B426;
  margin-top: 8px;
  margin-bottom: 8px;
  max-width: 50px;
}

.hr--short {
  max-width: 30px;
  margin-left: 0;
}

.hr--larger-margin {
  margin-top: 64px;
  margin-bottom: 64px;
}

.hr--max-width {
  margin-left: 20px;
  margin-right: 20px;
}

@media (min-width: 768px) {
  .hr--max-width {
    margin-left: 40px;
    margin-right: 40px;
  }
}

.hr--thin {
  height: 1px;
}

.hr--no-bottom-margin {
  margin-bottom: 0;
}

.hr--no-top-margin {
  margin-top: 0;
}

.highlight-word {
  color: #A0B426;
}

a {
  font-family: "merriweather", serif;
  color: #5D6B03;
  cursor: pointer;
  text-decoration: none;
  transition: color 200ms ease-out;
}

a:hover, a:focus {
  color: #A0B426;
}

a.down-arrow-link {
  color: #171717;
}

a.down-arrow-link svg {
  margin-right: 16px;
}

a.down-arrow-link svg path {
  fill: #A0B426;
}

a.down-arrow-link:hover span, a.down-arrow-link:focus span {
  border-bottom: 2px solid #A0B426;
}

dd,
dl,
dt,
fieldset,
ol,
p,
ul,
table {
  margin: 20px 0;
}

cite,
address {
  font-style: normal;
}

figure {
  margin: 0;
}

figure figcaption {
  color: #6A6A6A;
  font-family: "public_sans-regular", sans-serif;
  font-size: 16px;
  line-height: 27px;
  margin-top: 10px;
}

.highlight {
  color: #A0B426;
}

.callout {
  display: block;
  font-family: "bebas-neue", sans-serif;
  font-size: 36px;
  color: #000;
  text-transform: uppercase;
  font-weight: 400;
  margin: 50px 0;
}

ul.inline {
  list-style: none;
  padding: 0;
}

ul.inline li {
  display: inline-block;
  vertical-align: middle;
}

ul.editor-rich-text__tinymce {
  font-size: 1.8em;
}

@media (min-width: 768px) {
  ul.editor-rich-text__tinymce {
    font-size: 2.2em;
  }
}

ul.editor-rich-text__tinymce li > * {
  font-size: 1em;
}

ul.drupal-edit-link {
  padding: 0 15px;
  margin: 20px 0;
  max-width: 1440px;
  list-style: none;
}

@media only screen and (min-width: 1025px) {
  ul.drupal-edit-link {
    margin: 25px auto 60px;
  }
}

ul.drupal-edit-link li {
  display: inline-block;
  margin: 0 15px 0 0;
}

ul.drupal-edit-link li .button {
  padding: 10px 20px;
  font-size: 13px;
}

@media only screen and (max-width: 767px) {
  ul.drupal-edit-link li .button {
    padding: 3px 10px;
  }
}

@media only screen and (min-width: 960px) {
  .embedded-entity.align-left {
    float: left;
    margin: 1rem 1rem 1rem 0;
  }
}

@media only screen and (min-width: 960px) and (min-width: 1200px) {
  .embedded-entity.align-left {
    margin: 1.5rem 1.5rem 1.5rem -18%;
  }
}

@media only screen and (min-width: 960px) {
  .embedded-entity.align-center {
    display: block;
    margin: 16px auto;
    text-align: center;
  }
  .embedded-entity.align-right {
    float: right;
    margin: 1rem 0 1rem 1rem;
  }
}

@media only screen and (min-width: 960px) and (min-width: 1200px) {
  .embedded-entity.align-right {
    margin: 1.5rem -18% 1.5rem 1.5rem;
  }
}

h1,
.h1 {
  font-size: 34px;
  line-height: normal;
  font-family: "merriweather-bold", serif;
  margin: 0 0 0.5rem;
}

@media only screen and (min-width: 1024px) {
  h1,
  .h1 {
    font-size: 55px;
  }
}

h2,
.h2 {
  font-size: 36px;
  line-height: 0.98;
  font-family: "merriweather-bold", serif;
  margin: 0 0 0.5rem;
}

@media only screen and (min-width: 1024px) {
  h2,
  .h2 {
    font-size: 48px;
  }
}

h3,
.h3 {
  font-size: 36px;
  line-height: normal;
  font-family: "merriweather-bold", serif;
  margin: 0 0 0.5rem;
}

h4,
.h4 {
  font-size: 28px;
  line-height: 38px;
  font-family: "merriweather-bold", serif;
  margin: 0 0 0.5rem;
}

h5,
.h5 {
  font-size: 20px;
  line-height: normal;
  font-family: "merriweather-bold", serif;
  margin: 0 0 0.5rem;
}

h6,
.h6 {
  font-size: 15px;
  line-height: normal;
  font-family: "public_sans-semibold", sans-serif;
  margin: 0 0 0.5rem;
}

.figcaption {
  margin: 10px auto 0;
  font-size: 1.6em;
  text-align: left;
  color: #171717;
}

.figcaption p {
  font-size: 1em;
  margin: 0;
}

img {
  height: auto;
  margin: 0 auto;
  max-width: 100%;
  vertical-align: bottom;
}

nav ol,
nav ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

nav li {
  margin: 0;
}

nav.inline li {
  display: inline-block;
  vertical-align: middle;
}

dt {
  font-weight: bold;
}

dd {
  margin-left: 0;
}

svg {
  max-width: 100%;
}

fieldset {
  border: 0;
  border-top: 1px solid #9f9f9f;
  padding: 0;
}

legend {
  margin-left: 0.5em;
  padding: 0 0.5em;
}

label {
  cursor: pointer;
  display: inline-block;
}

a,
input[type="button"],
input[type="submit"],
input[type="reset"],
button {
  cursor: pointer;
}

[hidden] {
  display: none !important;
}

.svgs,
.svg-hide {
  display: none;
}

.content-wrapper {
  width: 100%;
  position: relative;
  z-index: 4;
  padding-top: 63px;
  padding-bottom: 50px;
}

.content-wrapper:focus {
  outline: none;
}

a:focus,
button:focus,
[tabindex="0"]:focus,
[tabindex="-1"]:focus {
  outline: none;
}

.keyboard--focus a:focus, .keyboard--focus
button:focus, .keyboard--focus
[tabindex="0"]:focus, .keyboard--focus
[tabindex="-1"]:focus {
  outline: 1px dotted #171717;
}

button {
  appearance: none;
  border: 0;
  padding: 0;
  background: none;
  border: 0;
}

.site-container {
  background-color: #fff;
  overflow: hidden;
}

div[data-drupal-messages] {
  max-width: 1440px;
  margin: 0 auto;
  padding: 0 15px;
}

.hp-hero {
  position: relative;
  z-index: 1;
}

@media (min-width: 1024px) {
  .hp-hero {
    margin-top: -29px;
  }
}

.hp-hero__video {
  position: relative;
  overflow: hidden;
  height: calc(100vh - 120px);
  z-index: 1;
}

@media (min-width: 768px) {
  .hp-hero__video {
    height: 100vh;
  }
}

.hp-hero__video::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #000;
  opacity: 0.4;
  z-index: 1;
}

.hp-hero__video video {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  transform: translate(-50%, -50%);
  object-fit: cover;
}

.hp-hero__play-button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 95px;
  height: 95px;
  border: 1px solid rgba(255, 255, 255, 0.33);
  border-radius: 50%;
  display: block;
  z-index: 2;
  transition: all .2s ease;
  opacity: 0;
}

.yt-ready .hp-hero__play-button {
  opacity: 1;
}

.hp-hero__play-button__triangle {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: block;
}

.hp-hero__play-button svg {
  overflow: visible;
}

.hp-hero__play-button:hover, .hp-hero__play-button:focus {
  background: #A0B426;
  border-color: #A0B426;
}

.hp-hero__text {
  text-align: center;
  position: absolute;
  bottom: 125px;
  padding: 5px 35px;
  left: 50%;
  transform: translate(-50%, 0);
  z-index: 10;
  width: 100%;
  max-width: 600px;
  color: #fff;
  font-family: "merriweather-bold", serif;
  transition: all .25s ease;
  font-size: 36px;
  line-height: 1.3;
}

@media (max-width: 767px) {
  .hp-hero__text {
    font-size: 24px;
    line-height: 1.3;
    bottom: 90px;
  }
}

.partnerships-investments-container {
  position: relative;
  background-size: 100%;
  background-position: center bottom;
  background-repeat: no-repeat;
  background-color: #fff;
  z-index: 3;
}

.partnerships-investments-container::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 40%;
  background-image: linear-gradient(180deg, #eeeeee 0%, #ffffff 50%, rgba(255, 255, 255, 0) 100%);
  z-index: -1;
}

.hp-partnerships__row,
.hp-results__row {
  position: relative;
  display: flex;
  box-sizing: border-box;
  flex: 0 1 auto;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  margin: 0 auto;
  max-width: 1446px;
  background-color: #fff;
  justify-content: center;
  text-align: center;
  padding: 70px 0 90px;
  margin-bottom: -100px;
  z-index: 5;
}

@media (min-width: 768px) {
  .hp-partnerships__row,
  .hp-results__row {
    top: -100px;
    margin-bottom: -100px;
  }
}

.hp-partnerships__column,
.hp-results__column {
  box-sizing: border-box;
  flex-basis: 100%;
  max-width: 100%;
  padding: 0 25px;
}

@media only screen and (min-width: 1025px) {
  .hp-partnerships__column,
  .hp-results__column {
    box-sizing: border-box;
    flex-basis: 91.66667%;
    max-width: 91.66667%;
    padding: 0 25px;
  }
}

@media only screen and (min-width: 1200px) {
  .hp-partnerships__column,
  .hp-results__column {
    box-sizing: border-box;
    flex-basis: 75%;
    max-width: 75%;
    padding: 0 25px;
  }
}

.hp-partnerships__column a:not(.button),
.hp-results__column a:not(.button) {
  text-decoration: underline;
}

.hp-partnerships__intro-title,
.hp-results__intro-title {
  font-family: "public_sans-medium", sans-serif;
  font-size: 15px;
  text-transform: uppercase;
  color: #6A6A6A;
}

.hp-partnerships hr,
.hp-results hr {
  background-color: #A0B426;
  max-width: 30px;
  margin: 5px auto 0;
  height: 2px;
}

.hp-partnerships p,
.hp-results p {
  font-size: 20px;
  line-height: 45px;
  margin-bottom: 35px;
}

@media (min-width: 768px) {
  .hp-partnerships p,
  .hp-results p {
    font-size: 26px;
  }
}

.hp-partnerships__row {
  top: 0;
}

.hp-investments__row {
  display: flex;
  box-sizing: border-box;
  flex: 0 1 auto;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  margin: 0 auto;
  max-width: 1305px;
  padding: 0 0 23vw;
}

.hp-investments__columns {
  box-sizing: border-box;
  flex-basis: 100%;
  max-width: 100%;
  padding: 0 25px;
}

@media only screen and (min-width: 768px) {
  .hp-investments__columns {
    box-sizing: border-box;
    flex-basis: 50%;
    max-width: 50%;
    padding: 0 25px;
  }
}

@media only screen and (min-width: 1025px) {
  .hp-investments__columns:first-child {
    box-sizing: border-box;
    flex-basis: 50%;
    max-width: 50%;
    padding: 0 25px;
  }
}

.hp-investments__columns:last-child {
  margin-left: auto;
}

@media only screen and (min-width: 1025px) {
  .hp-investments__columns:last-child {
    box-sizing: border-box;
    flex-basis: 33.33333%;
    max-width: 33.33333%;
    padding: 0 25px;
  }
}

.hp-investments__intro-title {
  font-family: "public_sans-medium", sans-serif;
  font-size: 15px;
  text-transform: uppercase;
  color: #6A6A6A;
}

.hp-investments__featured-landscape {
  display: flex;
  flex-wrap: wrap;
  margin-top: 30px;
  margin-bottom: 30px;
  align-items: center;
}

.hp-investments__featured-landscape img {
  max-width: 192px;
  margin: 0 40px 0 0;
}

.hp-investments__featured-landscape a:not(.button) {
  text-decoration: none;
}

.hp-investments__landscape-type {
  font-family: "public_sans-semibold", sans-serif;
  font-size: 11px;
  letter-spacing: 1.44px;
  text-transform: uppercase;
  color: #323232;
}

.hp-investments__landscape-title {
  font-family: "merriweather-bold", serif;
  font-size: 22px;
  line-height: 31px;
  color: #171717;
}

@media (min-width: 768px) {
  .hp-investments__landscape-title {
    font-size: 28px;
  }
}

.hp-investments__stat {
  padding: 30px 10px 20px;
  background-image: linear-gradient(145deg, #338fb8 0%, #115c7b 100%);
  text-align: center;
  color: #fff;
  max-width: 396px;
  margin: 0 auto;
  position: relative;
  z-index: 5;
}

.hp-investments__stat .button {
  background-color: #00435e;
  padding: 15px 10px;
}

.hp-investments__stat .button svg:first-child {
  margin-right: 10px;
}

@media (min-width: 768px) {
  .hp-investments__stat .button {
    padding: 15px 20px;
  }
  .hp-investments__stat .button svg:first-child {
    margin-right: 24px;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .hp-investments__stat .button {
    padding: 15px 10px;
  }
}

.hp-investments__stat-number {
  font-size: 80px;
  line-height: 1.15;
  margin-bottom: 5px;
}

@media (min-width: 768px) {
  .hp-investments__stat-number {
    font-size: 90px;
  }
}

.hp-investments__stat-desc {
  font-size: 20px;
  line-height: 28px;
  margin-bottom: 25px;
}

.hp-investments hr {
  max-width: 30px;
  background-color: #A0B426;
  height: 2px;
  margin: 0;
}

.hp-investments p {
  font-size: 20px;
  line-height: 45px;
}

@media (min-width: 768px) {
  .hp-investments p {
    font-size: 26px;
  }
}

.hp-investments a:not(.button) {
  text-decoration: underline;
}

.hp-investments a:not(.button):hover, .hp-investments a:not(.button):focus {
  color: #A0B426;
}

.hp-investments a:not(.button):hover .hp-investments__landscape-title, .hp-investments a:not(.button):focus .hp-investments__landscape-title {
  color: #A0B426;
}

.hp-investments .hp-investments__featured-landscape a {
  text-decoration: none;
}

.hp-results {
  position: relative;
  background-color: #fff;
  z-index: 6;
}

.hp-results::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(180deg, #eeeeee 0%, #ffffff 50%, rgba(255, 255, 255, 0) 100%);
  z-index: -1;
}

.hp-featured-story,
.hp-press,
.hp-cta {
  position: relative;
  z-index: 7;
}

.hp-featured-story__row,
.hp-press__row,
.hp-cta__row {
  display: flex;
  box-sizing: border-box;
  flex: 0 1 auto;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  margin: 0 auto;
  max-width: 1305px;
  align-items: center;
}

.hp-featured-story__column,
.hp-press__column,
.hp-cta__column {
  box-sizing: border-box;
  flex-basis: 100%;
  max-width: 100%;
  padding: 0 25px;
}

.hp-featured-story__columns,
.hp-press__columns,
.hp-cta__columns {
  box-sizing: border-box;
  flex-basis: 100%;
  max-width: 100%;
  padding: 0 25px;
}

@media only screen and (min-width: 1025px) {
  .hp-featured-story__columns,
  .hp-press__columns,
  .hp-cta__columns {
    box-sizing: border-box;
    flex-basis: 50%;
    max-width: 50%;
    padding: 0 25px;
  }
}

@media only screen and (min-width: 1025px) {
  .hp-featured-story__columns:first-child,
  .hp-press__columns:first-child,
  .hp-cta__columns:first-child {
    box-sizing: border-box;
    flex-basis: 54.16667%;
    max-width: 54.16667%;
    padding: 0 25px;
  }
}

.hp-featured-story__columns:last-child,
.hp-press__columns:last-child,
.hp-cta__columns:last-child {
  margin-left: auto;
}

@media only screen and (min-width: 1025px) {
  .hp-featured-story__columns:last-child,
  .hp-press__columns:last-child,
  .hp-cta__columns:last-child {
    box-sizing: border-box;
    flex-basis: 41.66667%;
    max-width: 41.66667%;
    padding: 0 25px;
  }
}

.hp-featured-story__intro-title {
  font-family: "public_sans-medium", sans-serif;
  font-size: 15px;
  text-transform: uppercase;
  color: #6A6A6A;
}

.hp-featured-story__topic {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-top: 33px;
}

.hp-featured-story__topic img {
  max-width: 192px;
  margin: 0 40px 0 0;
}

.hp-featured-story__topic-subtitle {
  color: #171717;
  font-family: "public_sans-semibold", sans-serif;
  font-size: 11px;
  letter-spacing: 1.44px;
  text-transform: uppercase;
}

.hp-featured-story h2,
.hp-featured-story h2 a {
  color: #171717;
  font-family: "merriweather-bold", serif;
  font-size: 28px;
  line-height: 1.3;
}

@media (min-width: 768px) {
  .hp-featured-story h2,
  .hp-featured-story h2 a {
    font-size: 36px;
  }
}

.hp-featured-story h2:hover, .hp-featured-story h2:focus,
.hp-featured-story h2 a:hover,
.hp-featured-story h2 a:focus {
  color: #A0B426;
}

.hp-featured-story h3,
.hp-featured-story h3 a {
  color: #171717;
  font-size: 28px;
  line-height: 31px;
}

.hp-featured-story h3:hover, .hp-featured-story h3:focus,
.hp-featured-story h3 a:hover,
.hp-featured-story h3 a:focus {
  color: #A0B426;
}

.hp-featured-story hr:not(.hr--dashed) {
  height: 2px;
  width: 100%;
  background-color: #BFBFBF;
  margin: 30px 0;
}

@media (min-width: 768px) {
  .hp-featured-story hr:not(.hr--dashed) {
    margin: 70px 0;
  }
}

.hp-press__row:last-child:not(:first-child) hr {
  height: 2px;
  width: 100%;
  background-color: #BFBFBF;
  margin: 50px 0 70px;
}

.hp-press__top-bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
}

@media (max-width: 1023px) {
  .hp-press__top-bar {
    margin-top: 50px;
  }
}

.hp-press__top-bar a {
  font-family: "public_sans-semibold", sans-serif;
  font-size: 14px;
  letter-spacing: 1.17px;
  text-transform: uppercase;
}

.hp-press__top-bar a:hover, .hp-press__top-bar a:focus {
  color: #A0B426;
}

.hp-press__title {
  color: #6A6A6A;
  font-family: "public_sans-medium", sans-serif;
  font-size: 15px;
  text-transform: uppercase;
}

.hp-press__title hr {
  max-width: 30px;
  height: 2px;
  background-color: #A0B426;
  margin: 0;
}

.hp-press__article {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 45px;
}

@media (max-width: 767px) {
  .hp-press__article {
    flex-direction: column-reverse;
  }
}

.hp-press__article:last-of-type {
  margin-bottom: 0;
}

.hp-press__article img {
  margin-bottom: 10px;
}

@media (min-width: 768px) {
  .hp-press__article img {
    max-width: 192px;
    margin-left: 40px;
    margin-bottom: 0;
  }
}

.hp-press__article a {
  display: block;
  color: #171717;
  font-family: "merriweather-bold", serif;
  font-size: 18px;
  line-height: 31px;
  margin-bottom: 5px;
}

.hp-press__article a:hover, .hp-press__article a:focus {
  color: #A0B426;
}

.hp-press__article-date {
  color: #6A6A6A;
  font-family: "public_sans-medium", sans-serif;
  font-size: 15px;
  text-transform: uppercase;
}

.hp-press .hp-press__slide {
  background-color: #191c22;
  color: #fff;
}

.hp-press .hp-press__slide img {
  width: 100%;
}

.hp-press__img-container {
  position: relative;
}

.hp-press__img-container::before {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #191c22 100%);
}

.hp-press__slide-content {
  padding: 0 30px 60px;
}

@media only screen and (min-width: 960px) {
  .hp-press__slide-content {
    padding: 0 60px 60px;
  }
}

.hp-press__slide-date {
  opacity: 0.8;
  font-family: "public_sans-semibold", sans-serif;
  font-size: 15px;
  text-transform: uppercase;
  margin-bottom: 5px;
}

.hp-press__slide-title {
  font-size: 27px;
  font-weight: 700;
  line-height: 40px;
}

.hp-press__bottom-row {
  display: flex;
  align-items: center;
  margin-top: 25px;
  line-height: 1;
}

.hp-press__bottom-row .button {
  padding: 0;
  font-size: 13px;
  padding: 13px 25px;
  border-radius: 3px;
  margin-right: 28px;
}

.hp-press__bottom-row a:not(.button) {
  font-size: 14px;
  letter-spacing: 1.17px;
  text-transform: uppercase;
  font-family: "public_sans-semibold", sans-serif;
  color: #A0B426;
}

.hp-press__bottom-row a:not(.button):hover, .hp-press__bottom-row a:not(.button):focus {
  color: #5D6B03;
}

.hp-press .glide__arrows {
  position: absolute;
  display: flex;
  justify-content: space-between;
  padding: 0 10px;
  top: 45%;
  transform: translate(0, -50%);
  width: 100%;
}

@media (min-width: 768px) {
  .hp-press .glide__arrows {
    padding: 0 20px;
  }
}

.hp-press .glide__slides {
  margin: 0;
}

.hp-press .glide__arrow svg {
  width: 13px;
  height: 25px;
}

.hp-press .glide__arrow--left {
  transform: rotate(-180deg);
}

.hp-press .glide__bullets {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  bottom: 40px;
  width: 100%;
  height: auto;
  text-align: center;
}

.hp-press .glide__bullet {
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: #fff;
  opacity: 0.7;
  margin: 0 10px;
}

.hp-press .glide__bullet--active {
  width: 10px;
  height: 10px;
  opacity: 1;
}

.hp-cta {
  padding: 0 0 70px;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  color: #171717;
}

.hp-cta__row {
  align-items: center;
}

.hp-cta__columns {
  box-sizing: border-box;
  flex-basis: 100%;
  max-width: 100%;
  padding: 0 25px;
}

.hp-cta__columns:first-child:not(:last-child) {
  order: 2;
}

@media only screen and (min-width: 1025px) {
  .hp-cta__columns:first-child:not(:last-child) {
    box-sizing: border-box;
    flex-basis: 45.83333%;
    max-width: 45.83333%;
    padding: 0 25px;
  }
}

@media only screen and (min-width: 1025px) {
  .hp-cta__columns:first-child:not(:last-child) {
    order: 1;
  }
}

.hp-cta__columns:first-child:not(:last-child) h2 {
  margin-top: 20px;
}

@media (max-width: 767px) {
  .hp-cta__columns:first-child:not(:last-child) h2 {
    line-height: 1.4;
  }
}

.hp-cta__columns:last-child:not(:first-child) {
  order: 1;
  text-align: right;
}

@media only screen and (min-width: 1025px) {
  .hp-cta__columns:last-child:not(:first-child) {
    box-sizing: border-box;
    flex-basis: 54.16667%;
    max-width: 54.16667%;
    padding: 0 25px;
  }
}

@media only screen and (min-width: 1025px) {
  .hp-cta__columns:last-child:not(:first-child) {
    order: 2;
  }
  .hp-cta__columns:last-child:not(:first-child) img {
    width: auto;
  }
}

.hp-cta hr {
  margin: 0;
}

.hp-cta h2 {
  font-size: 36px;
  line-height: 66px;
  margin-bottom: 0;
}

.hp-cta img {
  width: 100%;
}

.hp-cta p {
  color: #4c4c4c;
  margin-bottom: 30px;
}

.hp-cta .button {
  padding: 20px 25px;
}

.accordions {
  margin: 20px 0;
}

.accordions__row {
  display: flex;
  box-sizing: border-box;
  flex: 0 1 auto;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  margin: 0 auto;
  max-width: 1100px;
}

.accordions__column {
  box-sizing: border-box;
  flex-basis: 100%;
  max-width: 100%;
  padding: 0 25px;
}

@media screen and (min-width: 768px) and (max-width: 1440px) {
  .accordions__column {
    padding: 0 35px;
  }
}

.accordions .accordion {
  position: relative;
  color: #171717;
  font-family: "merriweather-bold", serif;
  font-size: 18px;
  line-height: 28px;
  width: 100%;
  text-align: left;
  transition: 0.4s;
  padding: 16px 0 16px 60px;
  border-bottom: 1px dashed #9f9f9f;
}

@media (min-width: 768px) {
  .accordions .accordion {
    font-size: 28px;
    line-height: 38px;
  }
}

@media screen and (min-width: 1100px) {
  .accordions .accordion {
    padding-left: 0;
    margin-left: 30px;
    width: calc(100% - 30px);
  }
}

.accordions .accordion .icon {
  position: absolute;
  display: block;
  top: 50%;
  left: 0px;
  transform: translate(0, -50%);
  width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden;
  text-align: center;
  z-index: 1;
}

@media screen and (min-width: 1100px) {
  .accordions .accordion .icon {
    left: -50px;
  }
}

.accordions .accordion .icon::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-style: solid;
  height: 0;
  width: 0;
  border-color: transparent transparent transparent #A0B426;
  border-width: 6.5px 0 6.5px 7px;
}

.accordions .accordion:hover .icon, .accordions .accordion:focus .icon {
  border: 1px solid #CDCDCD;
}

.accordions .accordion.active .icon::after {
  border-style: solid;
  height: 0;
  width: 0;
  border-color: #A0B426 transparent transparent;
  border-width: 7px 6.5px 0;
}

.accordions .panel {
  background-color: white;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.2s ease-out;
}

@media screen and (min-width: 1100px) {
  .accordions .panel {
    padding: 0 30px;
  }
}

.accordions .panel .inner-container {
  padding: 20px 0;
}

@media (min-width: 768px) {
  .accordions .panel .inner-container {
    padding: 40px 0;
  }
}

.accordions .panel p {
  margin-top: 0;
}

@media (max-width: 767px) {
  .accordions .panel p {
    font-size: 16px;
  }
}

.accordions .panel .offices__column,
.accordions .panel .offices__columns {
  padding: 0;
}

.announcements {
  padding: 35px 0;
}

@media only screen and (min-width: 768px) {
  .announcements {
    padding: 55px 0;
  }
}

.announcements__row {
  display: flex;
  box-sizing: border-box;
  flex: 0 1 auto;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  margin: 0 auto;
  max-width: 1355px;
}

.announcements__column {
  display: flex;
  box-sizing: border-box;
  flex-basis: 100%;
  max-width: 100%;
  padding: 0 25px;
}

@media screen and (min-width: 768px) and (max-width: 1440px) {
  .announcements__column {
    padding: 0 35px;
  }
}

@media only screen and (max-width: 639px) {
  .announcements__column {
    flex-wrap: wrap;
  }
}

.announcements__column .views-exposed-form {
  display: flex;
  box-sizing: border-box;
  flex: 0 1 auto;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  margin: 0 auto;
  max-width: 1100px;
  padding: 0 25px;
  margin-bottom: 50px;
}

@media screen and (min-width: 768px) and (max-width: 1440px) {
  .announcements__column .views-exposed-form {
    padding: 0 35px;
  }
}

.announcements__columns {
  box-sizing: border-box;
  flex-basis: 100%;
  max-width: 100%;
  padding: 0 25px;
  margin-bottom: 20px;
}

@media only screen and (min-width: 768px) {
  .announcements__columns {
    box-sizing: border-box;
    flex-basis: 50%;
    max-width: 50%;
    padding: 0 25px;
  }
}

@media only screen and (min-width: 1025px) {
  .announcements__columns {
    box-sizing: border-box;
    flex-basis: 33.33333%;
    max-width: 33.33333%;
    padding: 0 25px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1440px) {
  .announcements__columns {
    padding: 0 35px;
  }
}

.announcements__date {
  color: #6A6A6A;
  font-family: "public_sans-medium", sans-serif;
  font-size: 15px;
  text-transform: uppercase;
}

.announcements img {
  width: 100%;
  margin-bottom: 10px;
}

.announcements h4 {
  margin-right: 40px;
  font-size: 26px;
}

@media only screen and (max-width: 639px) {
  .announcements h4 hr {
    margin-bottom: 0;
  }
}

.announcements h4 ~ a {
  margin-top: 4px;
  font-family: "public_sans-semibold", sans-serif;
  font-size: 14px;
  letter-spacing: 1.17px;
  text-transform: uppercase;
}

@media only screen and (max-width: 639px) {
  .announcements h4 ~ a {
    display: block;
    margin-bottom: 20px;
  }
}

.announcements h4 ~ a:hover, .announcements h4 ~ a:focus {
  color: #A0B426;
}

.announcements h4 ~ a:hover svg path, .announcements h4 ~ a:focus svg path {
  stroke: #A0B426;
}

.announcements h4 ~ a svg {
  position: relative;
  top: 2px;
  width: 6px;
  margin-left: 10px;
}

.announcements h4 ~ a svg path {
  stroke: #5D6B03;
}

.announcements h5,
.announcements h5 a {
  font-size: 18px;
  line-height: 31px;
  color: #171717;
  margin-bottom: 0;
}

.announcements h5:hover, .announcements h5:focus,
.announcements h5 a:hover,
.announcements h5 a:focus {
  color: #A0B426;
}

.annual-report {
  margin: 35px 0 0;
}

.annual-report__row {
  display: flex;
  box-sizing: border-box;
  flex: 0 1 auto;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  margin: 0 auto;
  max-width: 1355px;
}

.annual-report__column {
  display: flex;
  box-sizing: border-box;
  flex-basis: 100%;
  max-width: 100%;
  padding: 0 25px;
  margin-bottom: 25px;
}

@media screen and (min-width: 768px) and (max-width: 1440px) {
  .annual-report__column {
    padding: 0 35px;
  }
}

.annual-report__columns {
  box-sizing: border-box;
  flex-basis: 50%;
  max-width: 50%;
  padding: 0 25px;
  margin-bottom: 20px;
}

@media only screen and (min-width: 768px) {
  .annual-report__columns {
    box-sizing: border-box;
    flex-basis: 25%;
    max-width: 25%;
    padding: 0 25px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1440px) {
  .annual-report__columns {
    padding: 0 35px;
  }
}

.annual-report__columns a:hover, .annual-report__columns a:focus {
  color: #A0B426;
}

.annual-report__columns a:hover .annual-report__title, .annual-report__columns a:focus .annual-report__title {
  color: #A0B426;
}

.annual-report__title {
  color: #6A6A6A;
  font-family: "public_sans-medium", sans-serif;
  font-size: 15px;
  line-height: 1.3;
  text-transform: uppercase;
  margin-bottom: 5px;
}

.annual-report--padding-bottom {
  padding-bottom: 80px;
}

.annual-report h4 {
  display: inline-block;
  margin-right: 40px;
}

.annual-report h4 ~ a {
  margin-top: 4px;
  font-family: "public_sans-semibold", sans-serif;
  font-size: 14px;
  letter-spacing: 1.17px;
  text-transform: uppercase;
}

.annual-report h4 ~ a:hover svg path, .annual-report h4 ~ a:focus svg path {
  stroke: #A0B426;
}

.annual-report h4 ~ a svg {
  position: relative;
  top: 2px;
  width: 6px;
  margin-left: 10px;
}

.annual-report h4 ~ a svg path {
  stroke: #5D6B03;
}

.blockquote-module {
  margin: 80px 0 40px;
}

.blockquote-module__row {
  display: flex;
  box-sizing: border-box;
  flex: 0 1 auto;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  margin: 0 auto;
  max-width: 1100px;
}

.blockquote-module__column {
  box-sizing: border-box;
  flex-basis: 100%;
  max-width: 100%;
  padding: 0 25px;
  padding-left: 40px;
  border-left: 3px solid #D17A22;
}

@media only screen and (min-width: 640px) {
  .blockquote-module__author {
    display: flex;
    align-items: center;
  }
}

.blockquote-module__author-name {
  font-family: "public_sans-semibold", sans-serif;
  font-size: 19px;
  line-height: 26px;
  color: #171717;
  text-transform: uppercase;
}

@media only screen and (max-width: 639px) {
  .blockquote-module__author-name {
    margin-top: 10px;
  }
}

.blockquote-module__author-company {
  font-family: "public_sans-semibold", sans-serif;
  font-size: 16px;
  line-height: 26px;
  color: #6A6A6A;
  margin-top: 5px;
}

.blockquote-module p {
  margin-top: 0;
  font-family: "public_sans-semibold", sans-serif;
  font-size: 22px;
}

.blockquote-module img {
  margin: 20px 0 0;
  max-width: 220px;
  border: 1px solid #BFBFBF;
  background-color: #fff;
  margin-right: 40px;
}

.breadcrumbs {
  padding: 35px 0 10px;
}

.breadcrumbs__row {
  display: flex;
  box-sizing: border-box;
  flex: 0 1 auto;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  margin: 0 auto;
  max-width: 1100px;
  align-items: center;
}

.breadcrumbs__columns {
  box-sizing: border-box;
  flex-basis: 100%;
  max-width: 100%;
  padding: 0 25px;
}

@media only screen and (min-width: 1025px) {
  .breadcrumbs__columns:first-child {
    box-sizing: border-box;
    flex-basis: 70.83333%;
    max-width: 70.83333%;
    padding: 0 25px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1440px) {
  .breadcrumbs__columns:first-child {
    padding: 0 35px;
  }
}

@media only screen and (min-width: 1025px) {
  .breadcrumbs__columns:last-child {
    box-sizing: border-box;
    flex-basis: 29.16667%;
    max-width: 29.16667%;
    padding: 0 25px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1440px) {
  .breadcrumbs__columns:last-child {
    padding: 0 35px;
  }
}

.breadcrumbs__list {
  display: none;
}

@media only screen and (min-width: 1025px) {
  .breadcrumbs__list {
    display: block;
  }
}

.breadcrumbs__list .separator {
  padding: 0 14px;
  font-size: 18px;
  color: #c9c9c9;
}

.breadcrumbs__list li {
  font-family: "public_sans-medium", sans-serif;
  font-size: 15px;
}

.breadcrumbs__list a {
  font-family: "public_sans-medium", sans-serif;
  font-size: 15px;
  color: #171717;
}

.breadcrumbs__list a:hover, .breadcrumbs__list a:focus {
  color: #A0B426;
}

.breadcrumbs .social-media-icons ul {
  display: flex;
  flex-wrap: wrap;
}

.breadcrumbs .social-media-icons li {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  margin: 0 14px;
}

.breadcrumbs .social-media-icons li:first-child {
  margin-left: 0;
}

.breadcrumbs .social-media-icons li:last-child {
  margin-right: 0;
}

.breadcrumbs .social-media-icons li a {
  display: inline-flex;
  line-height: 1;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  width: 56px;
  height: 56px;
  text-align: center;
}

.breadcrumbs .social-media-icons li a svg {
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.breadcrumbs .social-media-icons li a:hover svg path, .breadcrumbs .social-media-icons li a:focus svg path {
  fill: #A0B426;
}

.breadcrumbs--alt-top-padding {
  padding-top: 12px;
}

.breadcrumbs--alt-bottom-padding {
  padding-bottom: 16px;
}

.button {
  display: inline-block;
  vertical-align: middle;
  padding: 25px 30px;
  border: 2px solid transparent;
  border-radius: 5px;
  transition: background-color 250ms ease-out, border-color 250ms ease-out;
  font-family: "public_sans-semibold", sans-serif;
  font-size: 16px;
  appearance: none;
  line-height: 1;
  letter-spacing: 1.33px;
  text-transform: uppercase;
  text-decoration: none;
  text-align: center;
  cursor: pointer;
  background-color: #8B9C21;
  color: #fff;
}

.button svg:first-child {
  margin-right: 24px;
  vertical-align: middle;
}

.button svg:last-child {
  margin-left: 24px;
  vertical-align: middle;
}

.button svg path[fill] {
  fill: #fff;
}

.button svg path[fill="none"][stroke] {
  fill: none;
  stroke: #fff;
}

.button:hover, .button:focus {
  background-color: #A0B426;
  border-color: #A0B426;
  color: #fff;
}

.button:hover svg path[fill], .button:focus svg path[fill] {
  fill: #fff;
}

.button:hover svg path[fill="none"][stroke], .button:focus svg path[fill="none"][stroke] {
  fill: none;
  stroke: #fff;
}

.button--secondary {
  background-color: #21447e;
}

.button--secondary:hover, .button--secondary:focus {
  background-color: #3369c3;
  border-color: #3369c3;
}

.button--outline {
  background-color: transparent;
}

.button--outline--bg-white {
  background-color: #fff;
}

.button--outline--green {
  border-color: #8B9C21;
  color: #8B9C21;
}

.button--outline--green svg path[fill] {
  fill: #8B9C21;
}

.button--outline--green svg path[fill="none"][stroke] {
  fill: none;
  stroke: #8B9C21;
}

.button--outline--green:hover, .button--outline--green:focus {
  border-color: #A0B426;
  color: #fff;
  background-color: #A0B426;
}

.button--outline--green:hover svg path[fill], .button--outline--green:focus svg path[fill] {
  fill: #fff;
}

.button--outline--green:hover svg path[fill="none"][stroke], .button--outline--green:focus svg path[fill="none"][stroke] {
  fill: none;
  stroke: #fff;
}

.button--outline--blue {
  border-color: #21447e;
  color: #21447e;
}

.button--outline--blue svg path[fill] {
  fill: #21447e;
}

.button--outline--blue svg path[fill="none"][stroke] {
  fill: none;
  stroke: #21447e;
}

.button--outline--blue:hover, .button--outline--blue:focus {
  border-color: #3369c3;
  color: #fff;
  background-color: #3369c3;
}

.button--outline--blue:hover svg path[fill], .button--outline--blue:focus svg path[fill] {
  fill: #fff;
}

.button--outline--blue:hover svg path[fill="none"][stroke], .button--outline--blue:focus svg path[fill="none"][stroke] {
  fill: none;
  stroke: #fff;
}

.corporate-partner {
  background-color: #1E242F;
  padding: 70px 0 72px;
  color: #fff;
}

.corporate-partner__row {
  display: flex;
  box-sizing: border-box;
  flex: 0 1 auto;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  margin: 0 auto;
  max-width: 1140px;
  align-items: center;
}

.corporate-partner__columns {
  box-sizing: border-box;
  flex-basis: 100%;
  max-width: 100%;
  padding: 0 25px;
}

@media only screen and (min-width: 1025px) {
  .corporate-partner__columns {
    box-sizing: border-box;
    flex-basis: 50%;
    max-width: 50%;
    padding: 0 25px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1440px) {
  .corporate-partner__columns {
    padding: 0 35px;
  }
}

.corporate-partner__stat {
  font-family: "bebas-neue", sans-serif;
  font-size: 36px;
  text-transform: uppercase;
  margin-top: 45px;
  margin-bottom: 20px;
}

.corporate-partner p:first-child::first-letter {
  font-family: "merriweather-bold", serif;
  font-size: 64px;
  text-transform: uppercase;
  vertical-align: top;
  float: left;
  margin: 16px 16px 10px 0;
}

.corporate-partner img {
  border: 1px solid #bfbfbf;
}

.cta {
  padding: 0 0 50px;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  color: #fff;
}

.cta__row {
  display: flex;
  box-sizing: border-box;
  flex: 0 1 auto;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  margin: 0 auto;
  max-width: 1338px;
  align-items: center;
}

.cta__columns {
  box-sizing: border-box;
  flex-basis: 100%;
  max-width: 100%;
  padding: 0 25px;
}

@media screen and (min-width: 768px) and (max-width: 1440px) {
  .cta__columns {
    padding: 0 35px;
  }
}

.cta__columns:first-child:not(:last-child) {
  order: 2;
}

@media only screen and (min-width: 1025px) {
  .cta__columns:first-child:not(:last-child) {
    box-sizing: border-box;
    flex-basis: 45.83333%;
    max-width: 45.83333%;
    padding: 0 25px;
  }
}

@media only screen and (min-width: 1025px) {
  .cta__columns:first-child:not(:last-child) {
    order: 1;
  }
}

.cta__columns:first-child:not(:last-child) h2 {
  margin-top: 20px;
}

.cta__columns:last-child:not(:first-child) {
  order: 1;
  text-align: right;
}

@media only screen and (min-width: 1025px) {
  .cta__columns:last-child:not(:first-child) {
    box-sizing: border-box;
    flex-basis: 54.16667%;
    max-width: 54.16667%;
    padding: 0 25px;
  }
}

@media only screen and (min-width: 1025px) {
  .cta__columns:last-child:not(:first-child) {
    order: 2;
  }
  .cta__columns:last-child:not(:first-child) img {
    width: auto;
  }
}

.cta h2 {
  font-size: 26px;
  line-height: 46px;
  margin-bottom: 40px;
}

@media tablet-up {
  .cta h2 {
    font-size: 36px;
    line-height: 66px;
  }
}

.cta img {
  margin-top: -50px;
  width: 100%;
  box-shadow: 0 24px 34px rgba(0, 0, 0, 0.25);
}

.cta--simple {
  padding: 115px 0 135px;
  text-align: center;
}

.cta--simple .cta__columns {
  box-sizing: border-box;
  flex-basis: 100%;
  max-width: 100%;
  padding: 0 25px;
}

@media screen and (min-width: 768px) and (max-width: 1440px) {
  .cta--simple .cta__columns {
    padding: 0 35px;
  }
}

.cta--simple h2 {
  margin-bottom: 32px;
}

.cta--solid-bg {
  background-color: #004A7E;
}

.custom-table {
  margin: 30px 0 50px;
}

.custom-table__row {
  display: flex;
  box-sizing: border-box;
  flex: 0 1 auto;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  margin: 0 auto;
  max-width: 1446px;
}

.custom-table table {
  border-collapse: collapse;
  margin: 0;
  padding: 0;
  width: 100%;
  table-layout: fixed;
  text-align: left;
}

.custom-table table thead {
  width: 100%;
}

.custom-table table thead tr {
  background-color: transparent;
  width: 100%;
}

.custom-table table thead th {
  font-family: "public_sans-semibold", sans-serif;
  letter-spacing: 0.9px;
  text-transform: uppercase;
  font-size: 18px;
  font-weight: 600;
  padding: 30px 10px;
  line-height: 1.2;
}

.custom-table table tbody {
  width: 100%;
}

.custom-table table tbody tr {
  background-color: #fff;
  width: 100%;
}

.custom-table table tbody tr:nth-child(even) {
  background-color: transparent;
}

.custom-table table tbody td {
  font-size: 16px;
  font-weight: 400;
  padding: 30px 10px;
  line-height: 1.2;
  position: relative;
}

.custom-table table tbody td:first-child {
  border-right: 3px solid #f3f3f3;
  font-family: "public_sans-semibold", sans-serif;
}

@media (min-width: 769px) {
  .custom-table table tbody td:first-child {
    width: 25%;
  }
}

.custom-table table tbody td:nth-child(2) {
  font-family: "merriweather-bold", serif;
}

.custom-table table tbody td .button {
  font-size: 13px;
  border-radius: 50px;
  padding: 12px 20px;
  max-width: 310px;
  line-height: 1.3;
  word-break: break-word;
}

@media screen and (max-width: 768px) {
  .custom-table table {
    border: 0;
  }
  .custom-table table thead {
    border: none;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }
  .custom-table table tr {
    border-bottom: 3px solid #e4e5f3;
    display: block;
  }
  .custom-table table tbody td {
    border-bottom: 1px solid #e4e5f3;
    display: block;
    font-size: 16px;
    text-align: right;
    padding: 14px 16px 12px;
    padding-left: 70px;
  }
  .custom-table table tbody td::before {
    content: attr(data-label);
    font-family: "public_sans-semibold", sans-serif;
    float: left;
    font-weight: bold;
    text-transform: uppercase;
    position: absolute;
    left: 10px;
  }
  .custom-table table tbody td:not(:last-child) {
    border-right: 0;
  }
  .custom-table table tbody td:last-child {
    border-bottom: 0;
  }
  .custom-table table .file-size {
    display: block;
    margin-top: 10px;
  }
}

.site-footer {
  background-color: #1E242F;
  border-top: 5px solid #A0B426;
  padding: 30px 0;
  color: #fff;
  font-family: "public_sans-medium", sans-serif;
  font-size: 15px;
}

.site-footer__row {
  display: flex;
  flex-wrap: wrap;
  padding: 0 20px;
  justify-content: center;
  text-align: center;
}

@media only screen and (min-width: 640px) {
  .site-footer__row {
    text-align: left;
    justify-content: space-between;
    padding: 0 30px;
  }
}

@media only screen and (min-width: 640px) {
  .site-footer__columns {
    flex: 0 1 43%;
    width: 100%;
  }
}

@media only screen and (min-width: 1024px) {
  .site-footer__columns {
    flex-basis: auto;
    width: auto;
  }
}

.site-footer__columns:nth-child(2) {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

@media only screen and (min-width: 640px) {
  .site-footer__columns:nth-child(2) {
    flex: 1;
    align-content: baseline;
    margin-left: 40px;
  }
}

@media only screen and (min-width: 768px) {
  .site-footer__columns:nth-child(2) {
    flex-wrap: wrap;
  }
}

@media only screen and (min-width: 640px) {
  .site-footer__columns--util {
    text-align: center;
    flex: 0 1 100%;
  }
}

@media only screen and (min-width: 768px) {
  .site-footer__columns--util {
    display: flex;
    flex-flow: column wrap;
    height: 150px;
  }
}

@media only screen and (min-width: 1024px) {
  .site-footer__columns--util {
    margin-top: 40px;
  }
}

@media only screen and (min-width: 1440px) {
  .site-footer__columns--util {
    display: block;
    height: auto;
    margin-top: 8px;
    max-width: 350px;
  }
}

.site-footer__address {
  margin-bottom: 46px;
}

.site-footer__menu {
  margin: 0 2vw;
  width: 100%;
}

@media only screen and (min-width: 640px) {
  .site-footer__menu {
    flex: 0 1 auto;
  }
}

@media only screen and (min-width: 768px) {
  .site-footer__menu {
    flex-basis: calc(50% - 4vw);
    width: 100%;
  }
}

@media only screen and (min-width: 1024px) {
  .site-footer__menu {
    flex-basis: calc(25% - 4vw);
    margin: 15px 1vw;
  }
}

.site-footer__menu h3 {
  margin: 0 auto 46px;
  width: 100%;
  position: relative;
}

@media only screen and (min-width: 1024px) {
  .site-footer__menu h3 {
    margin-bottom: 26px;
  }
}

.site-footer__menu h3:after {
  content: "";
  position: absolute;
  left: 0;
  bottom: -10px;
  width: 20px;
  height: 3px;
  background-color: #a0b426;
}

@media only screen and (max-width: 639px) {
  .site-footer__menu h3:after {
    left: 50%;
    transform: translateX(-50%);
  }
}

.site-footer__menu nav {
  display: none;
}

@media only screen and (min-width: 1024px) {
  .site-footer__menu nav {
    display: block;
  }
}

.site-footer__menu li {
  margin: 6px 0;
}

.site-footer__utility-menu {
  margin-bottom: 40px;
}

@media only screen and (min-width: 768px) {
  .site-footer__utility-menu {
    text-align: left;
    margin-bottom: 20px;
  }
}

@media only screen and (min-width: 1440px) {
  .site-footer__utility-menu {
    margin-bottom: 45px;
  }
}

.site-footer__utility-menu li {
  margin: 0 5px;
}

.site-footer__utility-menu li:first-child {
  margin-left: 0;
}

.site-footer__utility-menu li:last-child {
  margin-right: 0;
}

.site-footer__social {
  margin-bottom: 45px;
}

@media only screen and (min-width: 768px) {
  .site-footer__social {
    text-align: left;
    margin-bottom: 0;
  }
}

@media only screen and (min-width: 1440px) {
  .site-footer__social {
    margin-bottom: 45px;
  }
}

.site-footer__social li {
  margin: 0 4%;
}

.site-footer__social li:first-child {
  margin-left: 0;
}

.site-footer__social li:last-child {
  margin-right: 0;
}

.site-footer__social li a:hover svg path, .site-footer__social li a:focus svg path {
  fill: #fff;
}

@media only screen and (min-width: 768px) {
  .site-footer__ad {
    flex: 1 0 calc(50% - 40px);
    text-align: right;
    margin-left: 25px;
  }
}

@media only screen and (min-width: 1440px) {
  .site-footer__ad {
    text-align: left;
    margin-left: 0;
  }
}

@media only screen and (min-width: 768px) {
  .site-footer__ad img {
    max-width: 350px;
  }
}

.site-footer .button {
  padding: 12px 20px;
  border-radius: 25px;
  font-size: 13px;
  line-height: 18px;
  margin-bottom: 52px;
}

.site-footer h3 {
  font-size: 16px;
}

.site-footer h3 a {
  font-family: "public_sans-semibold", sans-serif;
  font-size: 16px;
  text-transform: uppercase;
}

.site-footer a {
  color: #fff;
  font-family: "public_sans-medium", sans-serif;
  font-size: 15px;
}

.site-footer a:not(.button):hover, .site-footer a:not(.button):focus {
  color: #A0B426;
}

.site-footer p {
  line-height: 29px;
}

.form-module {
  background-image: linear-gradient(180deg, #ffffff 0%, #e2e2e2 100%);
  padding: 80px 0 60px;
}

.form-module__row {
  display: flex;
  box-sizing: border-box;
  flex: 0 1 auto;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  margin: 0 auto;
  max-width: 1355px;
  justify-content: space-between;
  align-items: center;
}

.form-module__columns {
  box-sizing: border-box;
  flex-basis: 100%;
  max-width: 100%;
  padding: 0 25px;
}

@media only screen and (min-width: 1025px) {
  .form-module__columns {
    box-sizing: border-box;
    flex-basis: 50%;
    max-width: 50%;
    padding: 0 25px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1440px) {
  .form-module__columns {
    padding: 0 35px;
  }
}

@media only screen and (min-width: 1200px) {
  .form-module__columns:first-child {
    box-sizing: border-box;
    flex-basis: 41.66667%;
    max-width: 41.66667%;
    padding: 0 25px;
  }
}

@media only screen and (min-width: 1200px) {
  .form-module__columns:last-child {
    box-sizing: border-box;
    flex-basis: 50%;
    max-width: 50%;
    padding: 0 25px;
  }
}

.form-module input:not([type="checkbox"]):not([type="radio"]):not([type="search"]):not([type="color"]):not([type="button"]):not([type="submit"]):not([type="reset"]) {
  max-width: 100%;
}

@media only screen and (min-width: 960px) {
  .form-module input:not([type="checkbox"]):not([type="radio"]):not([type="search"]):not([type="color"]):not([type="button"]):not([type="submit"]):not([type="reset"]) {
    width: 49.5%;
  }
}

.form-module input[type="checkbox"],
.form-module input[type="radio"] {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  -webkit-clip-path: inset(50%);
  clip-path: inset(50%);
  border: 0;
}

.form-module input[type="checkbox"]-focusable:focus, .form-module input[type="checkbox"]-focusable:active,
.form-module input[type="radio"]-focusable:focus,
.form-module input[type="radio"]-focusable:active {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
  -webkit-clip-path: none;
  clip-path: none;
}

.form-module input[type="checkbox"] + label,
.form-module input[type="radio"] + label {
  position: relative;
  font-size: 16px;
}

.form-module input[type="checkbox"] + label::before,
.form-module input[type="radio"] + label::before {
  content: '';
  display: inline-block;
  width: 20px;
  height: 20px;
  border: 1px solid #a4a4a4;
  background-color: #ffffff;
  margin-right: 5px;
  position: relative;
  top: 3px;
  transition: border-color .25s ease;
}

.form-module input[type="checkbox"]:checked + label::before,
.form-module input[type="radio"]:checked + label::before {
  background: #FFF url(../img/checkbox.svg) no-repeat 50% 50%;
}

.form-module input[type="checkbox"]:focus + label::before,
.form-module input[type="radio"]:focus + label::before {
  outline-style: auto;
  outline-color: blue;
  outline-width: 5px;
  outline-color: -webkit-focus-ring-color;
  border-color: #A0B426;
}

.form-module input[type="checkbox"]:disabled + label::before,
.form-module input[type="radio"]:disabled + label::before {
  cursor: default;
  opacity: .75;
}

.form-module input[type="radio"] + label::before {
  border-radius: 180%;
}

.form-module input[type="radio"]:checked + label::before {
  background: #FFF url(../img/radio.svg) no-repeat 50% 50%;
}

.form-module .fieldgroup {
  padding: 0;
  border-width: 0;
}

.form-module label,
.form-module .fieldset-legend {
  font-family: "public_sans-medium", sans-serif;
  font-size: 18px;
}

.form-module select {
  margin-bottom: 16px;
}

.webform-flex legend {
  margin: 0;
  padding: 0;
}

.webform-flex label {
  display: block;
}

.webform-flex .form-checkbox + label,
.webform-flex .form-radio + label {
  display: inline-block;
  vertical-align: middle;
}

.webform-flex input:not([type="checkbox"]):not([type="radio"]):not([type="search"]):not([type="color"]):not([type="button"]):not([type="submit"]):not([type="reset"]),
.webform-flex select {
  width: 100%;
}

@media (min-width: 768px) {
  .webform-flex--container .webform-flex--container {
    margin: 0;
  }
}

.keyboard--focus .form-module input[type="checkbox"]:focus + label::before,
.keyboard--focus .form-module [type="radio"]:focus + label::before {
  outline: 1px dotted #171717;
}

.grid {
  padding: 70px 0 65px;
}

.grid__row:first-child, .grid__row:nth-child(3) {
  display: flex;
  box-sizing: border-box;
  flex: 0 1 auto;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  margin: 0 auto;
  max-width: 1100px;
}

.grid__row:nth-child(2) {
  display: flex;
  box-sizing: border-box;
  flex: 0 1 auto;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  margin: 0 auto;
  max-width: 1355px;
}

.grid__row:nth-child(3) {
  justify-content: center;
}

.grid__column {
  box-sizing: border-box;
  flex-basis: 100%;
  max-width: 100%;
  padding: 0 25px;
}

@media screen and (min-width: 768px) and (max-width: 1440px) {
  .grid__column {
    padding: 0 35px;
  }
}

.grid__columns {
  box-sizing: border-box;
  flex-basis: 50%;
  max-width: 50%;
  padding: 0 25px;
}

@media only screen and (min-width: 1025px) {
  .grid__columns {
    box-sizing: border-box;
    flex-basis: 33.33333%;
    max-width: 33.33333%;
    padding: 0 25px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1440px) {
  .grid__columns {
    padding: 0 35px;
  }
}

@media (max-width: 767px) {
  .grid__filters {
    margin-bottom: 30px;
  }
}

.grid__filters input,
.grid__filters select {
  width: 100%;
  padding: 25px;
  border-radius: 5px;
  border: 1px solid #bababa;
  background-color: transparent;
  color: #171717;
  font-family: "public_sans-semibold", sans-serif;
  font-size: 14px;
}

.grid__filters input:focus,
.grid__filters select:focus {
  outline: 0;
}

.grid__filters input {
  max-width: 415px;
  margin-right: 20px;
  margin-bottom: 50px;
}

.grid__filters input:not([type="checkbox"]):not([type="radio"]):not([type="search"]):not([type="color"]):not([type="button"]):not([type="submit"]):not([type="reset"]) {
  border: 1px solid #bababa;
  border-radius: 5px;
  color: #171717;
  font-size: 16px;
  font-weight: 600;
  padding: 15px 40px 15px 20px;
  background-color: #fff;
  background: url(../img/search-icon.svg) no-repeat 95% 50%;
}

@media (max-width: 768px) {
  .grid__filters input:not([type="checkbox"]):not([type="radio"]):not([type="search"]):not([type="color"]):not([type="button"]):not([type="submit"]):not([type="reset"]) {
    max-width: 100%;
  }
}

.grid__filters input::placeholder {
  color: #171717;
}

.grid__filters .select-wrapper {
  position: relative;
  width: 100%;
  display: inline-block;
  margin-bottom: 15px;
}

@media (min-width: 640px) {
  .grid__filters .select-wrapper {
    max-width: calc(50% - 20px);
    margin-right: 35px;
  }
  .grid__filters .select-wrapper:last-child {
    margin-right: 0;
  }
}

@media (min-width: 768px) {
  .grid__filters .select-wrapper {
    margin-bottom: 50px;
  }
}

@media (min-width: 769px) {
  .grid__filters .select-wrapper {
    max-width: 290px;
    margin-right: 20px;
  }
}

.grid__filters select {
  appearance: none;
}

.grid h2 {
  font-size: 36px;
}

.grid hr {
  max-width: 50px;
  margin-left: 0;
  background-color: #A0B426;
}

.grid img {
  width: 100%;
  margin-bottom: 16px;
}

.grid a {
  color: inherit;
}

.grid a:hover, .grid a:focus {
  color: #A0B426;
}

.grid h3 {
  font-size: 16px;
  line-height: 1.5;
  margin-bottom: 40px;
}

@media (min-width: 640px) {
  .grid h3 {
    font-size: 22px;
  }
}

@media (min-width: 768px) {
  .grid--title-dash h3::before {
    content: '';
    display: inline-block;
    vertical-align: middle;
    height: 3px;
    width: 20px;
    margin-right: 10px;
    margin-bottom: 4px;
    background-color: #A0B426;
  }
}

.grid--public-sans h3 {
  font-family: "public_sans-semibold", sans-serif;
  font-size: 13px;
  text-transform: uppercase;
}

@media (min-width: 768px) {
  .grid--public-sans h3 {
    font-size: 22px;
  }
}

.grid--alt-row .grid__row:first-child {
  display: flex;
  box-sizing: border-box;
  flex: 0 1 auto;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  margin: 0 auto;
  max-width: 1338px;
}

.site-header {
  display: flex;
  flex: 0 1 auto;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: 0 auto;
  padding: 0 27px;
  background-color: #fff;
}

@media only screen and (min-width: 1025px) {
  .site-header {
    padding: 0 15px 31px;
  }
}

@media (min-width: 1200px) {
  .site-header {
    padding: 0 30px 31px;
  }
}

.site-header__mobile-toggle {
  position: relative;
  top: 0px;
  width: 30px;
  height: 25px;
  cursor: pointer;
  z-index: 1;
}

@media only screen and (min-width: 1025px) {
  .site-header__mobile-toggle {
    display: none;
  }
}

.site-header__mobile-hamburger {
  position: absolute;
  left: -10px;
  top: -10px;
  width: 48px;
  height: 48px;
}

.site-header__mobile-hamburger__line {
  top: 10px;
  left: 10px;
  position: absolute;
  width: 30px;
  height: 4px;
  transition-timing-function: ease;
  transition-duration: 150ms;
  transition-property: transform;
  border-radius: 5px;
  background-color: #004A7E;
}

.site-header__mobile-hamburger__line::before, .site-header__mobile-hamburger__line::after {
  content: '';
  display: block;
  position: absolute;
  width: 30px;
  height: 4px;
  transition-timing-function: ease;
  transition-duration: 150ms;
  transition-property: transform;
  border-radius: 5px;
  background-color: #004A7E;
}

.site-header__mobile-hamburger__line::before {
  top: 10px;
  transition-timing-function: ease;
  transition-duration: 150ms;
  transition-property: transform, opacity;
}

.site-header__mobile-hamburger__line::after {
  top: 20px;
}

.site-header__mobile-hamburger--active .site-header__mobile-hamburger__line {
  transform: translate3d(0, 10px, 0) rotate(45deg);
}

.site-header__mobile-hamburger--active .site-header__mobile-hamburger__line::before {
  transform: rotate(-45deg) translate3d(-5.71429px, -6px, 0);
  opacity: 0;
}

.site-header__mobile-hamburger--active .site-header__mobile-hamburger__line::after {
  transform: translate3d(0, -20px, 0) rotate(-90deg);
}

.site-header__logo {
  margin-top: 10px;
  max-width: 180px;
  width: 100%;
}

@media (min-width: 1024px) {
  .site-header__logo {
    max-width: 125px;
  }
}

@media (min-width: 1300px) {
  .site-header__logo {
    max-width: 150px;
  }
}

.site-header__logo a {
  display: block;
}

.site-header__logo svg {
  height: auto;
  max-width: 222px;
  width: 100%;
}

.site-header__menu {
  position: fixed;
  top: 0;
  left: -102%;
  background-color: #fff;
  padding: 30px 0 0;
  height: 100%;
  display: block;
  width: 100%;
  max-width: 85%;
  transition: left 400ms ease-in-out;
  box-shadow: 0 2px 64px rgba(0, 0, 0, 0.5);
  overflow-y: scroll;
  z-index: 10;
}

@media only screen and (min-width: 1025px) {
  .site-header__menu {
    position: relative;
    align-items: flex-start;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    height: auto;
    padding: 0;
    left: 0;
    box-shadow: 0 0 0 0 transparent;
    max-width: calc(100% - 125px);
    overflow: visible;
    padding-left: 20px;
  }
}

@media (min-width: 1300px) {
  .site-header__menu {
    max-width: calc(100% - 150px);
  }
}

.site-header__menu .site-header__mobile-toggle {
  left: 29px;
  margin-bottom: 20px;
}

.site-header__menu .landing-link a::after {
  content: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMyIgaGVpZ2h0PSIxNSIgdmlld0JveD0iMCAwIDEzIDE1Ij48Zz48Zz48cGF0aCBmaWxsPSIjOGI5YzIxIiBkPSJNOCA3LjVsLS41NS42MzZMMS41MSAxNSAwIDEzLjcyOCA1LjM5MyA3LjUgMCAxLjI3MiAxLjUwOSAwIDcuNDUgNi44NjQgOCA3LjV6Ii8+PC9nPjxnPjxwYXRoIGZpbGw9IiM4YjljMjEiIGQ9Ik0xMyA3LjVsLS41NS42MzZMNi41MSAxNSA1IDEzLjcyOCAxMC4zOTMgNy41IDUgMS4yNzIgNi41MDkgMGw1Ljk0MiA2Ljg2NEwxMyA3LjV6Ii8+PC9nPjwvZz48L3N2Zz4=);
  margin-left: 12px;
  vertical-align: middle;
}

@media only screen and (min-width: 1025px) {
  .site-header__menu .landing-link {
    display: none;
  }
}

.site-header__menu--active {
  left: 0;
}

.site-header__menu--main-menu, .site-header__menu--utility-menu {
  width: 100%;
}

@media only screen and (min-width: 1025px) {
  .site-header__menu--main-menu, .site-header__menu--utility-menu {
    width: auto;
  }
  .site-header__menu--main-menu li, .site-header__menu--utility-menu li {
    display: inline-block;
    vertical-align: middle;
  }
}

.site-header__menu--main-menu {
  border-bottom: 1px solid #b9b9b9;
}

@media only screen and (min-width: 1025px) {
  .site-header__menu--main-menu {
    border-bottom: 0;
  }
}

@media only screen and (min-width: 1025px) {
  .site-header__menu--main-menu > ul > li {
    position: relative;
    padding: 0px .85vw;
  }
  .site-header__menu--main-menu > ul > li::before {
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, 0);
    width: calc(100% - 2.50vw);
    height: 5px;
    background-color: transparent;
  }
  .site-header__menu--main-menu > ul > li:hover::before, .site-header__menu--main-menu > ul > li:focus::before {
    background-color: #A0B426;
  }
  .site-header__menu--main-menu > ul > li:hover > a,
  .site-header__menu--main-menu > ul > li:hover > span, .site-header__menu--main-menu > ul > li:focus > a,
  .site-header__menu--main-menu > ul > li:focus > span {
    opacity: 0.6;
  }
  .site-header__menu--main-menu > ul > li:hover > ul, .site-header__menu--main-menu > ul > li:focus > ul {
    width: 265px;
    height: auto;
    box-shadow: 0px -20px 5px -5px white, 0px 14px 21px 0px rgba(0, 0, 0, 0.15);
  }
}

.site-header__menu--main-menu > ul > li a,
.site-header__menu--main-menu > ul > li span {
  display: block;
  color: #171717;
  font-family: "public_sans-semibold", sans-serif;
  font-size: 19px;
  padding: 12px 0 12px 45px;
}

@media only screen and (min-width: 1025px) {
  .site-header__menu--main-menu > ul > li a,
  .site-header__menu--main-menu > ul > li span {
    font-size: 1.1vw;
    text-transform: uppercase;
    border-top: 5px solid transparent;
    padding: 28px 0 30px;
  }
}

@media only screen and (min-width: 1441px) {
  .site-header__menu--main-menu > ul > li a,
  .site-header__menu--main-menu > ul > li span {
    font-size: 16px;
  }
}

.site-header__menu--main-menu > ul > li ul {
  display: none;
}

@media only screen and (min-width: 1025px) {
  .site-header__menu--main-menu > ul > li ul {
    display: block;
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
    top: 88%;
    height: 0;
    width: 0;
    z-index: 10;
    padding: 8px 0 0;
    border-radius: 6px;
    overflow: hidden;
  }
  .site-header__menu--main-menu > ul > li ul::before {
    content: '';
    position: absolute;
    top: 8px;
    left: 0;
    width: 100%;
    height: 5px;
    background-color: #A0B426;
    border-radius: 6px 6px 0 0;
    z-index: 1;
  }
  .site-header__menu--main-menu > ul > li ul::after {
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, 0);
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-bottom: 8px solid #A0B426;
  }
}

.site-header__menu--main-menu > ul > li ul li {
  display: block;
  background-color: transparent;
}

@media only screen and (min-width: 1025px) {
  .site-header__menu--main-menu > ul > li ul li {
    padding: 0;
    background-color: #fff;
  }
  .site-header__menu--main-menu > ul > li ul li:not(:last-child) {
    border-bottom: 1px solid #d1d3d7;
  }
  .site-header__menu--main-menu > ul > li ul li:first-child {
    border-radius: 6px 6px 0 0;
    overflow: hidden;
  }
  .site-header__menu--main-menu > ul > li ul li:first-child > a {
    padding-top: 24px;
  }
}

.site-header__menu--main-menu > ul > li ul li a {
  font-size: 17px;
}

@media only screen and (min-width: 1025px) {
  .site-header__menu--main-menu > ul > li ul li a {
    font-size: 16px;
    padding: 20px 48px;
    line-height: 1.2;
    transition: none;
  }
  .site-header__menu--main-menu > ul > li ul li a:hover, .site-header__menu--main-menu > ul > li ul li a:focus {
    background-color: #A0B426;
    color: #fff;
  }
}

@media only screen and (max-width: 1024px) {
  .site-header__menu--main-menu > ul li.has-children {
    position: relative;
  }
  .site-header__menu--main-menu > ul li.has-children > a {
    position: relative;
  }
  .site-header__menu--main-menu > ul li.has-children > a::before {
    content: '';
    position: absolute;
    top: 23px;
    left: 20px;
    border-style: solid;
    height: 0;
    width: 0;
    border-color: transparent transparent transparent #8c8c8c;
    border-width: 6px 0 6px 6px;
  }
  .site-header__menu--main-menu > ul li.has-children.active {
    background-color: #e8e8e8;
  }
  .site-header__menu--main-menu > ul li.has-children.active > a::before {
    top: 26px;
    border-style: solid;
    height: 0;
    width: 0;
    border-color: #8B9C21 transparent transparent;
    border-width: 6px 6px 0;
  }
  .site-header__menu--main-menu > ul li.has-children.active > ul {
    display: block;
  }
  .site-header__menu--main-menu > ul li.has-children .has-children > a::before {
    top: 16px;
    left: 20px;
  }
  .site-header__menu--main-menu > ul li.has-children .has-children.active {
    background-color: #fff;
  }
  .site-header__menu--main-menu > ul li.has-children .has-children.active > a::before {
    top: 19px;
  }
  .site-header__menu--main-menu > ul li li {
    margin-left: 20px;
    padding: 8px 0;
  }
  .site-header__menu--main-menu > ul li li a {
    padding: 10px 20px 10px 45px;
    line-height: 23px;
  }
  .site-header__menu--main-menu > ul li li li {
    margin-left: 0;
    padding-right: 40px;
  }
  .site-header__menu--main-menu > ul li li li a {
    line-height: 23px;
  }
}

.site-header__menu--utility-menu {
  padding: 0 0 0 45px;
}

@media only screen and (min-width: 1025px) {
  .site-header__menu--utility-menu {
    padding: 0;
  }
}

.site-header__menu--utility-menu li {
  padding: 12px 0;
}

@media only screen and (min-width: 1025px) {
  .site-header__menu--utility-menu li {
    padding: 0;
  }
  .site-header__menu--utility-menu li:not(:first-child):not(:last-child) {
    padding: 0 0.833vw;
  }
  .site-header__menu--utility-menu li:first-child {
    padding-right: 0.833vw;
  }
  .site-header__menu--utility-menu li:last-child {
    padding-left: 0.833vw;
  }
}

.site-header__menu--utility-menu li a {
  color: #171717;
  font-family: "public_sans-medium", sans-serif;
  font-size: 16px;
}

@media only screen and (min-width: 1025px) {
  .site-header__menu--utility-menu li a {
    font-size: 1vw;
  }
}

@media only screen and (min-width: 1441px) {
  .site-header__menu--utility-menu li a {
    font-size: 15px;
  }
}

.site-header__menu--utility-menu li a:hover, .site-header__menu--utility-menu li a:focus {
  opacity: .6;
}

.site-header__menu--utility-menu li .search-toggle {
  display: none;
}

@media only screen and (min-width: 1025px) {
  .site-header__menu--utility-menu li .search-toggle {
    display: block;
  }
}

@media only screen and (min-width: 1025px) {
  .site-header__mobile-search {
    display: none;
  }
}

.site-header__mobile-search .search-toggle {
  padding: 10px 0;
}

.site-header__search-container {
  position: absolute;
  display: flex;
  top: 0;
  right: 0;
  width: 0;
  height: 88px;
  padding: 15px 0;
  overflow: hidden;
  transition: width 300ms ease-in-out, padding-left 300ms ease-in-out, padding-right 300ms ease-in-out;
  justify-content: space-between;
  background-color: #fff;
  z-index: 11;
}

@media only screen and (min-width: 1025px) {
  .site-header__search-container {
    height: 116px;
    padding: 30px 0;
  }
}

.site-header__search-container::before {
  content: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI0OSIgaGVpZ2h0PSI0OSIgdmlld0JveD0iMCAwIDQ5IDQ5Ij48Zz48Zz48cGF0aCBmaWxsPSIjY2NjIiBkPSJNMjEuNDMzIDYuMTI1YzguNDA1IDAgMTUuMzA3IDYuODQ3IDE1LjMwNyAxNS4zMDcgMCA4LjQ2MS02LjkwMiAxNS4zMDgtMTUuMzA3IDE1LjMwOC04LjQwNiAwLTE1LjMwOC02LjkwMi0xNS4zMDgtMTUuMzA4IDAtOC40MDUgNi44NDctMTUuMzA3IDE1LjMwOC0xNS4zMDd6bS0uMDY3IDM2LjU1OGM0Ljg0MyAwIDkuMzQ0LTEuNjUgMTIuOTM0LTQuMzgybDkuODU3IDkuODQ1Yy41Ny41NyAxLjMxLjg1NCAxLjk5NC44NTQuNjg0IDAgMS40ODItLjI4NSAxLjk5NC0uODU0IDEuMTQtMS4xMzggMS4xNC0yLjkwMiAwLTQuMDRsLTkuOC05Ljg0NmMyLjczNS0zLjU4NSA0LjM4OC04LjA4MSA0LjM4OC0xMi45MTlDNDIuNzMzIDkuNTYxIDMzLjE2IDAgMjEuMzY2IDBTMCA5LjU2MSAwIDIxLjM0MWMwIDExLjc4IDkuNTcyIDIxLjM0MiAyMS4zNjYgMjEuMzQyeiIvPjwvZz48L2c+PC9zdmc+);
  margin-left: auto;
  margin-right: 3%;
  vertical-align: middle;
}

.site-header__search-container.active {
  width: 100%;
  border-bottom: 5px solid #e8e8e8;
  padding: 15px 26px;
}

@media only screen and (min-width: 1025px) {
  .site-header__search-container.active {
    padding: 30px;
  }
}

.site-header__search-container input {
  width: 100%;
  max-width: 1140px;
  margin-right: auto;
  height: 100%;
  border: 0;
  font-size: 23px;
  font-family: "public_sans-semibold", sans-serif;
}

.site-header__search-container input:focus {
  outline: 0;
}

.site-header--homepage {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10;
  background-color: transparent;
}

.site-header--homepage .site-header__mobile-hamburger {
  background-color: #fff;
}

.site-header--homepage .site-header__mobile-hamburger::before, .site-header--homepage .site-header__mobile-hamburger::after {
  background-color: #fff;
}

.site-header--homepage .site-header__mobile-search path {
  fill: #fff;
}

@media only screen and (min-width: 1024px) {
  .site-header--homepage .site-header__menu {
    background-color: transparent;
  }
  .site-header--homepage .site-header__menu--main-menu > ul > li > a,
  .site-header--homepage .site-header__menu--main-menu > ul > li > span {
    color: #fff;
  }
  .site-header--homepage .site-header__menu--main-menu > ul > li:hover > ul, .site-header--homepage .site-header__menu--main-menu > ul > li:focus > ul {
    box-shadow: 0 0 0 0 transparent;
  }
  .site-header--homepage .site-header__menu--utility-menu li a {
    color: #fff;
  }
  .site-header--homepage .site-header__menu--utility-menu li .search-toggle path {
    fill: #fff;
  }
}

.hero {
  font-size: 16px;
  background-image: linear-gradient(to bottom, #004a7e 0%, #1e242f 65%, #1e242f 100%);
  color: #fff;
}

.hero .highlight {
  color: #A0B426;
}

.hero__container {
  display: flex;
  box-sizing: border-box;
  flex: 0 1 auto;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  margin: 0 auto;
  max-width: 1600px;
  justify-content: center;
}

.hero__image-container {
  position: relative;
  margin-top: 0px;
  overflow: hidden;
  width: 100%;
  max-width: 1446px;
}

@media (max-width: 767px) {
  .hero__image-container {
    height: 433px;
  }
}

@media only screen and (min-width: 1025px) {
  .hero__image-container {
    margin-top: -30px;
  }
}

.hero__image-container::after {
  content: '';
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 329px;
  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #1E242F 100%);
}

.hero__image-container[style*="background-image"] {
  background-size: contain;
  background-attachment: fixed;
  background-position: 100% 0;
  background-repeat: no-repeat;
}

.hero__image-container[style*="background-image"] img {
  opacity: 0;
}

.hero__image-container img {
  max-width: none;
  position: relative;
  left: 50%;
  transform: translate(-50%, 0);
  transform-origin: top;
  height: 433px;
  width: auto;
}

@media (min-width: 768px) {
  .hero__image-container img {
    width: 100%;
    height: auto;
  }
}

.hero__content-container {
  box-sizing: border-box;
  flex-basis: 100%;
  max-width: 100%;
  padding: 0 25px;
  max-width: 1326px;
  margin-top: -2.3rem;
  margin-bottom: 50px;
  z-index: 1;
  position: relative;
}

@media screen and (min-width: 768px) and (max-width: 1440px) {
  .hero__content-container {
    padding: 0 35px;
  }
}

.hero__content-container h1 {
  color: #fff;
  font-size: 2.125em;
}

@media only screen and (min-width: 1025px) {
  .hero__content-container h1 {
    font-size: 3.4375em;
  }
}

.hero__all {
  margin-bottom: 10px;
}

.hero__all a {
  color: inherit;
  font-family: "public_sans-semibold", sans-serif;
  font-size: 0.9375em;
  letter-spacing: 1.5px;
  text-transform: uppercase;
}

.hero__all a:hover, .hero__all a:focus {
  color: #A0B426;
}

.hero__all a svg {
  position: relative;
  top: -2px;
  vertical-align: middle;
  margin-right: 15px;
}

.hero__image-caption {
  color: #6a6a6a;
  font-family: "public_sans-medium", sans-serif;
  font-size: 13px;
  font-weight: 400;
  line-height: 27px;
}

@media only screen and (min-width: 768px) {
  .hero__image-caption {
    text-align: right;
  }
}

.hero__content {
  color: #fff;
  font-size: 27px;
  line-height: 43px;
}

.hero hr {
  max-width: 50px;
  margin: 5px 0 20px;
  background-color: #A0B426;
}

.hero .social-media-icons ul {
  display: flex;
  flex-wrap: wrap;
}

.hero .social-media-icons li {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  width: 56px;
  height: 56px;
  text-align: center;
  margin: 0 14px;
}

.hero .social-media-icons li:first-child {
  margin-left: 0;
}

.hero .social-media-icons li:last-child {
  margin-right: 0;
}

.hero .social-media-icons li a {
  display: inline-flex;
  line-height: 1;
}

.hero .social-media-icons li a:hover svg path, .hero .social-media-icons li a:focus svg path {
  fill: #A0B426;
}

.hero--simple {
  background-image: none;
  color: #171717;
}

@media screen and (max-width: 1025px) {
  .hero--simple {
    margin-top: 30px;
  }
}

.hero--simple .hero__container {
  display: flex;
  box-sizing: border-box;
  flex: 0 1 auto;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  margin: 0 auto;
  max-width: 1100px;
}

.hero--simple .hero__content-container {
  margin-top: -5px;
  margin-bottom: 0;
  max-width: none;
  padding: 0 25px;
}

@media screen and (min-width: 768px) and (max-width: 1440px) {
  .hero--simple .hero__content-container {
    padding: 0 35px;
  }
}

.hero--simple .hero__content-container h1 {
  color: #171717;
}

.hero--simple .hero__content-container h1::after {
  content: '';
  display: block;
  width: 50px;
  height: 3px;
  background-color: #A0B426;
  margin-top: 2px;
}

.hero--simple + .views-element-container .sectionnav--white-bg {
  margin-top: 40px;
}

.hero--margin-bottom {
  margin-bottom: 50px;
}

.hero--alt-width .hero__container {
  display: flex;
  box-sizing: border-box;
  flex: 0 1 auto;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  margin: 0 auto;
  max-width: 1340px;
}

.hero--social .hero__content-container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

input:not([type="checkbox"]):not([type="radio"]):not([type="search"]):not([type="color"]):not([type="button"]):not([type="submit"]):not([type="reset"]) {
  font-family: "public_sans-semibold", sans-serif;
  background-color: #fff;
  border: 1px solid #bababa;
  border-radius: 5px;
  font-size: 14px;
  margin-bottom: 16px;
  padding: 25px 20px;
  max-width: 360px;
  width: 100%;
  color: #171717;
}

input:not([type="checkbox"]):not([type="radio"]):not([type="search"]):not([type="color"]):not([type="button"]):not([type="submit"]):not([type="reset"]):focus {
  border: 1px solid #A0B426;
  outline: 0;
}

input:not([type="checkbox"]):not([type="radio"]):not([type="search"]):not([type="color"]):not([type="button"]):not([type="submit"]):not([type="reset"])::placeholder {
  color: #171717;
}

input[type="checkbox"] {
  background-color: #fff;
  border-radius: 4px;
  border: 2px solid #bababa;
  vertical-align: middle;
}

textarea {
  background-color: #fff;
  border: 1px solid #bababa;
  border-radius: 5px;
  font-size: 14px;
  margin-bottom: 16px;
  padding: 25px 20px;
  width: 100%;
  color: #171717;
}

textarea:focus {
  border: 1px solid #A0B426;
  outline: 0;
}

textarea::placeholder {
  color: #171717;
}

select:not([multiple]) {
  border: 1px solid #bababa;
  border-radius: 5px;
  color: #171717;
  font-size: 16px;
  font-weight: 600;
  padding: 15px 40px 15px 20px;
  background-color: #fff;
  appearance: none;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA0AAAAHCAMAAADkrjcoAAAAFVBMVEUAAACfsyahtSafryChtCagtCaftCbTrD/KAAAAB3RSTlMAUH8Q0P+gugqphAAAACZJREFUeNpFx7cNAAAIA7BQ/z8Z0b0ZxI8gaksF2U/20p1sK1fvBDQJAOjtDFxVAAAAAElFTkSuQmCC);
  background-repeat: no-repeat;
  background-position: 90% 50%;
  background-size: 12px 6px;
}

.load-more__button {
  position: absolute;
  left: 50%;
  bottom: -25px;
  transform: translate(-50%, 0);
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 1.33px;
  text-transform: uppercase;
  color: #5d6b03;
  border-radius: 35px;
  background-color: #fff;
  padding: 15px 30px;
  border: 1px solid #b9b9b9;
}

.load-more__button::before {
  content: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNSIgaGVpZ2h0PSIxNSIgdmlld0JveD0iMCAwIDE1IDE1Ij48Zz48ZyB0cmFuc2Zvcm09InJvdGF0ZSgtNDUgNy41IDcuNSkiPjxwYXRoIGZpbGw9IiM1ZDZiMDMiIGQ9Ik04LjY3MyA3LjVsNC4wODQtNC4wODRhLjgzLjgzIDAgMCAwLTEuMTczLTEuMTczTDcuNSA2LjMyNyAzLjQxNiAyLjI0M2EuODMuODMgMCAwIDAtMS4xNzMgMS4xNzNMNi4zMjcgNy41bC00LjA4NCA0LjA4NGEuODMuODMgMCAwIDAgMS4xNzMgMS4xNzNMNy41IDguNjczbDQuMDg0IDQuMDg0YS44MjguODI4IDAgMSAwIDEuMTczLTEuMTczTDguNjczIDcuNSIvPjwvZz48L2c+PC9zdmc+);
  display: inline-block;
  margin-right: 12px;
}

.load-more__button--loaded::before {
  content: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNSIgaGVpZ2h0PSIxNSIgdmlld0JveD0iMCAwIDE1IDE1Ij48Zz48ZyB0cmFuc2Zvcm09InJvdGF0ZSgtNDUgNy41IDcuNSkiPjxwYXRoIGZpbGw9IiM1ZDZiMDMiIGQ9Ik04LjcsNy41TDMuNCwyLjJDMy4xLDEuOSwyLjYsMiwyLjIsMi4zYy0wLjMsMC4zLTAuMywwLjgsMCwxLjFsOS4zLDkuM2MwLjMsMC4zLDAuOCwwLjMsMS4yLDBzMC4zLTAuOCwwLTEuMkw4LjcsNy41Ii8+PC9nPjwvZz48L3N2Zz4=);
}

.load-more__button:hover, .load-more__button:focus {
  border-color: #A0B426;
  color: #A0B426;
}

[data-load-more] {
  position: relative;
  z-index: 2;
}

[data-load-more] ~ [data-load-more] {
  z-index: 1;
}

.user-login-form {
  margin: 100px;
}

.user-login-form label {
  display: block;
  font-weight: bold;
}

.user-login-form .description {
  margin-bottom: 20px;
}

@media (max-width: 1023px) {
  #mapbox-container {
    display: none;
  }
}

.map {
  position: relative;
  max-width: 1660px;
  margin: auto auto 50px;
  width: 100%;
  margin-top: 104px;
  padding-bottom: 45px;
}

.map::before {
  content: '';
  display: block;
  width: 200vw;
  background: #ECEFF4;
  position: absolute;
  height: 100%;
  left: -50%;
  top: 0;
  bottom: 0;
  right: 0;
}

.map__wrap {
  display: flex;
  position: relative;
}

.map__listing {
  width: 307px;
  height: 758px;
  overflow: hidden;
  overflow-y: auto;
}

.map__listing__empty {
  padding-top: 100px;
  text-align: center;
}

.map__listing__empty__text {
  font-family: "merriweather", serif;
  font-size: 16px;
  color: #171717;
  letter-spacing: 0;
  text-align: center;
  line-height: 30px;
  padding: 0px 70px;
}

.map-projects {
  flex: 0 1 307px;
  padding: 55px 40px 30px;
  position: relative;
}

.map-projects::before {
  content: '';
  display: block;
  background: #bec7cf;
  height: 1px;
  width: calc(100% - 70px);
  left: 20px;
  position: absolute;
  bottom: 0;
}

.map-projects::after {
  content: '';
  display: block;
  width: calc(100% - 70px);
  left: 20px;
  height: 20px;
  position: absolute;
  bottom: -20px;
  background: linear-gradient(to bottom, #eceff4 0%, rgba(236, 239, 244, 0.53) 47%, rgba(125, 185, 232, 0) 100%);
  z-index: 2;
}

.map-projects__title {
  margin-bottom: 0;
}

.map-projects__count {
  color: #2c84ab;
  font-family: "public_sans-semibold", sans-serif;
  font-size: 42px;
  text-transform: uppercase;
  display: block;
  margin-bottom: 9px;
}

.map-projects__text {
  color: #4c4c4c;
  font-family: "public_sans-medium", sans-serif;
  font-size: 15px;
  text-transform: uppercase;
  display: block;
}

.map-controls {
  display: flex;
  position: relative;
  align-items: flex-end;
}

.map-controls__wrap:last-child {
  margin-bottom: 20px;
}

@media (min-width: 1600px) {
  .map-controls__wrap:last-child {
    margin-bottom: 0;
  }
}

.map-controls__wrap--flex {
  display: flex;
}

.map-controls__wrap--flex .map-controls__fieldset {
  margin-right: 15px;
}

.map-controls__wrap--flex .map-controls__fieldset:last-child {
  margin-right: 0;
}

.map-controls__yearflex {
  display: flex;
}

.map-controls__form {
  position: relative;
  display: flex;
  flex: 0 1 calc(100% - 307px);
  padding: 56px 10px 0 0;
  flex-direction: column;
}

@media (min-width: 1600px) {
  .map-controls__form {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
}

.map-controls__fieldset {
  border: none;
  padding: 0;
}

.map-controls__legend, .map-controls__label {
  display: block;
  border: none;
  padding: 0;
  margin: 0 0 5px;
}

.map-controls__select {
  min-width: 150px;
  max-width: 300px;
  width: 300px;
}

.map-controls__select__state {
  width: 200px;
}

.map-controls__select--year {
  width: 75px;
}

.map-controls__years-range {
  display: flex;
  align-items: center;
}

.map-controls__radio {
  position: absolute;
  top: 0;
}

.map-controls__radio input:checked + label {
  border-color: #8B9C21;
}

.map-controls__radio input:checked + label:hover, .map-controls__radio input:checked + label:focus {
  border-color: #8B9C21;
}

.map-controls__radio label {
  color: #171717;
  font-family: "public_sans-semibold", sans-serif;
  font-size: 12px;
  font-weight: 600;
  margin-right: 25px;
  border-top: 4px solid transparent;
  padding-top: 10px;
  transition: all .25s ease-in;
}

.map-controls__radio label:hover, .map-controls__radio label:focus {
  border-color: #171717;
}

.map-range-hyphen {
  color: #4c4c4c;
  font-family: "public_sans-semibold", sans-serif;
  font-size: 13px;
  margin: 0 5px;
}

.mapbox {
  transition: all .25s ease;
  height: 758px;
  width: calc(100% - 306px);
  background: #EEE;
  border: 1px solid #979797;
}

.project {
  padding: 0px 20px;
  position: relative;
  overflow: hidden;
}

.project::after {
  content: '';
  display: block;
  border-bottom: 2px dashed #bec7cf;
  width: calc(100% - 20px);
  padding-top: 25px;
}

.project__year {
  display: block;
  opacity: 0.8;
  color: #000000;
  font-family: "public_sans-semibold", sans-serif;
  font-size: 11px;
  letter-spacing: 1.44px;
  padding-top: 13px;
}

.project__title {
  margin: 0;
  line-height: 1;
  font-size: 1rem;
}

.project__toggle {
  color: #000000;
  font-family: "merriweather-bold", serif;
  font-size: 15px;
  line-height: 1.733333333;
  background: none;
  text-align: left;
  position: relative;
  padding: 5px 15px 5px 0;
  width: 100%;
  box-sizing: border-box;
}

.project__toggle::after {
  content: '';
  display: block;
  position: absolute;
  top: 10px;
  right: 0px;
  border-style: solid;
  height: 0;
  width: 0;
  border-color: transparent transparent transparent #7c8d10;
  border-width: 6px 0 6px 6px;
  transition: all .25s ease;
}

.project__toggle[aria-expanded="true"]::after {
  transform: rotate(90deg);
}

.project__description {
  display: none;
  position: relative;
}

.project__description::after {
  content: '';
  display: block;
  position: absolute;
  background: transparent;
  width: 6px;
  height: 300%;
  top: -100%;
  left: -20px;
  transition: all .25s ease;
}

.project__description[style*="block;"]::after, .project__description[style*="height"]::after {
  background: #a0b426;
}

.project__description p {
  color: #000000;
  font-family: "merriweather", serif;
  font-size: 13px;
  line-height: 1.538461538;
}

.project__description p.project__amount {
  font-family: "merriweather-bold", serif;
  line-height: 2;
}

.project__description p.project__amount .project__amount__number {
  font-size: 15px;
}

.project__program p {
  color: #000000;
  font-family: "merriweather", serif;
  font-size: 13px;
  line-height: 2;
}

.project__link {
  font-size: 16px;
  font-weight: bold;
  text-decoration: none;
}

.project__pop {
  display: none;
}

.mapboxgl-popup-close-button {
  font-size: 24px;
  margin-right: 5px;
}

.filters,
#goHome {
  background: #000;
  color: #FFF;
  padding: 5px 10px;
  cursor: pointer;
}

.filters:hover,
#goHome:hover {
  background: #FFF;
  color: #000;
}

.mapboxgl-popup-content h2 {
  font-family: "merriweather-bold", serif;
  font-size: 15px;
  color: #000000;
  letter-spacing: 0;
  line-height: 1.733333333;
}

.mapboxgl-popup-content p {
  font-family: "merriweather", serif;
  font-size: 13px;
  color: #000000;
  letter-spacing: 0;
  line-height: 1.538461538;
}

.map__loading {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  background: rgba(30, 36, 47, 0.8);
  pointer-events: none;
  transition: all .25s ease;
}

.map__loading--hide {
  opacity: 0;
}

.select2-results__option {
  font-size: 16px;
  line-height: 1.2;
}

.button--map {
  font-size: 15px;
  padding: 8px 10px;
  margin-top: 10px;
}

.map-animate {
  width: 60px;
  height: 60px;
  border: 3px solid rgba(191, 196, 203, 0.33);
  display: block;
  position: relative;
  border-radius: 180%;
  transition: all .25s ease;
  margin-left: 15px;
}

.map-animate__svg {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.map-animate__svg svg {
  transition: inherit;
  height: 19px;
  fill: #5D6B03;
}

.map-animate__svg__pause {
  display: none;
}

.map-animate__svg__pause svg {
  height: 37px;
  position: relative;
  top: 5px;
}

.map-animate:hover, .map-animate:focus {
  background: #8B9C21;
  border-color: #8B9C21;
}

.map-animate:hover svg, .map-animate:focus svg {
  fill: #FFF;
}

.map-range-hide--hiden {
  display: none;
}

.lds-roller {
  display: inline-block;
  position: relative;
  width: 64px;
  height: 64px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.lds-roller div {
  animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  transform-origin: 32px 32px;
}

.lds-roller div:after {
  content: " ";
  display: block;
  position: absolute;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: #fff;
  margin: -3px 0 0 -3px;
}

.lds-roller div:nth-child(1) {
  animation-delay: -0.036s;
}

.lds-roller div:nth-child(1):after {
  top: 50px;
  left: 50px;
}

.lds-roller div:nth-child(2) {
  animation-delay: -0.072s;
}

.lds-roller div:nth-child(2):after {
  top: 54px;
  left: 45px;
}

.lds-roller div:nth-child(3) {
  animation-delay: -0.108s;
}

.lds-roller div:nth-child(3):after {
  top: 57px;
  left: 39px;
}

.lds-roller div:nth-child(4) {
  animation-delay: -0.144s;
}

.lds-roller div:nth-child(4):after {
  top: 58px;
  left: 32px;
}

.lds-roller div:nth-child(5) {
  animation-delay: -0.18s;
}

.lds-roller div:nth-child(5):after {
  top: 57px;
  left: 25px;
}

.lds-roller div:nth-child(6) {
  animation-delay: -0.216s;
}

.lds-roller div:nth-child(6):after {
  top: 54px;
  left: 19px;
}

.lds-roller div:nth-child(7) {
  animation-delay: -0.252s;
}

.lds-roller div:nth-child(7):after {
  top: 50px;
  left: 14px;
}

.lds-roller div:nth-child(8) {
  animation-delay: -0.288s;
}

.lds-roller div:nth-child(8):after {
  top: 45px;
  left: 10px;
}

@keyframes lds-roller {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

#map .mapboxgl-popup-content {
  background: #FFFFFF;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.7);
}

#map .mapboxgl-popup {
  max-width: 286px !important;
  width: 100%;
}

#map .mapboxgl-image {
  margin: -20px -20px 10px;
  border-radius: 8px 8px 0 0;
  overflow: hidden;
}

#map .mapboxgl-image img {
  display: block;
}

#map .mapboxgl-popup-close-button {
  margin-right: 0;
  border-radius: 0 8px 0 0;
  right: 0px;
  width: 20px;
  transition: all .25s ease;
  background: #FFF;
}

#map .mapboxgl-popup-close-button:hover, #map .mapboxgl-popup-close-button:focus {
  background: #8B9C21;
  color: #FFF;
}

#map .mapboxgl-popup {
  transition: opacity .15s ease;
}

#map .mapboxgl-popup--hide {
  opacity: 0;
}

.offices-list {
  padding: 75px 0 0;
}

.offices-list:not(:last-child) .offices__row:last-child {
  border-bottom: 1px dashed #BFBFBF;
}

.offices-list:last-child .offices__row:last-child {
  margin-bottom: 50px;
}

.offices-list .offices__row {
  display: flex;
  box-sizing: border-box;
  flex: 0 1 auto;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  margin: 0 auto;
  max-width: 1100px;
}

.offices-list .offices__row:last-child {
  padding-bottom: 20px;
}

.offices-list .offices__column, .offices-list .offices__columns {
  box-sizing: border-box;
  flex-basis: 100%;
  max-width: 100%;
  padding: 0 25px;
}

@media screen and (min-width: 768px) and (max-width: 1440px) {
  .offices-list .offices__column, .offices-list .offices__columns {
    padding: 0 35px;
  }
}

.offices-list .offices__columns--two-columns {
  font-family: "public_sans-regular", sans-serif;
  font-size: 18px;
  line-height: 33px;
  color: #4c4c4c;
}

.offices-list .offices__columns--two-columns p {
  margin-bottom: 0;
}

.offices-list .offices__columns--two-columns p ~ p {
  margin-top: 5px;
}

@media only screen and (min-width: 768px) {
  .offices-list .offices__columns--two-columns:first-child:not(:last-child) {
    box-sizing: border-box;
    flex-basis: 50%;
    max-width: 50%;
    padding: 0 25px;
  }
}

@media only screen and (min-width: 1025px) {
  .offices-list .offices__columns--two-columns:first-child:not(:last-child) {
    box-sizing: border-box;
    flex-basis: 33.33333%;
    max-width: 33.33333%;
    padding: 0 25px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1440px) {
  .offices-list .offices__columns--two-columns:first-child:not(:last-child) {
    padding: 0 35px;
  }
}

@media only screen and (min-width: 768px) {
  .offices-list .offices__columns--two-columns:nth-child(2) {
    box-sizing: border-box;
    flex-basis: 50%;
    max-width: 50%;
    padding: 0 25px;
  }
}

@media only screen and (min-width: 1025px) {
  .offices-list .offices__columns--two-columns:nth-child(2) {
    box-sizing: border-box;
    flex-basis: 66.66667%;
    max-width: 66.66667%;
    padding: 0 25px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1440px) {
  .offices-list .offices__columns--two-columns:nth-child(2) {
    padding: 0 35px;
  }
}

.offices-list .offices__columns--three-columns {
  margin-top: 15px;
  margin-bottom: 15px;
}

@media only screen and (min-width: 768px) {
  .offices-list .offices__columns--three-columns {
    box-sizing: border-box;
    flex-basis: 50%;
    max-width: 50%;
    padding: 0 25px;
  }
}

@media only screen and (min-width: 1025px) {
  .offices-list .offices__columns--three-columns {
    box-sizing: border-box;
    flex-basis: 33.33333%;
    max-width: 33.33333%;
    padding: 0 25px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1440px) {
  .offices-list .offices__columns--three-columns {
    padding: 0 35px;
  }
}

.offices-list .offices__columns--three-columns p {
  margin-top: 0;
  font-family: "public_sans-medium", sans-serif;
  font-size: 16px;
  line-height: 1.3;
}

.offices-list .offices__columns--three-columns p a {
  display: inline-block;
  margin-top: 10px;
}

.offices-list .offices__staff-title {
  font-family: "merriweather-bold", serif;
  font-size: 28px;
  line-height: 38px;
  margin-top: 70px;
  margin-bottom: 20px;
}

.offices-list .offices__staff-name {
  font-family: "public_sans-semibold", sans-serif;
  text-transform: uppercase;
  font-size: 18px;
  letter-spacing: 0.9px;
  color: #000;
}

.offices-list h2 {
  font-size: 28px;
  line-height: 37px;
}

@media only screen and (min-width: 768px) {
  .offices-list h2 {
    font-size: 48px;
    line-height: 57px;
  }
}

.offices-list hr {
  margin-bottom: 10px;
}

.offices-list--alt-padding {
  padding-top: 0;
}

.offices-list--alt-padding:last-child .offices__row:last-child {
  margin-bottom: 20px;
}

.overview {
  position: relative;
}

@media only screen and (min-width: 1200px) {
  .overview::before {
    content: 'Overview';
    text-transform: uppercase;
    position: absolute;
    top: 40%;
    transform: rotate(-90deg) translate(0, -50%);
    opacity: 0.2;
    text-transform: uppercase;
    font-family: "bebas-neue", sans-serif;
    font-size: 48px;
    margin-left: -20px;
  }
}

.overview__row {
  display: flex;
  box-sizing: border-box;
  flex: 0 1 auto;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  margin: 0 auto;
  max-width: 1100px;
}

.overview__column {
  box-sizing: border-box;
  flex-basis: 100%;
  max-width: 100%;
  padding: 0 25px;
}

@media screen and (min-width: 768px) and (max-width: 1440px) {
  .overview__column {
    padding: 0 35px;
  }
}

.overview *:first-child::first-letter {
  font-family: "merriweather-bold", serif;
  font-size: 64px;
  text-transform: uppercase;
  vertical-align: top;
  float: left;
  margin: 16px 25px 10px 0;
}

.overview .down-arrow-link {
  display: inline-block;
  margin-top: 25px;
  font-family: "public_sans-semibold", sans-serif;
  font-size: 15px;
  text-transform: uppercase;
}

.overview-partner {
  position: relative;
}

@media only screen and (min-width: 960px) {
  .overview-partner {
    margin-bottom: 60px;
  }
}

@media only screen and (min-width: 1200px) {
  .overview-partner::before {
    content: 'Overview';
    text-transform: uppercase;
    position: absolute;
    top: 40%;
    transform: rotate(-90deg) translate(0, -50%);
    opacity: 0.2;
    text-transform: uppercase;
    font-family: "bebas-neue", sans-serif;
    font-size: 48px;
    margin-left: -20px;
  }
}

@media only screen and (min-width: 960px) {
  .overview-partner__container {
    display: flex;
    max-width: 1100px;
    margin: 0 auto;
  }
}

@media only screen and (min-width: 960px) {
  .overview-partner .overview {
    max-width: 72%;
    padding-right: 40px;
  }
}

@media only screen and (min-width: 1200px) {
  .overview-partner .overview:before {
    display: none;
  }
}

@media only screen and (min-width: 960px) {
  .overview-partner .partners-grid {
    margin-top: 20px;
    border-left: 1px solid #C9C9C9;
  }
}

@media only screen and (min-width: 1200px) {
  .overview-partner .partners-grid {
    margin-left: 5%;
    padding-left: 60px;
  }
}

.overview-partner .partners-grid__columns {
  width: 100%;
}

@media only screen and (min-width: 1200px) {
  .overview-partner .partners-grid__columns {
    padding: 0;
  }
}

@media only screen and (min-width: 960px) {
  .overview-partner .partners-grid h2:before {
    display: none;
  }
  .overview-partner .partners-grid h2:after {
    left: 50%;
    bottom: -10px;
    top: auto;
    right: auto;
    width: 50px;
    height: 2px;
    background-color: #8B9C21;
    transform: translateX(-50%);
  }
}

.pagination {
  margin-top: 20px;
}

@media (max-width: 767px) {
  .pagination {
    margin: 20px 25px 0;
    text-align: center;
  }
}

.pagination li {
  padding: 10px 15px;
  border-bottom: 6px solid transparent;
}

.pagination li.active {
  border-bottom: 6px solid #A0B426;
}

.pagination a {
  font-family: "public_sans-semibold", sans-serif;
  font-size: 18px;
  line-height: 21px;
  color: inherit;
}

.pagination a:hover, .pagination a:focus {
  color: #A0B426;
}

.announcements .pager {
  width: 100%;
  text-align: center;
}

.partners-grid {
  margin-top: 90px;
}

.partners-grid__row {
  display: flex;
  box-sizing: border-box;
  flex: 0 1 auto;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  margin: 0 auto;
  max-width: 1520px;
  overflow: hidden;
  justify-content: center;
}

.partners-grid__column {
  box-sizing: border-box;
  flex-basis: 100%;
  max-width: 100%;
  padding: 0 25px;
  text-align: center;
}

@media screen and (min-width: 768px) and (max-width: 1440px) {
  .partners-grid__column {
    padding: 0 35px;
  }
}

.partners-grid__columns {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
  border-left: 1px solid #d8d8d8;
  padding: 0 30px;
  margin: 45px 0;
}

@media only screen and (max-width: 959px) {
  .partners-grid__columns:nth-child(odd) {
    border-left: 0;
  }
}

@media only screen and (min-width: 960px) {
  .partners-grid__columns {
    width: 33.3333333%;
  }
}

@media only screen and (min-width: 960px) and (max-width: 1023px) {
  .partners-grid__columns:nth-child(3n + 1) {
    border-left: 0;
  }
}

@media only screen and (min-width: 1024px) {
  .partners-grid__columns {
    width: 25%;
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1199px) {
  .partners-grid__columns:nth-child(4n + 1) {
    border-left: 0;
  }
}

@media only screen and (min-width: 1200px) {
  .partners-grid__columns {
    width: 20%;
  }
  .partners-grid__columns:nth-child(5n + 1) {
    border-left: 0;
  }
}

.partners-grid__columns:first-child {
  border-left: 0;
}

.partners-grid h2 {
  position: relative;
  text-align: center;
  font-size: 26px;
  display: inline-block;
  padding: 0 42px;
}

.partners-grid h2::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 100%;
  width: 100vw;
  height: 1px;
  transform: translate(0, -50%);
  background-color: #bfbfbf;
}

.partners-grid h2::after {
  content: '';
  position: absolute;
  top: 50%;
  right: 100%;
  width: 100vw;
  height: 1px;
  transform: translate(0, -50%);
  background-color: #bfbfbf;
}

.related-content {
  padding: 35px 0 55px;
}

@media only screen and (min-width: 768px) {
  .related-content {
    padding: 94px 0 85px;
  }
}

.related-content__row {
  display: flex;
  box-sizing: border-box;
  flex: 0 1 auto;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  margin: 0 auto;
  max-width: 1355px;
}

.related-content__row:nth-child(2) .related-content__column {
  padding-bottom: 15px;
  margin-bottom: 45px;
  width: 100%;
}

@media only screen and (min-width: 768px) {
  .related-content__row:nth-child(2) .related-content__column {
    padding-bottom: 45px;
  }
}

.related-content__row:nth-child(2) .related-content__column:not(:last-child) {
  border-bottom: 2px dashed #9f9f9f;
}

.related-content__column {
  padding: 0 25px;
}

@media (min-width: 768px) {
  .related-content__column {
    display: flex;
    box-sizing: border-box;
    flex-basis: 100%;
    max-width: 100%;
    padding: 0 25px;
  }
  .related-content__column.contextual-region {
    align-items: center;
  }
}

@media screen and (min-width: 768px) and (max-width: 1440px) {
  .related-content__column {
    padding: 0 35px;
  }
}

.related-content__content {
  display: inline-block;
  vertical-align: middle;
}

@media only screen and (min-width: 960px) {
  .related-content__content {
    margin-right: 70px;
  }
}

@media only screen and (min-width: 768px) {
  .related-content__content {
    max-width: 70%;
    padding-right: 5%;
  }
}

.related-content__content ~ img {
  display: inline-block;
  vertical-align: middle;
}

.related-content__cat {
  color: #171717;
  font-family: "public_sans-semibold", sans-serif;
  font-size: 11px;
  line-height: 1;
  font-weight: 600;
  letter-spacing: 1.44px;
  text-transform: uppercase;
  margin-bottom: 2px;
}

.related-content h2 {
  font-size: 26px;
  display: inline-block;
  margin-right: 40px;
  vertical-align: top;
  line-height: 38px;
}

@media only screen and (max-width: 639px) {
  .related-content h2 hr {
    margin-bottom: 0;
  }
}

.related-content h2 ~ a {
  font-family: "public_sans-semibold", sans-serif;
  font-size: 14px;
  letter-spacing: 1.17px;
  text-transform: uppercase;
  vertical-align: top;
}

@media only screen and (max-width: 639px) {
  .related-content h2 ~ a {
    display: block;
    margin-bottom: 20px;
  }
}

.related-content h2 ~ a:hover svg path, .related-content h2 ~ a:focus svg path {
  stroke: #A0B426;
}

.related-content h2 ~ a svg {
  position: relative;
  top: 2px;
  width: 6px;
  margin-left: 10px;
}

.related-content h2 ~ a svg path {
  stroke: #5D6B03;
}

.related-content hr {
  max-width: 30px;
  margin: 8px 0 50px;
  background-color: #A0B426;
}

.related-content h3 {
  font-size: 18px;
  line-height: 31px;
}

@media only screen and (min-width: 768px) {
  .related-content h3 {
    font-size: 28px;
    line-height: 38px;
  }
}

.related-content h3 a {
  color: inherit;
  border-bottom: 2px solid transparent;
}

.related-content h3 a:hover, .related-content h3 a:focus {
  border-color: #A0B426;
}

.related-content h3 svg {
  width: 32px;
  height: 32px;
  margin-left: 24px;
  margin-right: 5px;
  vertical-align: middle;
}

@media only screen and (max-width: 639px) {
  .related-content h3 svg {
    margin-left: 10px;
  }
}

.related-content h3 svg ~ span {
  vertical-align: middle;
  font-family: "public_sans-medium", sans-serif;
  font-size: 15px;
  text-transform: uppercase;
}

.related-content p {
  font-size: 16px;
  line-height: 31px;
  margin-bottom: 0;
}

@media only screen and (min-width: 768px) {
  .related-content p {
    font-size: 18px;
  }
}

.related-content--alt-top-padding {
  padding-top: 30px;
}

.related-content--no-bottom-padding {
  padding-bottom: 0;
}

.related-programs {
  background-color: #1E242F;
  color: #fff;
  padding: 65px 0 90px;
}

.related-programs__row {
  position: relative;
  display: flex;
  box-sizing: border-box;
  flex: 0 1 auto;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  margin: 0 auto;
  max-width: 1355px;
}

.related-programs__column {
  box-sizing: border-box;
  flex-basis: 100%;
  max-width: 100%;
  padding: 0 25px;
}

@media screen and (min-width: 768px) and (max-width: 1440px) {
  .related-programs__column {
    padding: 0 35px;
  }
}

.related-programs a {
  color: inherit;
}

.related-programs a:hover .related-programs__title, .related-programs a:focus .related-programs__title {
  color: #A0B426;
}

.related-programs__columns {
  box-sizing: border-box;
  flex-basis: 100%;
  max-width: 100%;
  padding: 0 25px;
}

@media only screen and (min-width: 1025px) {
  .related-programs__columns {
    box-sizing: border-box;
    flex-basis: 33.33333%;
    max-width: 33.33333%;
    padding: 0 25px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1440px) {
  .related-programs__columns {
    padding: 0 35px;
  }
}

.related-programs__columns.active .related-programs__image-container::before {
  content: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNSIgaGVpZ2h0PSIyNSIgdmlld0JveD0iMCAwIDI1IDI1Ij48Zz48Zz48cGF0aCBmaWxsPSIjYTBiNDI2IiBkPSJNMTUuMTY2IDEyLjVsOS4yODItOS4yODJBMS44ODUgMS44ODUgMCAwIDAgMjEuNzgzLjU1MkwxMi41IDkuODM1IDMuMjE3LjU1MkExLjg4NSAxLjg4NSAwIDAgMCAuNTUyIDMuMjE4TDkuODM0IDEyLjUuNTUyIDIxLjc4M2ExLjg4NSAxLjg4NSAwIDAgMCAyLjY2NSAyLjY2NWw5LjI4My05LjI4MiA5LjI4MyA5LjI4MmExLjg4MiAxLjg4MiAwIDAgMCAyLjY2NiAwIDEuODg1IDEuODg1IDAgMCAwIDAtMi42NjVMMTUuMTY1IDEyLjUiLz48L2c+PC9nPjwvc3ZnPg==);
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(0, -50%);
  width: 100%;
  text-align: center;
  z-index: 1;
}

.related-programs__columns.active .related-programs__image-container::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.75);
}

.related-programs__columns.active .related-programs__title {
  color: #A0B426;
}

.related-programs h2 {
  color: inherit;
  font-size: 1.625em;
}

.related-programs hr {
  max-width: 30px;
  margin: 16px 0 22px;
  background-color: #A0B426;
}

.related-programs__image-container {
  position: relative;
  cursor: pointer;
}

.related-programs__image-container img {
  width: 100%;
}

.related-programs__title {
  position: relative;
  vertical-align: middle;
  color: inherit;
  font-family: "public_sans-semibold", sans-serif;
  font-size: 22px;
  line-height: 29px;
  text-transform: uppercase;
  margin-top: 13px;
  margin-bottom: 30px;
  padding-left: 30px;
  cursor: pointer;
}

.related-programs__title::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(0, -50%);
  width: 3px;
  height: 100%;
  background-color: #A0B426;
}

.related-programs__content {
  position: absolute;
  left: 15px;
  width: calc(100% - 25px);
  padding-top: 100px;
  padding-bottom: 90px;
  display: none;
}

.related-programs__content h3 {
  font-family: "merriweather-bold", serif;
  font-size: 48px;
  line-height: 57px;
}

.related-programs__content p {
  margin-bottom: 50px;
}

.related-programs--no-bg {
  background-color: #fff;
  color: #171717;
  border-bottom: 1px solid #BFBFBF;
}

.related-species {
  color: #171717;
  padding: 65px 0 90px;
}

.related-species__row {
  position: relative;
  display: flex;
  box-sizing: border-box;
  flex: 0 1 auto;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  margin: 0 auto;
  max-width: 1355px;
}

.related-species__column {
  box-sizing: border-box;
  flex-basis: 100%;
  max-width: 100%;
  padding: 0 25px;
}

@media screen and (min-width: 768px) and (max-width: 1440px) {
  .related-species__column {
    padding: 0 35px;
  }
}

.related-species__columns {
  box-sizing: border-box;
  flex-basis: 100%;
  max-width: 100%;
  padding: 0 25px;
}

@media only screen and (min-width: 768px) {
  .related-species__columns {
    box-sizing: border-box;
    flex-basis: 50%;
    max-width: 50%;
    padding: 0 25px;
  }
}

@media only screen and (min-width: 1025px) {
  .related-species__columns {
    box-sizing: border-box;
    flex-basis: 25%;
    max-width: 25%;
    padding: 0 25px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1440px) {
  .related-species__columns {
    padding: 0 35px;
  }
}

.related-species a {
  color: inherit;
}

.related-species a:hover .related-species__title, .related-species a:focus .related-species__title {
  color: #A0B426;
}

.related-species h2 {
  color: inherit;
  font-size: 1.625em;
}

.related-species hr {
  max-width: 30px;
  margin: 16px 0 22px;
  background-color: #A0B426;
}

.related-species__image-container {
  position: relative;
  cursor: pointer;
}

.related-species__image-container img {
  width: 100%;
}

.related-species__title {
  position: relative;
  vertical-align: middle;
  color: inherit;
  font-family: "public_sans-semibold", sans-serif;
  font-size: 18px;
  line-height: 29px;
  text-transform: uppercase;
  margin-top: 13px;
  margin-bottom: 30px;
  padding-left: 30px;
  cursor: pointer;
}

.related-species__title::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(0, -50%);
  width: 20px;
  height: 3px;
  background-color: #A0B426;
}

.related-species__content {
  position: absolute;
  left: 15px;
  width: calc(100% - 25px);
  padding-top: 100px;
  padding-bottom: 90px;
  display: none;
}

.related-species__content h3 {
  font-family: "merriweather-bold", serif;
  font-size: 48px;
  line-height: 57px;
}

.related-species__content p {
  margin-bottom: 50px;
}

.related-species--no-bg {
  background-color: #fff;
  color: #171717;
  border-bottom: 1px solid #BFBFBF;
}

.search-results__filters li {
  padding: 0 15px;
}

.search-results__filters li a {
  font-family: "public_sans-medium", sans-serif;
  font-size: 16px;
  color: #171717;
  border-bottom: 3px solid transparent;
  padding-bottom: 5px;
}

.search-results__filters li:first-child {
  padding-left: 0;
}

.search-results__filters li.active a {
  border-bottom-color: #A0B426;
}

.search-results__filter-list {
  display: none;
}

@media only screen and (min-width: 1025px) {
  .search-results__filter-list {
    display: block;
  }
}

@media only screen and (min-width: 1025px) {
  .search-results__filter-dropdown {
    display: none;
  }
}

.search-results__search-container {
  position: relative;
  max-width: 920px;
  margin-bottom: 30px;
}

.search-results__search-container input {
  width: 100%;
  border-radius: 6px;
  border: 1px solid #cbcbcb;
  background-color: #ffffff;
  padding: 23px 55px 23px 36px;
  font-family: "public_sans-semibold", sans-serif;
  font-size: 23px;
}

.search-results__search-container::after {
  content: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMyIgaGVpZ2h0PSIyMyIgdmlld0JveD0iMCAwIDIzIDIzIj48Zz48Zz48cGF0aCBmaWxsPSIjMDA0YTdlIiBkPSJNMTAuMDU4IDIuODc1YzMuOTQzIDAgNy4xODIgMy4yMTMgNy4xODIgNy4xODMgMCAzLjk3LTMuMjM5IDcuMTgyLTcuMTgyIDcuMTgyLTMuOTQ0IDAtNy4xODMtMy4yMzktNy4xODMtNy4xODIgMC0zLjk0NCAzLjIxMy03LjE4MyA3LjE4My03LjE4M3ptLS4wMjkgMTcuMTZhMTAuMDIgMTAuMDIgMCAwIDAgNi4wNzEtMi4wNTdsNC42MjcgNC42MjFjLjI2Ny4yNjcuNjE1LjQwMS45MzYuNDAxLjMyIDAgLjY5NS0uMTM0LjkzNi0uNGExLjMyMSAxLjMyMSAwIDAgMCAwLTEuODk3bC00LjYtNC42MjJhOS45OTQgOS45OTQgMCAwIDAgMi4wNi02LjA2NEMyMC4wNTggNC40ODcgMTUuNTY0IDAgMTAuMDI4IDBTMCA0LjQ4OCAwIDEwLjAxN2MwIDUuNTMgNC40OTMgMTAuMDE4IDEwLjAzIDEwLjAxOHoiLz48L2c+PC9nPjwvc3ZnPg==);
  position: absolute;
  top: 54%;
  right: 36px;
  transform: translate(0, -50%);
  line-height: 1;
}

.search-results__pagination {
  margin-bottom: 50px;
}

.search-results__pagination .grid__row {
  display: flex;
  box-sizing: border-box;
  flex: 0 1 auto;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  margin: 0 auto;
  max-width: 1355px;
  padding: 0 20px;
}

.glide {
  position: relative;
  width: 100%;
  box-sizing: border-box;
}

.glide * {
  box-sizing: inherit;
}

.glide__track {
  overflow: hidden;
}

.glide__slides {
  position: relative;
  width: 100%;
  list-style: none;
  backface-visibility: hidden;
  transform-style: preserve-3d;
  touch-action: pan-Y;
  overflow: hidden;
  padding: 0;
  white-space: nowrap;
  display: flex;
  flex-wrap: nowrap;
  will-change: transform;
}

.glide__slides--dragging {
  user-select: none;
}

.glide__slide {
  width: 100%;
  height: 100%;
  flex-shrink: 0;
  white-space: normal;
  user-select: none;
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: transparent;
}

.glide__slide a {
  user-select: none;
  -webkit-user-drag: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

.glide__arrows {
  -webkit-touch-callout: none;
  user-select: none;
}

.glide__bullets {
  -webkit-touch-callout: none;
  user-select: none;
}

.glide--rtl {
  direction: rtl;
}

.section-nav {
  background-color: #004A7E;
  color: #fff;
}

.section-nav__container {
  display: flex;
  box-sizing: border-box;
  flex: 0 1 auto;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  margin: 0 auto;
  max-width: 1580px;
  margin: 0 0 0 auto;
  justify-content: center;
  padding-left: 30px;
}

.section-nav__nav {
  box-sizing: border-box;
  flex-basis: 100%;
  max-width: 100%;
  padding: 0 25px;
  padding: 16px 0;
  overflow: hidden;
  display: flex;
}

.section-nav__nav li {
  position: relative;
  line-height: 21px;
  font-size: 16px;
}

.section-nav__nav li:not(:last-child)::after {
  content: '';
  position: absolute;
  top: 0;
  right: -30px;
  height: 100%;
  width: 1px;
  background-color: #fff;
  opacity: 0.24;
}

.section-nav__nav a {
  color: #ffffff;
  font-family: "public_sans-semibold", sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 21px;
}

.section-nav .glide {
  position: relative;
  padding-left: 20px;
  display: inline-block;
  width: calc(100% - 105px);
}

.section-nav .glide__track {
  overflow: visible;
}

.section-nav .glide__slides {
  margin: 0;
  overflow: visible;
}

.section-nav .glide__slide:hover > a {
  color: rgba(0, 0, 0, 0.6);
}

.section-nav .glide__slide:hover .glide__slide-dropdown {
  display: block;
}

.section-nav .glide__slide:focus > a {
  color: rgba(0, 0, 0, 0.6);
}

.section-nav .glide__arrow {
  position: absolute;
  top: 0;
  width: 50px;
  height: 90%;
  background-color: #004A7E;
}

.section-nav .glide__arrow--left {
  display: none;
  left: -8px;
  transform: rotate(180deg);
}

.section-nav .glide__arrow--right {
  right: -9px;
}

.section-nav .glide__slide-dropdown {
  position: absolute;
  display: none;
  top: 100%;
  left: 50%;
  transform: translate(-50%, 0);
  width: 300px;
  background-color: #1E242F;
  z-index: 3;
  color: #fff;
  border-radius: 6px;
  box-shadow: 0 11px 21px rgba(0, 0, 0, 0.15);
}

.section-nav .glide__slide-dropdown::before {
  content: '';
  position: absolute;
  top: -8px;
  left: 50%;
  transform: translate(-50%, 0);
  border-style: solid;
  height: 0;
  width: 0;
  border-color: transparent transparent #1E242F;
  border-width: 0 7px 8px;
}

.section-nav .glide__slide-dropdown ul {
  list-style: none;
  margin: 0;
  padding: 25px 0 75px;
}

.section-nav .glide__slide-dropdown ul li {
  margin: 0;
  padding: 0;
  border-bottom: 1px solid #424a58;
}

.section-nav .glide__slide-dropdown ul li::after {
  display: none;
}

.section-nav .glide__slide-dropdown a {
  position: relative;
  display: block;
  padding: 18px 50px;
  color: #fff;
}

.section-nav .glide__slide-dropdown a:hover, .section-nav .glide__slide-dropdown a:focus {
  color: #a7adb6;
}

.section-nav .glide__slide-dropdown a:hover::before, .section-nav .glide__slide-dropdown a:focus::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 5px;
  height: 100%;
  background-color: #A0B426;
}

.section-nav__nav-item {
  position: relative;
  display: inline-block;
  font-family: "public_sans-light", sans-serif;
  font-size: 16px;
  line-height: 21px;
  max-width: 96px;
  padding-right: 20px;
  z-index: 2;
  background-color: #004A7E;
}

.section-nav__nav-item::after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  width: 1px;
  background-color: #fff;
  opacity: 0.24;
}

.section-nav--white-bg {
  background-color: #fff;
}

.section-nav--white-bg a {
  color: #000;
}

.section-nav--white-bg .section-nav__nav {
  border-top: 1px solid rgba(0, 0, 0, 0.24);
  border-bottom: 1px solid rgba(0, 0, 0, 0.24);
}

.section-nav--white-bg .section-nav__nav li:not(:last-child)::after {
  background-color: #000;
}

.section-nav--media-center {
  margin-top: 35px;
  padding: 0 20px;
}

.section-nav--media-center .section-nav__container {
  position: relative;
  max-width: 1070px;
  margin: 0 auto;
  padding: 0;
}

.section-nav--media-center .section-nav__container::before {
  content: '';
  position: absolute;
  top: 0;
  width: 100%;
  height: 90%;
  right: 100%;
  background-color: #fff;
  z-index: 2;
}

.section-nav--media-center .section-nav__container::after {
  content: '';
  position: absolute;
  top: 0;
  width: 100%;
  height: 90%;
  left: 100%;
  background-color: #fff;
  z-index: 2;
}

.section-nav--media-center .section-nav__nav {
  padding: 0;
  overflow: visible;
}

.section-nav--media-center .section-nav__nav li {
  padding: 10px 0;
  margin: 10px 0 5px;
}

.section-nav--media-center .section-nav__nav li:not(:last-child)::after {
  right: -20px;
}

.section-nav--media-center .glide {
  padding-left: 0;
  width: 100%;
}

.section-nav--media-center .glide__arrow {
  background-color: #fff;
}

.section-nav--media-center .glide__arrow svg path {
  stroke: #171717;
}

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;
}

.slick-list:focus {
  outline: none;
}

.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.slick-track:before, .slick-track:after {
  content: "";
  display: table;
}

.slick-track:after {
  clear: both;
}

.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none;
}

[dir="rtl"] .slick-slide {
  float: right;
}

.slick-slide img {
  display: block;
}

.slick-slide.slick-loading img {
  display: none;
}

.slick-slide.dragging img {
  pointer-events: none;
}

.slick-initialized .slick-slide {
  display: block;
}

.slick-loading .slick-slide {
  visibility: hidden;
}

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

/* Slider */
.slick-loading .slick-list {
  background: #fff url("./ajax-loader.gif") center center no-repeat;
}

/* Icons */
@font-face {
  font-family: "slick";
  src: url("./fonts/slick.eot");
  src: url("./fonts/slick.eot?#iefix") format("embedded-opentype"), url("./fonts/slick.woff") format("woff"), url("./fonts/slick.ttf") format("truetype"), url("./fonts/slick.svg#slick") format("svg");
  font-weight: normal;
  font-style: normal;
}

/* Arrows */
.slick-prev,
.slick-next {
  position: absolute;
  display: block;
  height: 20px;
  width: 20px;
  line-height: 0px;
  font-size: 0px;
  cursor: pointer;
  background: transparent;
  color: transparent;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  padding: 0;
  border: none;
  outline: none;
}

.slick-prev:hover, .slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
  outline: none;
  background: transparent;
  color: transparent;
}

.slick-prev:hover:before, .slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before {
  opacity: 1;
}

.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
  opacity: 0.25;
}

.slick-prev:before,
.slick-next:before {
  font-family: "slick";
  font-size: 20px;
  line-height: 1;
  color: white;
  opacity: 0.75;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-prev {
  left: -25px;
}

[dir="rtl"] .slick-prev {
  left: auto;
  right: -25px;
}

.slick-prev:before {
  content: "←";
}

[dir="rtl"] .slick-prev:before {
  content: "→";
}

.slick-next {
  right: -25px;
}

[dir="rtl"] .slick-next {
  left: -25px;
  right: auto;
}

.slick-next:before {
  content: "→";
}

[dir="rtl"] .slick-next:before {
  content: "←";
}

/* Dots */
.slick-dotted.slick-slider {
  margin-bottom: 30px;
}

.slick-dots {
  position: absolute;
  bottom: -25px;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  margin: 0;
  width: 100%;
}

.slick-dots li {
  position: relative;
  display: inline-block;
  height: 20px;
  width: 20px;
  margin: 0 5px;
  padding: 0;
  cursor: pointer;
}

.slick-dots li button {
  border: 0;
  background: transparent;
  display: block;
  height: 20px;
  width: 20px;
  outline: none;
  line-height: 0px;
  font-size: 0px;
  color: transparent;
  padding: 5px;
  cursor: pointer;
}

.slick-dots li button:hover, .slick-dots li button:focus {
  outline: none;
}

.slick-dots li button:hover:before, .slick-dots li button:focus:before {
  opacity: 1;
}

.slick-dots li button:before {
  position: absolute;
  top: 0;
  left: 0;
  content: "•";
  width: 20px;
  height: 20px;
  font-family: "slick";
  font-size: 6px;
  line-height: 20px;
  text-align: center;
  color: black;
  opacity: 0.25;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-dots li.slick-active button:before {
  color: black;
  opacity: 0.75;
}

.sectionnav {
  background-color: #004A7E;
  color: #fff;
  position: relative;
  z-index: 2;
}

.sectionnav__container {
  display: flex;
  box-sizing: border-box;
  flex: 0 1 auto;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  margin: 0 auto;
  max-width: 1580px;
  margin: auto;
  justify-content: center;
  padding-left: 0;
}

@media (min-width: 768px) {
  .sectionnav__container {
    padding-left: 30px;
  }
}

.sectionnav__nav {
  box-sizing: border-box;
  flex-basis: 100%;
  max-width: 100%;
  padding: 0 25px;
  padding: 16px 0 0;
  display: flex;
  align-items: center;
}

.sectionnav__nav a {
  color: #ffffff;
  font-family: "public_sans-semibold", sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 21px;
  transition: all .25s ease;
}

.sectionnav__nav a:hover {
  color: rgba(0, 0, 0, 0.6);
}

@media (max-width: 767px) {
  .sectionnav__nav {
    flex-direction: column;
  }
}

.sectionnav__nav-item {
  position: relative;
  display: inline-block;
  font-family: "public_sans-semibold", sans-serif;
  font-size: 16px;
  line-height: 21px;
  padding-right: 20px;
  z-index: 3;
  margin-bottom: 10px;
  padding-left: 52px;
  padding-bottom: 16px;
}

@media (min-width: 768px) {
  .sectionnav__nav-item {
    font-family: "public_sans-light", sans-serif;
    max-width: 96px;
    margin-bottom: 0;
    padding-left: 0;
  }
}

.sectionnav__nav-item::after {
  content: '';
  position: absolute;
  top: 50%;
  right: 0;
  height: 24px;
  width: 1px;
  background-color: #fff;
  opacity: 0.24;
  transform: translateY(-50%);
  display: none;
}

.sectionnav--white-bg {
  background-color: #fff;
}

.sectionnav--white-bg a {
  color: #000;
}

.sectionnav--white-bg .sectionnav__nav {
  border-top: 1px solid rgba(0, 0, 0, 0.24);
  border-bottom: 1px solid rgba(0, 0, 0, 0.24);
}

.sectionnav--white-bg .sectionnav__nav li:not(:last-child)::after {
  background-color: #000;
}

.sectionnav--white-bg .sectionnav__overflow::after {
  background: #FFF;
}

.sectionnav--media-center {
  margin-top: 35px;
  padding: 0 20px;
}

.sectionnav--media-center .sectionnav__container {
  position: relative;
  max-width: 1070px;
  margin: 0 auto;
  padding: 0;
}

.sectionnav--media-center .sectionnav__container::before {
  content: '';
  position: absolute;
  top: 0;
  width: 100%;
  height: 90%;
  right: 100%;
  background-color: #fff;
  z-index: 2;
}

.sectionnav--media-center .sectionnav__container::after {
  content: '';
  position: absolute;
  top: 0;
  width: 100%;
  height: 90%;
  left: 100%;
  background-color: #fff;
  z-index: 2;
}

.sectionnav--media-center .sectionnav__nav {
  padding: 0;
  overflow: visible;
}

.sectionnav--media-center .sectionnav__nav li {
  padding: 10px 0;
  margin: 10px 0 5px;
}

.sectionnav--media-center .sectionnav__nav li:not(:last-child)::after {
  right: -20px;
}

.sectionnav--media-center .glide {
  padding-left: 0;
  width: 100%;
}

.sectionnav--media-center .glide__arrow {
  background-color: #fff;
}

.sectionnav--media-center .glide__arrow svg path {
  stroke: #171717;
}

.sectionnav__ul {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  align-items: center;
  width: calc(100% - 72px);
  position: relative;
}

.sectionnav__ul::after {
  content: '';
  display: block;
  background: #004A7E;
  height: 100%;
  width: 300%;
  right: -300%;
  position: absolute;
  z-index: 2;
  top: 0;
  bottom: 0;
}

.sectionnav .slick-track {
  display: flex;
  align-items: stretch;
}

.sectionnav .slick-list {
  overflow: visible;
}

.sectionnav .slick-slide {
  max-width: 260px;
  position: relative;
  height: auto !important;
  display: flex;
  align-items: center;
}

.sectionnav .slick-slide::after {
  content: '';
  position: absolute;
  top: 50%;
  right: 0px;
  height: 24px;
  width: 1px;
  background-color: #fff;
  opacity: 0.24;
  transform: translateY(-50%);
}

.sectionnav__li {
  max-width: 260px;
  position: relative;
  line-height: 1.3125;
  font-size: 16px;
  border: 1px solid transparent;
  box-sizing: content-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 10px 0;
}

.sectionnav__li a {
  display: block;
  padding: 0 15px;
  position: relative;
}

.sectionnav__li:hover .glide__slide-dropdown {
  display: block;
}

.sectionnav__overflow {
  position: relative;
  z-index: 2;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
}

@media (min-width: 768px) {
  .sectionnav__overflow {
    width: calc(100% - 96px);
  }
}

.sectionnav__overflow::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 0;
  height: 24px;
  width: 1px;
  background-color: #fff;
  opacity: 0.24;
  transform: translateY(-50%);
  display: block;
  z-index: 4;
}

.sectionnav__overflow::after {
  content: '';
  display: block;
  background: #004A7E;
  height: 100%;
  width: 300%;
  left: -300%;
  position: absolute;
  z-index: 2;
  top: 0;
  bottom: 0;
}

.sectionnav .glide {
  position: relative;
  padding-left: 20px;
  display: inline-block;
  width: calc(100% - 105px);
}

.sectionnav .glide__slide:hover > a {
  color: rgba(0, 0, 0, 0.6);
}

.sectionnav .glide__slide:hover .glide__slide-dropdown {
  display: block;
}

.sectionnav .glide__slide:focus > a {
  color: rgba(0, 0, 0, 0.6);
}

.sectionnav .glide__arrow {
  position: absolute;
  top: 0;
  width: 50px;
  height: 90%;
  background-color: #004A7E;
}

.sectionnav .glide__arrow--left {
  display: none;
  left: -8px;
  transform: rotate(180deg);
}

.sectionnav .glide__arrow--right {
  right: -9px;
}

.sectionnav .glide__slide-dropdown {
  position: absolute;
  display: none;
  top: calc(100% + 3px);
  left: 50%;
  transform: translate(-50%, 0);
  width: 300px;
  background-color: #1E242F;
  z-index: 3;
  color: #fff;
  border-radius: 6px;
  box-shadow: 0 11px 21px rgba(0, 0, 0, 0.15);
}

.sectionnav .glide__slide-dropdown::before {
  content: '';
  position: absolute;
  top: -8px;
  left: 50%;
  transform: translate(-50%, 0);
  border-style: solid;
  height: 0;
  width: 0;
  border-color: transparent transparent #1E242F;
  border-width: 0 7px 8px;
}

.sectionnav .glide__slide-dropdown ul {
  list-style: none;
  margin: 0;
  padding: 25px 0 5px;
}

.sectionnav .glide__slide-dropdown ul li {
  margin: 0;
  padding: 0;
  border-bottom: 1px solid #424a58;
}

.sectionnav .glide__slide-dropdown ul li:last-child {
  border-bottom: none;
}

.sectionnav .glide__slide-dropdown ul li::after {
  display: none;
}

.sectionnav .glide__slide-dropdown a {
  position: relative;
  display: block;
  padding: 18px 50px;
  color: #fff;
}

.sectionnav .glide__slide-dropdown a:hover, .sectionnav .glide__slide-dropdown a:focus {
  color: #a7adb6;
}

.sectionnav .glide__slide-dropdown a:hover::before, .sectionnav .glide__slide-dropdown a:focus::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 5px;
  height: 100%;
  background-color: #A0B426;
}

.sectionnav .slick-arrow {
  display: block;
  width: 36px;
  height: 46px;
  transition: all .25s ease;
  background: #004A7E;
  position: relative;
  transition: all .25s ease;
  z-index: 3;
}

.sectionnav .slick-arrow:hover {
  background: #8B9C21;
}

.sectionnav .slick-arrow svg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  fill: #FFF;
  width: 14px;
  height: 9px;
}

.sectionnav .slick-arrow--prev svg {
  transform: translate(-50%, -50%) rotate(180deg);
}

.sectionnav .slick-arrow--next::after {
  content: '';
  display: block;
  position: relative;
  top: 0;
  left: -30px;
  width: 30px;
  height: 100%;
  background: linear-gradient(to left, #004a7e 0%, rgba(0, 74, 126, 0.98) 2%, rgba(0, 74, 126, 0.29) 71%, rgba(125, 185, 232, 0) 100%);
}

.sectionnav .slick-arrow.slick-disabled {
  opacity: 0.1;
  cursor: default;
}

.sectionnav .slick-arrow.slick-disabled:hover {
  background: #004A7E;
}

.sectionnav .slick-arrow.slick-hidden {
  display: none;
}

.sectionnav--media-center .sectionnav__overflow {
  width: 100%;
  margin-bottom: 0;
}

.sectionnav--white-bg .slick-arrow {
  background: white;
  position: relative;
  transition: all .25s ease;
  z-index: 3;
}

.sectionnav--white-bg .slick-arrow:hover {
  background: #8B9C21;
}

.sectionnav--white-bg .slick-arrow:hover svg [stroke] {
  stroke: #FFF;
}

.sectionnav--white-bg .slick-arrow svg {
  position: absolute;
}

.sectionnav--white-bg .slick-arrow svg [stroke] {
  stroke: #171717;
}

.sectionnav--white-bg .slick-arrow--next::after {
  background: linear-gradient(to left, white 0%, rgba(255, 255, 255, 0.98) 2%, rgba(255, 255, 255, 0.29) 71%, rgba(125, 185, 232, 0) 100%);
}

.sectionnav--white-bg .slick-arrow.slick-disabled:hover {
  background: #FFF;
}

.sectionnav--white-bg .sectionnav__ul::after {
  background: #FFF;
}

.map-controls__form .select2-container .select2-selection--single {
  height: 60px;
  border-radius: 5px;
  background-color: #fff;
  border-color: #fff;
  color: #171717;
  font-family: "public_sans-semibold", sans-serif;
  font-size: 14px;
  padding: 15px;
}

.map-controls__form .select2-container--default .select2-selection--single .select2-selection__arrow {
  top: 50%;
  transform: translateY(-50%);
  right: 14px;
}

.map-controls__form .select2-container .select2-selection--single .select2-selection__rendered {
  padding-right: 0;
}

.select2-dropdown {
  border-color: #FFF !important;
}

.select2-results__option {
  color: #171717 !important;
  font-family: "public_sans-medium", sans-serif !important;
  font-size: 13px !important;
  line-height: 1.307692308 !important;
  padding: 10px 22px !important;
}

.select2-container--default .select2-results__option--highlighted[aria-selected] {
  background: #5D6B03 !important;
  color: #FFF !important;
}

.select2-container--default .select2-search--dropdown .select2-search__field {
  height: 44px;
  border-radius: 3px;
  border: 1px solid #bababa !important;
  background-color: #ffffff;
  background: url("../img/map-search.svg") no-repeat 95% 50%;
}

[data-simplebar] {
  position: relative;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-content: flex-start;
  align-items: flex-start;
}

.simplebar-wrapper {
  overflow: hidden;
  width: inherit;
  height: inherit;
  max-width: 100%;
  max-height: inherit;
}

.simplebar-mask {
  direction: inherit;
  position: absolute;
  overflow: hidden;
  padding: 0;
  margin: 0;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  width: auto !important;
  height: auto !important;
  z-index: 0;
}

.simplebar-offset {
  direction: inherit !important;
  box-sizing: inherit !important;
  resize: none !important;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  padding: 0;
  margin: 0;
  -webkit-overflow-scrolling: touch;
}

.simplebar-content-wrapper {
  direction: inherit;
  box-sizing: border-box !important;
  position: relative;
  display: block;
  height: 100%;
  /* Required for horizontal native scrollbar to not appear if parent is taller than natural height */
  width: auto;
  visibility: visible;
  overflow: auto;
  /* Scroll on this element otherwise element can't have a padding applied properly */
  max-width: 100%;
  /* Not required for horizontal scroll to trigger */
  max-height: 100%;
  /* Needed for vertical scroll to trigger */
}

.simplebar-content:before,
.simplebar-content:after {
  content: ' ';
  display: table;
}

.simplebar-placeholder {
  max-height: 100%;
  max-width: 100%;
  width: 100%;
  pointer-events: none;
}

.simplebar-height-auto-observer-wrapper {
  box-sizing: inherit !important;
  height: 100%;
  width: 100%;
  max-width: 1px;
  position: relative;
  float: left;
  max-height: 1px;
  overflow: hidden;
  z-index: -1;
  padding: 0;
  margin: 0;
  pointer-events: none;
  flex-grow: inherit;
  flex-shrink: 0;
  flex-basis: 0;
}

.simplebar-height-auto-observer {
  box-sizing: inherit;
  display: block;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  height: 1000%;
  width: 1000%;
  min-height: 1px;
  min-width: 1px;
  overflow: hidden;
  pointer-events: none;
  z-index: -1;
}

.simplebar-track {
  z-index: 1;
  position: absolute;
  right: 0;
  bottom: 0;
  pointer-events: none;
  overflow: hidden;
}

[data-simplebar].simplebar-dragging .simplebar-content {
  pointer-events: none;
  user-select: none;
  -webkit-user-select: none;
}

[data-simplebar].simplebar-dragging .simplebar-track {
  pointer-events: all;
}

.simplebar-scrollbar {
  position: absolute;
  right: 2px;
  width: 7px;
  min-height: 10px;
}

.simplebar-scrollbar:before {
  position: absolute;
  content: '';
  background: black;
  border-radius: 7px;
  left: 0;
  right: 0;
  opacity: 0;
  transition: opacity 0.2s linear;
}

.simplebar-track .simplebar-scrollbar.simplebar-visible:before {
  /* When hovered, remove all transitions from drag handle */
  opacity: 0.5;
  transition: opacity 0s linear;
}

.simplebar-track.simplebar-vertical {
  top: 0;
  width: 11px;
}

.simplebar-track.simplebar-vertical .simplebar-scrollbar:before {
  top: 2px;
  bottom: 2px;
}

.simplebar-track.simplebar-horizontal {
  left: 0;
  height: 11px;
}

.simplebar-track.simplebar-horizontal .simplebar-scrollbar:before {
  height: 100%;
  left: 2px;
  right: 2px;
}

.simplebar-track.simplebar-horizontal .simplebar-scrollbar {
  right: auto;
  left: 0;
  top: 2px;
  height: 7px;
  min-height: 0;
  min-width: 10px;
  width: auto;
}

/* Rtl support */
[data-simplebar-direction='rtl'] .simplebar-track.simplebar-vertical {
  right: auto;
  left: 0;
}

.hs-dummy-scrollbar-size {
  direction: rtl;
  position: fixed;
  opacity: 0;
  visibility: hidden;
  height: 500px;
  width: 500px;
  overflow-y: hidden;
  overflow-x: scroll;
}

.species-drawer {
  background-color: #1E242F;
  color: #fff;
  padding: 65px 0 90px;
}

.species-drawer--alt {
  background: none;
  color: #171717;
}

.species-drawer--alt .species-drawer__click-container {
  color: #171717;
}

.species-drawer__row {
  position: relative;
  display: flex;
  box-sizing: border-box;
  flex: 0 1 auto;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  margin: 0 auto;
  max-width: 1355px;
}

.species-drawer__column {
  box-sizing: border-box;
  flex-basis: 100%;
  max-width: 100%;
  padding: 0 25px;
}

@media screen and (min-width: 768px) and (max-width: 1440px) {
  .species-drawer__column {
    padding: 0 35px;
  }
}

.species-drawer__columns {
  box-sizing: border-box;
  flex-basis: 100%;
  max-width: 100%;
  padding: 0 25px;
}

@media only screen and (min-width: 768px) {
  .species-drawer__columns {
    box-sizing: border-box;
    flex-basis: 33.33333%;
    max-width: 33.33333%;
    padding: 0 25px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1440px) {
  .species-drawer__columns {
    padding: 0 35px;
  }
}

.species-drawer__columns.active .species-drawer__image-container::before {
  content: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNSIgaGVpZ2h0PSIyNSIgdmlld0JveD0iMCAwIDI1IDI1Ij48Zz48Zz48cGF0aCBmaWxsPSIjYTBiNDI2IiBkPSJNMTUuMTY2IDEyLjVsOS4yODItOS4yODJBMS44ODUgMS44ODUgMCAwIDAgMjEuNzgzLjU1MkwxMi41IDkuODM1IDMuMjE3LjU1MkExLjg4NSAxLjg4NSAwIDAgMCAuNTUyIDMuMjE4TDkuODM0IDEyLjUuNTUyIDIxLjc4M2ExLjg4NSAxLjg4NSAwIDAgMCAyLjY2NSAyLjY2NWw5LjI4My05LjI4MiA5LjI4MyA5LjI4MmExLjg4MiAxLjg4MiAwIDAgMCAyLjY2NiAwIDEuODg1IDEuODg1IDAgMCAwIDAtMi42NjVMMTUuMTY1IDEyLjUiLz48L2c+PC9nPjwvc3ZnPg==);
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(0, -50%);
  width: 100%;
  text-align: center;
  z-index: 1;
}

.species-drawer__columns.active .species-drawer__image-container::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.75);
}

.species-drawer__columns.active .species-drawer__title {
  color: #A0B426;
}

.species-drawer h2 {
  color: inherit;
  font-size: 1.625em;
}

.species-drawer hr {
  max-width: 30px;
  margin: 16px 0 22px;
  background-color: #A0B426;
}

.species-drawer__image-container {
  position: relative;
  cursor: pointer;
}

.species-drawer__image-container img {
  width: 100%;
}

.species-drawer__title {
  position: relative;
  vertical-align: middle;
  color: inherit;
  font-family: "public_sans-semibold", sans-serif;
  font-size: 22px;
  line-height: 29px;
  text-transform: uppercase;
  margin-top: 13px;
  margin-bottom: 30px;
  padding-left: 30px;
  cursor: pointer;
}

.species-drawer__title::before {
  content: '';
  position: absolute;
  top: 15px;
  left: 0;
  height: 3px;
  width: 20px;
  background-color: #A0B426;
}

.species-drawer__content {
  position: absolute;
  left: 25px;
  width: calc(100% - 50px);
  padding-top: 30px;
  padding-bottom: 50px;
  display: none;
}

@media screen and (min-width: 768px) and (max-width: 1440px) {
  .species-drawer__content {
    left: 35px;
    width: calc(100% - 70px);
  }
}

@media (min-width: 1024px) {
  .species-drawer__content {
    padding-top: 100px;
    padding-bottom: 90px;
  }
}

.species-drawer__content h3 {
  font-family: "merriweather-bold", serif;
  font-size: 32px;
  line-height: 1.1875;
}

@media (min-width: 1024px) {
  .species-drawer__content h3 {
    font-size: 48px;
  }
}

.species-drawer__content p {
  margin-bottom: 50px;
}

.species-drawer--no-bg {
  background-color: #fff;
  color: #171717;
  border-bottom: 1px solid #BFBFBF;
}

.species-drawer--no-bg .species-drawer__click-container {
  color: #171717;
}

.species-drawer__click-container {
  text-align: left;
  color: #FFF;
}

.species-drawer__click-container:hover, .species-drawer__click-container:focus {
  color: #A0B426;
}

.species-content__row {
  display: flex;
  flex-wrap: wrap;
}

@media only screen and (min-width: 1025px) {
  .species-content__columns {
    width: 50%;
  }
  .species-content__columns:first-child {
    width: 42%;
    margin-right: 100px;
  }
}

.species-content__related-plans h4 {
  font-family: "public_sans-semibold", sans-serif;
  font-size: 15px;
  font-weight: 600;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  color: inherit;
}

.species-content__related-plans ul {
  list-style: none;
  margin: 0 0 40px;
  padding: 0;
}

.species-content__related-plans ul a {
  color: inherit;
  font-family: "merriweather", serif;
  font-size: 26px;
  font-weight: 700;
  line-height: 44px;
  transition: all .25s ease;
}

.species-content__related-plans ul a svg {
  margin-left: 10px;
}

.species-content__related-plans ul a:hover {
  color: #A0B426;
}

.species-landscapes__row {
  display: flex;
  flex-wrap: wrap;
}

.species-landscapes__row img {
  width: 100%;
  margin-bottom: 15px;
}

.species-landscapes__columns {
  width: 50%;
  padding: 0 8px;
  margin-bottom: 45px;
}

.species-landscapes__columns a {
  color: inherit;
  display: block;
  transition: all .2s ease;
}

.species-landscapes__columns a:hover {
  color: #A0B426;
}

.species-landscapes__title {
  font-family: "public_sans-semibold", sans-serif;
  font-size: 18px;
  line-height: 1.2;
  text-transform: uppercase;
  vertical-align: middle;
}

.species-landscapes__title::before {
  content: '';
  display: inline-block;
  vertical-align: middle;
  height: 3px;
  width: 20px;
  margin-right: 10px;
  margin-bottom: 4px;
  background-color: #A0B426;
}

.standard-content {
  max-width: 1100px;
  margin: 40px auto 20px;
}

.standard-content__row {
  display: flex;
  box-sizing: border-box;
  flex: 0 1 auto;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  margin: 0 auto;
  max-width: 1040px;
  margin-left: 0;
}

.standard-content__row--alt-width {
  display: flex;
  box-sizing: border-box;
  flex: 0 1 auto;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  margin: 0 auto;
  max-width: 1340px;
}

.standard-content__column {
  box-sizing: border-box;
  flex-grow: 1;
  flex-basis: 0;
  max-width: 100%;
  padding: 0 25px;
}

@media screen and (min-width: 768px) and (max-width: 1440px) {
  .standard-content__column {
    padding: 0 35px;
  }
}

.standard-content--no-max-width {
  max-width: 100%;
}

.strategy-grid {
  color: #171717;
  padding: 65px 0 45px;
}

.strategy-grid__row {
  position: relative;
  display: flex;
  box-sizing: border-box;
  flex: 0 1 auto;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  margin: 0 auto;
  max-width: 1355px;
}

.strategy-grid__column {
  box-sizing: border-box;
  flex-basis: 100%;
  max-width: 100%;
  padding: 0 25px;
}

@media screen and (min-width: 768px) and (max-width: 1440px) {
  .strategy-grid__column {
    padding: 0 35px;
  }
}

.strategy-grid__columns {
  box-sizing: border-box;
  flex-basis: 100%;
  max-width: 100%;
  padding: 0 25px;
  margin-bottom: 20px;
}

@media only screen and (min-width: 1025px) {
  .strategy-grid__columns {
    box-sizing: border-box;
    flex-basis: 33.33333%;
    max-width: 33.33333%;
    padding: 0 25px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1440px) {
  .strategy-grid__columns {
    padding: 0 35px;
  }
}

.strategy-grid__columns:nth-child(3n+1) .strategy-grid__title::before {
  background-color: #D17A22;
}

.strategy-grid__columns:nth-child(3n+2) .strategy-grid__title::before {
  background-color: #21447e;
}

.strategy-grid h2 {
  color: inherit;
  font-size: 1.625em;
}

.strategy-grid hr {
  max-width: 30px;
  margin: 16px 0 22px;
  background-color: #A0B426;
}

.strategy-grid__image-container {
  position: relative;
}

.strategy-grid__image-container img {
  width: 100%;
}

.strategy-grid a {
  color: inherit;
}

.strategy-grid a:hover .strategy-grid__title, .strategy-grid a:focus .strategy-grid__title {
  color: #A0B426;
}

.strategy-grid__intro-title {
  color: #6A6A6A;
  font-family: "public_sans-medium", sans-serif;
  font-size: 15px;
  text-transform: uppercase;
  line-height: 1.2;
}

.strategy-grid__title {
  position: relative;
  vertical-align: middle;
  color: inherit;
  font-family: "merriweather-bold", serif;
  font-size: 21px;
  line-height: 35px;
  margin-top: 13px;
  margin-bottom: 20px;
  padding-left: 15px;
  border-bottom: 2px solid transparent;
}

.strategy-grid__title::before {
  content: '';
  position: absolute;
  top: 7px;
  left: 0;
  width: 4px;
  height: 100%;
  max-height: 50px;
  background-color: #A0B426;
}

.tabbed-content {
  padding: 100px 0;
  background-color: #f3f3f3;
  overflow: hidden;
}

.tabbed-content__container {
  display: flex;
  box-sizing: border-box;
  flex: 0 1 auto;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  margin: 0 auto;
  max-width: 1075px;
  padding: 0px 25px;
}

@media screen and (min-width: 768px) and (max-width: 1440px) {
  .tabbed-content__container {
    padding: 0 35px;
  }
}

ul.tabs {
  width: 100%;
  margin: 0 0 35px;
  padding: 0;
  list-style: none;
  border-bottom: 1px solid #C9C9C9;
}

ul.tabs li {
  position: relative;
  cursor: pointer;
  display: block;
  margin: 0;
  margin-right: -7px;
}

@media only screen and (min-width: 500px) {
  ul.tabs li {
    display: inline-block;
  }
}

.tab__button {
  font-family: "public_sans-semibold", sans-serif;
  font-size: 22px;
  letter-spacing: 0.9px;
  text-transform: uppercase;
  padding: 10px 28px;
  text-align: center;
  vertical-align: top;
  color: #171717;
}

.tab__button::after {
  content: '';
  position: absolute;
  bottom: -4px;
  left: 0;
  width: 100%;
  height: 6px;
}

.tab__button.current {
  background: #1E242F;
  color: #fff;
  z-index: 2;
}

.tab__button.current::after {
  background-color: #A0B426;
}

.tab__button:hover::after, .tab__button:focus::after {
  background-color: #A0B426;
}

.tab-content {
  display: none;
}

.tab-content.current {
  display: block;
}

.tab-content .views-element-container {
  margin-bottom: 75px;
}

.tab-content .views-element-container:last-child {
  margin-bottom: 0;
}

.tab-content div.views-field-body {
  margin: 20px 0;
}

.trending-content {
  padding: 70px 0 0;
}

.trending-content__row {
  display: flex;
  box-sizing: border-box;
  flex: 0 1 auto;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  margin: 0 auto;
  max-width: 1355px;
  align-items: center;
  padding: 0 0 70px;
  border-bottom: 1px solid #bfbfbf;
  justify-content: space-between;
}

.trending-content__columns {
  box-sizing: border-box;
  flex-basis: 100%;
  max-width: 100%;
  padding: 0 25px;
}

@media screen and (min-width: 768px) and (max-width: 1440px) {
  .trending-content__columns {
    padding: 0 35px;
  }
}

@media only screen and (min-width: 960px) {
  .trending-content__columns:first-child {
    box-sizing: border-box;
    flex-basis: 58.33333%;
    max-width: 58.33333%;
    padding: 0 25px;
  }
}

@media only screen and (min-width: 1200px) {
  .trending-content__columns:first-child {
    box-sizing: border-box;
    flex-basis: 50%;
    max-width: 50%;
    padding: 0 25px;
  }
}

@media only screen and (min-width: 960px) {
  .trending-content__columns:last-child {
    box-sizing: border-box;
    flex-basis: 41.66667%;
    max-width: 41.66667%;
    padding: 0 25px;
  }
}

@media only screen and (min-width: 1200px) {
  .trending-content__columns:last-child {
    box-sizing: border-box;
    flex-basis: 50%;
    max-width: 50%;
    padding: 0 25px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1440px) {
  .trending-content__columns:last-child {
    padding: 0 35px;
  }
}

.trending-content__content {
  max-width: 550px;
}

.trending-content__content h2 {
  font-size: 36px;
  line-height: 1.1875;
}

@media (min-width: 768px) {
  .trending-content__content h2 {
    font-size: 48px;
  }
}

@media (min-width: 960px) and (max-width: 1200px) {
  .trending-content__content h2 {
    font-size: 36px;
  }
}

.trending-content__content hr {
  max-width: 50px;
  margin-left: 0;
  background-color: #A0B426;
  height: 3px;
}

.trending-content__content p {
  max-width: 535px;
}

.trending-content__topic {
  color: #6A6A6A;
  font-family: "public_sans-medium", sans-serif;
  font-size: 15px;
  text-transform: uppercase;
}

.trending-content img {
  margin-bottom: 30px;
}

@media only screen and (min-width: 960px) {
  .trending-content--reverse .trending-content__columns:first-child {
    order: 2;
  }
  .trending-content--reverse .trending-content__columns:last-child {
    order: 1;
  }
}

@media only screen and (min-width: 1200px) {
  .trending-content--reverse .trending-content__columns:last-child .trending-content__content {
    margin-left: auto;
    margin-right: 120px;
  }
}

.trending-content--background {
  background-color: #1E242F;
  color: #fff;
}

.trending-content--background .trending-content__row {
  border-bottom: 0;
}

.trending-content--background .trending-content__topic {
  color: #fff;
}

.trending-content .video-play {
  display: flex;
  align-items: center;
  color: #8B9C21;
  font-family: "public_sans-semibold", sans-serif;
  font-size: 16px;
  letter-spacing: 1.33px;
  text-transform: uppercase;
}

.trending-content .video-play svg {
  margin-right: 20px;
}

.trending-content .video-play:hover, .trending-content .video-play:focus {
  color: #A0B426;
}

.video-module {
  margin: 60px 0 70px;
}

.video-module__row {
  display: flex;
  box-sizing: border-box;
  flex: 0 1 auto;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  margin: 0 auto;
  max-width: 1355px;
}

.video-module__column {
  position: relative;
  box-sizing: border-box;
  flex-basis: 100%;
  max-width: 100%;
  padding: 0 25px;
}

@media screen and (min-width: 768px) and (max-width: 1440px) {
  .video-module__column {
    padding: 0 35px;
  }
}

.video-module__img-container {
  position: relative;
}

.video-module__img-container::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #171717;
  opacity: 0.4;
}

.video-module__content {
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  transform: translate(0, -50%);
  text-align: center;
}

.video-module__content .button {
  padding: 14px 25px;
}

.video-module__content .button svg {
  margin-right: 20px;
  width: 40px;
  height: 40px;
}

.youtube-modal {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  z-index: 1000;
  background: #000;
  display: none;
}

.youtube-modal .fitvid {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.youtube-modal__close {
  position: absolute;
  top: 10px;
  right: 10px;
  background: transparent;
  border: none;
  padding: 20px;
  z-index: 2;
  transition: all .25s ease;
}

.youtube-modal__close svg {
  fill: #FFF;
}

.youtube-modal__close:hover, .youtube-modal__close:focus {
  background: #8B9C21;
}

.sr-only,
.a11y, .access {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  -webkit-clip-path: inset(50%);
  clip-path: inset(50%);
  border: 0;
}

.sr-only-focusable:active, .sr-only-focusable:active,
.a11y-focusable:active,
.a11y-focusable:active, .access-focusable:active, .access-focusable:active {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
  -webkit-clip-path: none;
  clip-path: none;
}

.u-cf::after {
  clear: both;
  content: "";
  display: block;
}

.text-center {
  text-align: center;
}

.u-no-children-margin > :first-child {
  margin-top: 0 !important;
}

.u-no-children-margin > :last-child {
  margin-bottom: 0 !important;
}

@media print {
  body {
    padding: 0 20px;
  }
}

.wdg_grid,
.wdg_overlay {
  position: absolute;
  z-index: 999;
  top: 0;
  width: 100%;
  height: 2000%;
  bottom: 0;
  background-position: 50% 0%;
  background-repeat: repeat-y;
  pointer-events: none;
}

.wdg_grid {
  display: none;
  opacity: 0.8;
}

.wdg_grid--active {
  background-image: url(../img/overlay/grid--desktop.png);
  display: block !important;
}

.wdg_overlay {
  display: none;
  z-index: 998;
  opacity: .5;
  background-position: 50% 0;
  background-repeat: no-repeat;
  width: 100%;
  background-size: 100% auto;
}

.admin-bar .wdg_overlay {
  background-position: 50% 46px;
}

@media (min-width: 1024px) {
  .admin-bar .wdg_overlay {
    background-position: 50% 32px;
  }
}

.wdg_overlay--active {
  display: block !important;
}
